<template>
<v-card flat title="QUESTIONS">
    <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab==='one'">
        <template v-slot:actions>
            <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
        </template>
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_btn">View</v-tab>
        <v-tab value="two" @click="addBtn()">{{ getBtnName() }}</v-tab>
    </v-tabs>

    <v-card-text>
        <v-tab-items v-model="tab">
            <v-tab-item value="one" v-if="tab==='one'">
                <div v-if="loadingData" style="text-align: center;">
                    Loading...
                </div>
                <tableLayout :headers="questionsHeader" :items="questionsItems" :slotNames="questionsSlotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                    <template v-slot:is_active="{ item }">
                        <span :class="item.is_active ==='active' ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                    <template v-slot:Actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="blue">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item)" color="red">mdi-delete</v-icon>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <QuestionAdd :editItem="item" :btnNameChange=btnBoolean @data-changed="show_changed_data"></QuestionAdd>
            </v-tab-item>
        </v-tab-items>
    </v-card-text>
</v-card>
<v-dialog v-model="dialogForBatch" max-width="560px" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete ">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        <div v-if="error.api" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{ error.api }}</div>
    </v-card>
</v-dialog>
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
<logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import QuestionAdd from './questionAdd.vue'
import tableLayout from '../ReusableComponents/tableLayout.vue'
import apiUrl from '@/api/allApis.js'
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    markRaw
} from 'vue';
import jwtMixin from '@/mixins/jwt.js'
export default markRaw({
    components: {
        QuestionAdd,
        tableLayout,
        SearchComponent,
        successMessageVue,
        logoutDia
    },
    mixins: [jwtMixin],
    data() {
        return {
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            tab: null,
            btnBoolean: true,
            loadingData: false,
            item: {
                question: "",
                answers: [{
                        answer: "",
                        isAnswer: true
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    }
                ],
                is_active: 'inactive',
                question_img: "",
            },
            questionsSlotNames: ['is_active', 'Actions'],
            questionsHeader: [{
                    key: 'question',
                    title: 'Question'
                },
                {
                    key: 'is_active',
                    title: 'Active'
                },
                {
                    key: 'question_img',
                    title: 'Question Image'
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                },
            ],
            questionsItems: [],
            actionsItems: ['Delete Selected Items'],
            filterSlotNames: ['actions'],
            actionValue: 'Select Action',
            dialogForBatch: false,
            search: '',
            selected: [],
            indvidualItem: '',
            singleDelete: false,
            singleItem: [],
            error: {
                api: ''
            }
        }
    },
    created() {
        this.fetch_question_table_data();
    },
    methods: {
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteQuestion, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_question_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        editItem(item) {
            this.tab = 'two'
            this.item = item
            this.btnBoolean = false;
        },
        toggle_btn() {
            this.btnBoolean = true;
            this.item = {
                question: "",
                answers: [{
                        answer: "",
                        isAnswer: true
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    }
                ],
                is_active: 'inactive',
                question_img: "",
            };
            this.fetch_question_table_data();
        },
        addBtn() {
            if (this.tabTitle == "ADD") {
                this.item = {
                    question: "",
                    answers: [{
                            answer: "",
                            isAnswer: true
                        },
                        {
                            answer: "",
                            isAnswer: false
                        },
                        {
                            answer: "",
                            isAnswer: false
                        },
                        {
                            answer: "",
                            isAnswer: false
                        }
                    ],
                    is_active: 'inactive',
                    question_img: "",
                }
            }
        },
        deleteItem(item) {
            this.indvidualItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.indvidualItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        show_changed_data(message) {
            this.show_snackbar(message)
            this.tab = "one";
            this.fetch_question_table_data();
            this.btnBoolean = true;
            this.item = {
                question: "",
                answers: [{
                        answer: "",
                        isAnswer: true
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    }
                ],
                is_active: 'inactive',
                question_img: "",
            };
        },
        getBtnName() {
            return this.btnBoolean ? 'Add' : 'Edit';
        },
        changed_data() {
            this.fetch_question_table_data();
            this.tab = "one"
            this.tabTitle = "Add"
        },

        async fetch_question_table_data() {
            try {
                this.loadingData = true;
                this.search = '';
                await fetch(`${apiUrl.fetchQuestion}`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },

                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.questionsItems = data.SortedQuestion;
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error = error;
            } finally {
                this.loadingData = false;
                this.selected = [];
            }
        },
        show_snackbar(message) {
            this.successMessage = message;
            this.showSnackbar = true;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }
    }
});
</script>
