<template>
<v-layout class="navigation-height">
    <v-navigation-drawer v-model="drawer" :rail="rail" permanent class="fixed-navbar" :rail-width="customRailWidth">
        <v-list-item title="MWC Admin Portal" class="list-item-ittle" nav>
            <template v-slot:append>
                <v-btn v-if="!rail" variant="text" icon="mdi-chevron-left" @click="rail = !rail"></v-btn>
                <v-btn v-else variant="text" icon="mdi-chevron-right" @click="rail = !rail"></v-btn>
            </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
            <v-list-item @click="selectComponent('accountuser')" prepend-icon="mdi-account-box" title="ACCOUNT USER" value="accountuser"></v-list-item>
            <v-list-item @click="selectComponent('userCartTable')" prepend-icon="mdi-cart" title="CART" value="userCartTable"></v-list-item>
            <v-list-item @click="selectComponent('news')" prepend-icon="mdi-newspaper" title="NEWS" value="news"></v-list-item>
             <v-list-item @click="selectComponent('cartPayment')" prepend-icon="mdi-credit-card" title="PAYMENT" value="Payment"></v-list-item>
            <v-list-item @click="selectComponent('prize')" prepend-icon="mdi-gift" title="PRIZE" value="prize"></v-list-item>
            <v-list-item @click="selectComponent('questions')" prepend-icon="mdi-help-circle" title="QUESTIONS" value="questions"></v-list-item>
            <v-list-item @click="selectComponent('raffleDetails')" prepend-icon="mdi-ticket" title="TICKETS" value="raffleDetails"></v-list-item>
            <v-tooltip location="top" text="RESTRICTION CLIENT BLACKLIST">
                <template v-slot:activator="{ props }">
                    <v-list-item @click="selectComponent('restrictionClientBlacklist')" v-bind="props" prepend-icon="mdi-cancel" title="RESTRICTION CLIENT BLACKLIST" value="restrictionClientBlacklist"></v-list-item>
                </template>
            </v-tooltip>
            <v-list-item @click="selectComponent('walletTable')" prepend-icon="mdi-wallet" title="WALLET" value="walletTable"></v-list-item>
            <v-list-item @click="selectComponent('categoryTable')" prepend-icon="mdi-checkbox-multiple-blank" title="WEBSITE CATEGORY" value="categoryTable"></v-list-item>
            <v-list-item @click="selectComponent('Charity')" prepend-icon="mdi-hand-heart" title="WEBSITE CHARITY" value="charity"></v-list-item>
            <v-list-item @click="selectComponent('OtherSetting')" prepend-icon="mdi-cogs" title="WEBSITE OTHERSETTING" value="OtherSetting"></v-list-item>
            <v-list-item @click="selectComponent('WebsiteRaffle')" prepend-icon="mdi-ticket-outline" title="WEBSITE RAFFLE" value="WebsiteRaffle"></v-list-item>
            <v-list-item @click="selectComponent('drawInstantWinTable')" prepend-icon="mdi-trophy-variant" title="WINNER" value="drawInstantWinTable"></v-list-item>
        </v-list>

    </v-navigation-drawer>
    <v-main>
        <component :is="selectedComponent"></component>
    </v-main>
</v-layout>
</template>

<script>
import '@/assets/css/navigation.css'
import newsTable from '../components/newsPages/newsTable.vue'
import accountuser from '../components/accountUserPages/accountUserTable.vue';
import charityTable from '../components/websiteCharityPages/charityTable.vue';
import otherSettings from '../components/otherSettingsPages/otherSettings.vue';
import websiteRaffle from '../components/websiteRafflePages/websiteRaffleTablesss.vue';
import categoryTable from '@/components/raffoluxCategoryPages/categoryTable.vue'
import restrictionClientBlacklist from '@/components/RestrictionClientBlackListPage/clientBlacklistTable.vue'
import drawInstantWinTable from '@/components/drawInstantWinnerPages/drawInstantWinTable.vue';
import cartPayment from '@/components/cartPayment/cartPaymentTable.vue';
import userCartTable from '@/components/userCartPages/userCartTable.vue'
import questions from "@/components/questionPage/questionTable.vue"
import raffleDetails from "@/components/raffleDetailPage/raffleDetails.vue"
import prize from "@/components/prizePage/prizeTable.vue"
import walletTable from '@/components/walletTable/walletTable.vue'
import {
    markRaw
} from "vue";

export default markRaw({
    components: {
        walletTable,
        newsTable,
        accountuser,
        charityTable,
        otherSettings,
        websiteRaffle,
        categoryTable,
        restrictionClientBlacklist,
        drawInstantWinTable,
        cartPayment,
        userCartTable,
        questions,
        raffleDetails,
        prize
    },
    data() {
        return {
            drawer: true,
            rail: false,
            selectedComponent: null,
            customRailWidth: 72
        };
    },
    created() {
        let Token = sessionStorage.getItem('userToken')
        if (!Token) {
            this.$router.push({
                name: 'loginPage'
            });
        } else {
            this.selectComponent('Home');
        }
    },

    methods: {
        toggleRail() {
            this.rail = !this.rail;
        },
        selectComponent(componentName) {

            switch (componentName.toLowerCase()) {
                case 'accountuser':
                    this.selectedComponent = accountuser;
                    break;
                case 'news':
                    this.selectedComponent = newsTable;
                    break;
                case 'charity':
                    this.selectedComponent = charityTable;
                    break;
                case 'othersetting':
                    this.selectedComponent = otherSettings;
                    break;
                case 'websiteraffle':
                    this.selectedComponent = websiteRaffle;
                    break;
                case 'categorytable':
                    this.selectedComponent = categoryTable;
                    break;
                case 'restrictionclientblacklist':
                    this.selectedComponent = restrictionClientBlacklist;
                    break;
                case 'drawinstantwintable':
                    this.selectedComponent = drawInstantWinTable;
                    break;
                case 'cartpayment':
                    this.selectedComponent = cartPayment;
                    break;
                case 'usercarttable':
                    this.selectedComponent = userCartTable;
                    break;
                case "questions":
                    this.selectedComponent = questions;
                    break;
                case "categorytable":
                    this.selectedComponent = categoryTable;
                    break;
                case "prize":
                    this.selectedComponent = prize;
                    break;
                case "wallettable":
                    this.selectedComponent = walletTable;
                    break;
                case "raffledetails":
                    this.selectedComponent = raffleDetails;
                    break;
                default:
                    this.selectedComponent = accountuser;
            }
            if (window.innerWidth <= 768) {
                this.toggleRail();
            }
            setTimeout(() => {
                if ('scrollBehavior' in document.documentElement.style) {
                    document.documentElement.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                } else {
                    document.documentElement.scrollTo(0, 0);
                }
            }, 100);
        },
    }
});
</script>
