import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/loginPage.vue'
import postLiveDraw from '../components/liveDrawPage/postLiveDraw.vue'
import preLiveDraw from '../components/liveDrawPage/preLiveDrawPage.vue'
const routes = [
  {
    path: '/',
    name: 'loginPage',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/postLiveDraw/:id',
    name: 'postLiveDraw',
    component: postLiveDraw 
  },
  {
    path: '/preLiveDraw/:id',
    name: 'preLiveDraw',
    component: preLiveDraw
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
