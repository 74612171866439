<template>
<v-autocomplete label="User Email*" variant="filled" v-model="emailAddress" :items="allOptionDatail.map(item => item.emailId)" :menu-props="{ minWidth: '300px' }" @update:model-value="handleUserChange" :error-messages="error.user_id" :readonly="!btnNameChange">
</v-autocomplete>
<v-text-field label="Credit Balance*" variant="filled" v-model="editItem.credit_balance" :error-messages="error.credit_balance" ref="credit_balanceField" @input="remove_error('credit_balance')"></v-text-field>

<v-btn @click="validate_inputs()" v-if="btnNameChange" :disabled="buttonDisabled" color="blue">ADD</v-btn>
<v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-else color="blue">SAVE</v-btn>
<logoutDia :showLogoutDialog="showLogoutDialog" />
<div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
</template>

<script>
import apiUrl from '../../api/allApis.js'
import vaildationMixin from "../../mixins/validation.js";
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    mapGetters,
    mapActions
} from "vuex";

import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
    logoutDia,
  },
    mixins: [vaildationMixin, jwtMixin],
    props: {
        btnNameChange: Boolean,
        editItem: Object
    },
    emits: ['data-changed'],
    data() {
        return {
            showLogoutDialog: false,
            successmessage: '',
            error: {
                type: '',
                credit_balance: '',
                user_id: '',
                active: '',
                api: '',
            },
            buttonDisabled: false,
            items: ["true", "false"],

        }
    },
    computed: {
        ...mapGetters(['allOptionDatail']),
        isFormFilled() {
            return (
                this.error.credit_balance === '' &&
                this.error.user_id === ''
            );
        },

    },
    async mounted() {

        this.load_option_data();
        if (!this.btnNameChange) {
            this.OptionObject = this.allOptionDatail.find(item => item.userId === this.editItem.user_id)
            if (this.OptionObject) {
                this.emailAddress = this.OptionObject.emailId
            } else {
                this.emailAddress = "User Email Not Available"
            }
        }

    },
    methods: {
        ...mapActions('option_detail'),
        async load_option_data() {
            let apiEndpoint = apiUrl.fetchaccUserTableData;
            await this.$store.dispatch('option_detail', apiEndpoint);
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
            if (selectedUser) {
                this.editItem.user_id = selectedUser.userId;
                this.emailAddress = selectedUser.emailId;
                this.remove_error('user_id');
            }
        },
        remove_error(value) {
            switch (value) {
                case 'credit_balance':
                    let credit_balance = this.validateNumberField(this.editItem.credit_balance, 'Credit Balance');
                    if (!credit_balance.valid) {
                        this.error.credit_balance = credit_balance.error;
                        break;
                    } else {
                        this.error.credit_balance = '';
                        break;
                    }
                case 'user_id':
                    this.error.user_id = ''
                    break;
                case 'active':
                    this.error.active = ''
                    break;
                default:
                    this.error = {
                        credit_balance: '',
                        user_id: '',
                        active: '',
                        api: '',
                    }
            }
        },
        validate_inputs() {
            let credit_balance = this.valid_inputs(this.editItem.credit_balance);
            let user_id = this.valid_inputs(this.emailAddress);
            if (!user_id.valid) {
                this.error.user_id = user_id.error;
            } else if (!credit_balance.valid) {
                this.error.credit_balance = credit_balance.error;
                this.$refs.credit_balanceField.$el.querySelector('input').focus();
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        type: '',
                        credit_balance: '',
                        user_id: '',
                        active: '',
                        api: '',
                    }
                    if (this.btnNameChange) {
                        this.add_wallet_table_data()
                    } else {
                        this.edit_wallet_table_data()
                    }
                }
            }
        },
        async edit_wallet_table_data() {
            
            try {
                this.buttonDisabled = true;
                this.editItem.credit_balance = Number(parseFloat(this.editItem.credit_balance).toFixed(2));
                let {
                    user_email,
                    ...editWalletItem
                } = this.editItem
                await fetch(apiUrl.updateWallet, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(editWalletItem)
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully saved...";
                            this.$emit("data-changed", this.successMessage);
                        }  else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;

            } finally {
                this.buttonDisabled = false;
            }
        },
        async add_wallet_table_data() {
        
            try {
                this.buttonDisabled = true;
                this.editItem.credit_balance = Number(parseFloat(this.editItem.credit_balance).toFixed(2));
                await fetch(apiUrl.addWallet, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.editItem)
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully added...";
                            this.$emit("data-changed", this.successMessage);
                        }  else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },
    },

});
</script>
