<template>
<v-app-bar color="primary" dense dark>

  <v-toolbar-title align="center">Admin portal</v-toolbar-title>

</v-app-bar>
<v-card flat>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-row justify="space-around">
          <v-card width="400" title="LOGIN" align="center" class="mt-16">
            <v-card-text class="mt-10">
              <v-text-field v-model="loginEmail" label="Email" outlined :error-messages="error.mail" @input="remove_error('mail')" @keyup.enter="this.focusNextField('passwordField')"></v-text-field>
              <v-text-field v-model="loginPassword" :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" label="Password" @click:append-inner="show = !show" :error-messages="error.pass" @input="remove_error('pass')" @keyup.enter="validate_input" ref="passwordField"></v-text-field>
              </v-card-text>
            <v-btn color="primary" class="mb-5 mt-4" @click="validate_input" :loading="logBtnDisable" :disabled="logBtnDisable">
              login
            </v-btn>
            <div v-if="error.api" class="mb-10 mt-0" :style="{ color: 'red' }">{{ error.api }}</div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</v-card>
</template>

<script>
import vaildationMixin from "../mixins/validation.js"
import apiUrl from '../api/allApis.js'
export default {
  mixins: [vaildationMixin],
  data() {
    return {
      logBtnDisable: false,
      loginEmail: '',
      loginPassword: '',
      loginOtp: '',
      show: false,
      error: {
        mail: '',
        pass: '',
      }
    }
  },
  computed: {
    isFormFilled() {
      return (
        this.error.mail === '' || this.error.mail === undefined &&
        this.error.pass === '' || this.error.pass === undefined
      )
    },
  },
  created(){
    let Token=sessionStorage.getItem('userToken');
    if(Token){
      this.router();
    }
  },
  methods: {
    focusNextField(refName) {
      this.$refs[refName].focus();
    },
    remove_error(value) {
      switch (value) {
        case 'mail':
          let mail = this.validate_email(this.loginEmail);
          if (!mail.valid) {
            this.error.mail = mail.error;
            break;
          } else {
            this.error.mail = '';
            break;
          }
        case 'pass':
          let pass = this.validate_pass(this.loginPassword, 'Password');
          if (!pass.valid) {
            this.error.pass = pass.error;
            break;
          } else {
            this.error.pass = '';
            break;
          }
        case 'otp':
          let otp = this.validate_otp(this.loginOtp, 'OTP');
          if (!otp.valid) {
            this.error.otp = otp.error;
            break;
          } else {
            this.error.otp = '';
            break;
          }
        default:
          this.error = {
            mail: '',
            pass: '',
          };
          break;
      }
    },
    validate_input() {
      let mail = this.valid_inputs(this.loginEmail);
      let pass = this.valid_inputs(this.loginPassword);
      if (!mail.valid) {
        this.error.mail = mail.error;
      } else if (!pass.valid) {
        this.error.pass = pass.error;
      }
      else {
        if (this.isFormFilled) {
          this.error = {
            mail: '',
            pass: '',
          }
          this.check_User();
        }
      }
    },
    async check_User() {
      this.logBtnDisable = true;
      try {
        this.btnDisable = true;
        const loginData = {
          email: this.loginEmail.toLowerCase(),
          password: this.loginPassword,
        };
        await fetch(apiUrl.signIn, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(loginData)
          }).then((response) => response.json())
          .then((data) => {
            if (data.status == 200) {
              this.btnDisable = false;
              this.confirmedUser(data.data[0]);
            } else {
              this.btnDisable = false;
              this.error.api = data.message ? data.message : data;
            }
          })
      } catch (error) {
        this.btnDisable = false;
        this.error.api = error;
      } finally {
        this.logBtnDisable = false;
      }
    },
    router(){
      this.$router.push({
        name: 'home'
      })
    },
    confirmedUser(value) {
      let Token = JSON.stringify(value.access_token)
      sessionStorage.setItem('userToken', Token)
      this.router();
    }
  }
}
</script>
