<template>
<v-dialog v-model="showLogoutDialog" max-width="560px" persistent>
    <v-card>
      <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="logout">Ok</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import logoutMixin from '@/mixins/logout';
import {
    markRaw
} from 'vue';
export default markRaw({
  props: {
    showLogoutDialog: Boolean,
  },
  mixins: [logoutMixin],
    data() {
    return {
        dialogMessage: "Your session has been expired,Please log in again",
    }
}
})
</script>
