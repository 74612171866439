import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["allOptionDatail", "allReasonOptionDatail", "allIdNameDetail", "allConditionOptionDetail", "allDeliveryOptionDetail", "allCustomRaffleOptionDetail", "allReffleCodeOptionDetail","allStatus","allWhitelabelWebsiteOptionDetail","allPaymentGatewayOptionDetail","allBankOptionDetail","allPaymentMethodOptionDetail"]),
  },
  methods: {


    username_display(id) {
      if (id && this.allOptionDatail) {
        let userObject = this.allOptionDatail.find(
          (item) => item.userId === id
        );
        if (userObject) {
          return userObject.emailId;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },

    reason_display(reason_id) {
      if (reason_id && this.allReasonOptionDatail) {
        let userObject = this.allReasonOptionDatail.find(
          (item) => item.reasonID === reason_id
        );
        if (userObject) {
          return userObject.reasonNames;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    condition_display(condition_id) {
      if (condition_id && this.allConditionOptionDetail) {
        let userObject = this.allConditionOptionDetail.find(
          (item) => item.conditionID === condition_id
        );
        if (userObject) {
          return userObject.conditionNames;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    raffle_code_display(rafflecode_id) {  
      if (rafflecode_id && this.allReffleCodeOptionDetail) {
        let userObject = this.allReffleCodeOptionDetail.find(
          (item) => item.raffleCodeID === rafflecode_id
        );
        if (userObject) {
          return userObject.raffleCodeName;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    raffle_display(raffle_id) {
      if (raffle_id && this.allReffleCodeOptionDetail) {
        let userObject = this.allReffleCodeOptionDetail.find(
          (item) => item.raffleID === raffle_id
        );
        if (userObject) {
          return userObject.raffleCodeName;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    custom_raffle_display(custom_raffle_id) {
      if (custom_raffle_id && this.allCustomRaffleOptionDetail) {
        let userObject = this.allCustomRaffleOptionDetail.find(
          (item) => item.customRaffleID === custom_raffle_id
        );
        if (userObject) {
          return userObject.custonRaffleNames;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    delivery_option_display(delivery_information_id) {
      if (delivery_information_id && this.allDeliveryOptionDetail) {
        let userObject = this.allDeliveryOptionDetail.find(
          (item) => item.deliveryID === delivery_information_id
        );
        if (userObject) {
          return userObject.deliveryOptions;
        } else {
          return " Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    id_name_display(id) {
      if (id && this.allIdNameDetail) {
        let userObject = this.allIdNameDetail.find(
          (item) => item.allId === id
        );
        if (userObject) {
          return userObject.allNames;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },

    status_name_display(id) {
      if (id && this.allStatus) {
        let userObject = this.allStatus.find(
          (item) => item.statusId === id
        );
        if (userObject) {
          return userObject.statusName;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    website_name_display(id) {
      if (id && this.allWhitelabelWebsiteOptionDetail) {
        let userObject = this.allWhitelabelWebsiteOptionDetail.find(
          (item) => item.websiteId === id
        );
        if (userObject) {
          return userObject.websiteName;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    payment_gateway_name_display(id) {
      if (id && this.allPaymentGatewayOptionDetail) {
        let userObject = this.allPaymentGatewayOptionDetail.find(
          (item) => item.paymentGatewayId === id
        );
        if (userObject) {
          return userObject.paymentGatewayName;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    payment_method_name_display(id) {
      if (id && this.allPaymentMethodOptionDetail) {
        let userObject = this.allPaymentMethodOptionDetail.find(
          (item) => item.paymentMethodId === id
        );
        if (userObject) {
          return userObject.paymentMethodName;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },
    bank_name_display(id) {
      if (id && this.allBankOptionDetail) {
        let userObject = this.allBankOptionDetail.find(
          (item) => item.bankId === id
        );
        if (userObject) {
          return userObject.bankName;
        } else {
          return "Not Found";
        }
      } else {
        return "Not Found";
      }
    },
 
    display_type(type) {
  return type === 0 ? "temporary" : "permanent";
}
  },
};

