<template>
<v-text-field v-if="!btnNameChange" label="ID" variant="filled" v-model="editItem.id" readonly></v-text-field>
<v-text-field label="Name*" variant="filled" v-model="editItem.name" :error-messages="error.name" ref="nameField" @input="remove_error('name')"></v-text-field>
<v-text-field label="Value*" variant="filled" v-model="editItem.value" :error-messages="error.value" ref="valueField" @input="remove_error('value')"></v-text-field>
<v-text-field label="Summary" variant="filled" v-model="editItem.summary" :error-messages="error.summary" ref="summaryField" @input="remove_error('summary')"></v-text-field>

<v-select label="Physical Prize*" variant="filled" :items="items" v-model="editItem.physical_prize" :error-messages="error.physical_prize" @input="remove_error('physical_prize')" ref="physicalPrizeField"></v-select>

<v-btn @click="validate_inputs()" v-if="btnNameChange" :disabled="buttonDisabled" color="blue">ADD</v-btn>
<v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-else color="blue">SAVE</v-btn>
<logoutDia :showLogoutDialog="showLogoutDialog" />
<div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
</template>

<script>
import apiUrl from '../../api/allApis.js'
import vaildationMixin from "../../mixins/validation.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [vaildationMixin, jwtMixin],
    name: "Add",
    props: {
        btnNameChange: Boolean,
        editItem: Object
    },
    emits: ['data-changed'],
    data() {
        return {
            showLogoutDialog: false,
            successmessage: '',
            error: {
                name: '',
                value: '',
                summary: '',
                physical_prize: '',
                api: '',
            },
            buttonDisabled: false,
            items: ["true", "false"],

        }
    },

    computed: {
        isFormFilled() {
            return (
                this.error.name === '' &&
                this.error.value === '' &&
                this.error.physical_prize === ''
            );
        },

    },
    methods: {
        async load_option_data() {
            const apiEndpoint = apiUrl.fetchaccUserTableData;
            await this.$store.dispatch('option_detail', apiEndpoint);
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
            if (selectedUser) {
                this.editItem.user_id = selectedUser.userId;
                this.emailAddress = selectedUser.emailId;
                this.remove_error('user_id');
            }
        },
        remove_error(value) {
            switch (value) {
                case 'name':
                    let name = this.validate_name(this.editItem.name, "Name")
                    if (!name.valid) {
                        this.error.name = name.error;
                        break;
                    } else {
                        this.error.name = '';
                        break;
                    }
                case 'value':
                    let values = this.validateNumberField(this.editItem.value, 'Value');
                    if (!values.valid) {
                        this.error.value = values.error;
                        break;
                    } else {
                        this.error.value = '';
                        break;
                    }
                case 'summary':
                    this.error.summary = ''
                    break;
                case 'physical_prize':
                    this.error.physical_prize = ''
                    break;
                default:
                    this.error = {
                        name: '',
                        value: '',
                        summary: '',
                        physical_prize: '',
                        api: '',
                    }
            }
        },
        validate_inputs() {
            let name = this.valid_inputs(this.editItem.name);
            let value = this.valid_inputs(this.editItem.value);

            if (!name.valid) {
                this.error.name = name.error;
                this.$refs.nameField.$el.querySelector('input').focus();
            } else if (!value.valid) {
                this.error.value = value.error;
                this.$refs.valueField.$el.querySelector('input').focus();
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        name: '',
                        value: '',
                        summary: '',
                        physical_prize: '',
                        api: '',
                    }
                    if (this.btnNameChange) {
                        this.add_prize_table_data()
                    } else {
                        this.edit_prize_table_data()
                    }
                }
            }
        },
        async edit_prize_table_data() {

            try {
                this.buttonDisabled = true;
                this.editItem.value = Number(this.editItem.value)
                this.editItem.physical_prize = JSON.parse(this.editItem.physical_prize)

                await fetch(apiUrl.updatePrize, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.editItem)
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully saved...";
                            this.$emit("data-changed", this.successMessage);
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;

            } finally {
                this.buttonDisabled = false;
            }
        },
        async add_prize_table_data() {

            try {
                this.buttonDisabled = true;
                this.editItem.physical_prize = JSON.parse(this.editItem.physical_prize)
                    this.editItem.value = Number(this.editItem.value)
                    await fetch(apiUrl.addPrize, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.editItem)
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully added...";
                            this.$emit("data-changed", this.successMessage);
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },
    },

});
</script>
