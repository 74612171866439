<template>
    <div v-if="rafData.length > 0 && !videoPlaying">
        <div class="row liveDrawPageNavBarBg m-0 align-items-center">
            <div class="col-4 p-0">
                <p class="liveDrawRaffId mb-0 sortIDTextSize">Raffle ID: {{ sortId }}</p>
            </div>
            <div class="col-4 text-center p-0">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/MWCLogo.svg" alt=""
                    class="liveDrawMwcLogo" />
            </div>
            <div class="col-4 p-0 text-end">
                <p class="liveDrawRaffId mb-0">{{ raffleExpireDate }}</p>
            </div>
        </div>
        <div class="row m-0 liveDrawPageSecBg">
            <div class="col-6 p-0">
                <div class="liveDrawPageLeftSecWidth">
                    <h2 class="liveDrawPageRaffName mb-0">{{ rafData[0].title }}</h2>
                    <p class="liveDrawRaffTktsSold">{{ rafData[0].tickets_sold }} / {{ rafData[0].total_entries }}
                        tickets sold
                    </p>
                    <div class="row m-0 liveDrawRefSec">
                        <div class="col-4 liveDrawPageYearSecBg">
                            <p class="liveDrawYearTxt">YEAR</p>
                            <p class="liveDrawYearNum mb-0">{{ rafData[0].year }}</p>
                        </div>
                        <div class="col-4 liveDrawPageYearSecBg">
                            <p class="liveDrawYearTxt">REF NO.</p>
                            <p class="liveDrawYearNum liveDrawRefNum mb-0">{{ rafData[0].ref_no }}</p>
                        </div>
                        <div class="col-4 liveDrawPageYearSecBg">
                            <p class="liveDrawYearTxt">BOX + PAPERS</p>
                            <div class="d-flex justify-content-center">
                                <!-- <em class="fa-sharp fa-solid fa-circle-check liveDrawCircleIcon"></em> -->
                                <em class="fa-solid fa-circle-xmark liveDrawCircleIcon" aria-hidden="true"></em>
                            </div>
                        </div>
                    </div>
                    <p class="liveDrawRunnerPrizes">
                        <em class="fa-solid fa-gift liveDrawGiftIcon"></em>
                        <span v-if="isRendering"> {{ rafData[0].no_winners - 1 }} X RUNNER UP PRIZES OF £{{
                            isConsolationPrize }}
                            SITE CREDIT</span>
                    </p>
                    <button type="button" class="btn liveDrawTimerBtn" v-if="!this.raffleExpire">{{
                        countdownTimers[this.id]
                    }}</button>
                    <button type="button" class="btn liveDrawRunBtn" :disabled="disabledDrawBtn" v-else
                        @click="open_video" :class="{ 'pop-out': isAnimating }">RUN DRAW</button>
                    <p style="color: red; text-align: center;" class="m-1" v-if="this.error.length > 0">{{ this.error }}
                    </p>
                    <p class="liveDrawResultTxt">Prize draw result generated by Random.org</p>
                    <div class="d-flex justify-content-center">
                        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/randomOrgImg.svg"
                            alt="Main Image" class="liveDrawRandomImg" />
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex justify-content-center">
                    <img :src="img_url + rafData[0].main_image_url" alt="" class="liveDrawRightSecImg" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="videoPlaying" class="video-container">
        <video ref="videoPlayer" :src="videoSrc" @ended="handleVideoEnd" width="100%" :style="videoStyle"></video>
    </div>
</template>

<script>
import "@/assets/css/preLiveDraw.css";
import timerMixin from "../../mixins/timer.js"
import raffleMixin from "../../mixins/raffleMixin.js"
import moment from "moment";
export default {
    components: {},
    mixins: [timerMixin, raffleMixin],
    data: () => ({
        id: '',
        videoSrc: 'https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/video/LiveDrawNewAnimation.mp4',
        raffleExpire: false,
        disabledDrawBtn: false,
        videoPlaying: false,
        isAnimating: false,
        videoStyle: {
            pointerEvents: 'none',
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
        },
        raffleExpireDate: '',
        error: '',
    }),
    watch: {
        rafData(newValue) {
            if (newValue.length > 0) {
                this.checkRefExpire();
                this.disabledDraw();
            }
        }
    },
    methods: {
        handleVideoStart() {
            this.videoPlaying = true;
            this.$nextTick(() => {
                if (this.$refs.videoPlayer) {
                    this.$refs.videoPlayer.play().catch(error => {
                        console.error('Error playing video:', error);
                    });
                }
            });
            this.videoStyle.pointerEvents = 'none';
        },
        handleVideoEnd() {
            this.videoPlaying = false
            this.$router.push({
                name: 'postLiveDraw',
                params: {
                    id: this.id
                }
            })
        },
        open_video() {
            if (this.rafData[0].tickets_sold > 0) {
                if(!this.rafData[0].is_question){
                 this.error = 'All sold tickets with incorrect answers cannot be considered as winning tickets.'
                }else{
                    this.isAnimating = true;
                    setTimeout(() => {
                        this.isAnimating = false;
                        this.handleVideoStart();
                    }, 1500);
                }    
            } else {
                this.error = 'No tickets have been sold for this raffle yet.'
            }
        },
        checkRefExpire() {
            if (this.rafData[0]) {
                let dateTime = this.rafData[0].raffle_expire.split('.000Z')[0]
                let targetDate = moment.tz(dateTime, this.userTimeZone);
                let now = moment.tz(this.userTimeZone);
                let duration = moment.duration(targetDate.diff(now));
                this.raffleExpireDate = moment(dateTime).format('DD / MM / YY');
                if (duration.asSeconds() <= 0) {
                    this.raffleExpire = true;
                } else {
                    this.raffleExpire = false;
                    let timerData = {
                        raffle_expire: this.rafData[0].raffle_expire,
                        raffle_id: this.id
                    };
                    this.startCountdownSingleUk(timerData);
                }
            }
        },
        disabledDraw() {
            if (this.raffleExpire && this.rafData[0].status !== 5 && this.rafData[0].is_live) {
                this.disabledDrawBtn = true;
            } else {
                this.disabledDrawBtn = false;
            }
        }
    }
}
</script>

<style>
.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    cursor: none;
}
</style>
