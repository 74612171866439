import jwtMixin from '@/mixins/jwt.js'
let state = {
  status: [
    {
      statusId: "",
      statusName: "",
    },
  ],
  promotional_token: [
    {
      voucherId: '',
      voucherName:''
    }
  ],
  raf_data:{},
  rafWin_data:[],
};

let actions = {
  async fetch_website_status({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_webSite_status", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async promotional_token({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_promotional_token", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async fetch_raf_data({commit},currentURL){
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_raf_data", data);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async fetch_rafWin_data({commit},currentURL){
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_rafWin_data", data);
        });
    } catch (error) {
      this.error = error;
    }
  }
};

let mutations = {
  set_webSite_status(state, result) {
    state.status = result.map((i) => ({
      statusId: i.id,
      statusName: i.raffle_status,
    }));
  },
  set_promotional_token(state, result) {
    state.promotional_token = result.map((i) => ({
      voucherId: i.id,
      voucherName: `${i.promotion_name}(${i.promotion_description})-${i.token}`,
    }));
  },
  set_raf_data(state, result){
    state.raf_data = result.Items
  },
  set_rafWin_data(state, result){
    state.rafWin_data = result.Items
  }
};

let getters = {
  allStatus: (state) => state.status,
  allPromotionalName:(state)=>state.promotional_token,
  rafData:(state)=>state.raf_data,
  rafWinData:(state)=>state.rafWin_data
};

export default {
  state,
  actions,
  mutations,
  getters,
};