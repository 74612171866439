<template>
<div>
    <v-text-field auto-grow variant="filled" ref="emailField" label="Email*" rows="1" v-model="accUserData.email" :error-messages="error.email" @input="remove_error('email')"></v-text-field>
    <v-text-field auto-grow variant="filled" ref="userNameField" label="User Name*" rows="1" v-model="accUserData.user_name" :error-messages="error.user_name" @input="remove_error('user_name')"></v-text-field>
    <v-row>
        <v-col cols="12" md="4">
            <v-autocomplete label="Country Name*" variant="filled" v-model="country" :error-messages="error.country_code" @update:model-value="remove_error('country')" :items="countryCode.map(country => `${country.dial_code}-${country.name}`)" :menu-props="{ minWidth: '300px' }" ref="countryCodeField"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="8">
            <v-text-field auto-grow variant="filled" ref="contactNumberField" label="Contact Number*" rows="1" v-model="accUserData.contact_number" :error-messages="error.contact_number" @input="remove_error('contact_number')"></v-text-field>
        </v-col>
    </v-row>
    <v-text-field auto-grow variant="filled" autocomplete="new-password" :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" ref="passwordField" label="Password*" rows="1" v-model="accUserData.password" :error-messages="error.password" @input="remove_error('password')" @click:append-inner="show1 = !show1" :readonly="accUserButton"></v-text-field>
    <v-text-field auto-grow variant="filled" ref="firstNameField" label="First Name*" rows="1" v-model="accUserData.first_name" :error-messages="error.first_name" @input="remove_error('first_name')"></v-text-field>
    <v-text-field auto-grow variant="filled" ref="lastNameField" label="Last Name*" rows="1" v-model="accUserData.last_name" :error-messages="error.last_name" @input="remove_error('last_name')"></v-text-field>
    <v-autocomplete v-model="accUserData.type" ref="typeField" :items="typeItems" attach label="Type*" multiple :error-messages="error.type" @update:model-value="remove_error('type')"></v-autocomplete>
    <v-select label="Verified*" :items="items" v-model="accUserData.verified"></v-select>
    <v-select label="New User*" :items="items" ref="newUserField" v-model="accUserData.new_user" :error-messages="error.new_user" @update:model-value="remove_error('newUser')"></v-select>
    <v-text-field auto-grow variant="filled" ref="referralField" label="Referral" rows="1" v-model="accUserData.referral" :error-messages="error.referral" @input="remove_error('referral')" :readonly="accUserButton"></v-text-field>
    <v-text-field auto-grow variant="filled" label="Referral Id" rows="1" v-model="accUserData.referral_id" :error-messages="error.referral_id" @input="remove_error('referral_id')" :readonly="accUserButton" v-if="accUserButton"></v-text-field>
    <v-text-field auto-grow variant="filled" label="Referrer Id" rows="1" v-model="accUserData.referrer_id" :error-messages="error.referrer_id" @input="remove_error('referrer_id')" :readonly="accUserButton" v-if="accUserButton"></v-text-field>
    <v-text-field auto-grow variant="filled" label="Referred Count" rows="1" v-model="accUserData.referred_count" :error-messages="error.referred_count" @input="remove_error('referred_count')" :readonly="accUserButton" v-if="accUserButton"></v-text-field>
    <v-text-field auto-grow variant="filled" label="Referral Expiry" rows="1" v-model="accUserData.referral_expiry" :error-messages="error.referral_expiry" @input="remove_error('referral_expiry')" :readonly="accUserButton" v-if="accUserButton"></v-text-field>
    <label>Marketing:</label>
    <v-select label="Email Opt*" :items="items" v-model="accUserData.marketing.email_opt" :error-messages="error.email_opt" @update:model-value="remove_error('email_opt')"></v-select>
    <v-select label="Sms Opt*" :items="items" v-model="accUserData.marketing.sms_opt" :error-messages="error.sms_opt" @update:model-value="remove_error('sms_opt')"></v-select>
    <div v-if=accUserButton>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Profile Photo</v-card-title>
                    <v-img :src="img_url + accUserData.profile_photo" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="img_upload">{{ imgUpload ? 'Close'
                            : (accUserData.profile_photo ? 'Edit Image' : 'Add Image')
                            }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>

    <v-file-input accept="image/*" v-model="imgValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Profile Photo" :error-messages="error.profile_photo" @change=" this.remove_error('profile_photo');" v-if="imgUpload"></v-file-input>
    <div class="mt-10">
        <v-btn color="blue" type="submit" v-if="!accUserButton" @click=validate_inputs() :disabled="btnDisable">
            Add
        </v-btn>
        <v-btn color="blue" class="me-4" type="submit" v-else @click=validate_inputs() :disabled="btnDisable">
            SAVE
        </v-btn>
    </div>
    <div v-if="error.api" :style="{ color: 'red', 'text-align': 'center' }">{{ error.api }}</div>
</div>
<v-dialog v-model="dialogForDelete" max-width="560px" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
<logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import '@/assets/css/dateFormate.css'
import apiUrl from '@/api/allApis';
import vaildationMixin from "../../mixins/validation.js"
import imgUploadMixin from "../../mixins/imgUpload.js"
import {
    countryCodeArray
} from "../../countryData/countryCodeArray.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [vaildationMixin, imgUploadMixin, jwtMixin],
    data() {
        return {
            showLogoutDialog: false,
            imgUpdated: false,
            country: null,
            countryCode: countryCodeArray,
            datePickerDialog: false,
            successMessage: '',
            deleteDetails: null,
            deleteId: null,
            dialogForDelete: false,
            show1: false,
            btnDisable: false,
            openBankPage: false,
            openPaypalPage: false,
            openAddressPage: false,
            editBankButton: false,
            editPaypalButton: false,
            dialogMessage: 'Are you sure you want to delete this item?',
            addressDetails: {
                id: '',
                serial_no: '',
                fullname: '',
                phone_no: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                city: '',
                active: 'false'
            },
            paypalDetails: {
                id: '',
                serial_no: '',
                mail_id: '',
                active: 'false'
            },
            bankDetails: {
                id: '',
                serial_no: '',
                bank_name: '',
                account_number: '',
                sort_code: "",
                active: 'false'
            },
            bankData: [],
            paypalData: [],
            addressData: [],
            typeItems: ["superUser", "staff", "guest", "user"],
            items: ["true", "false"],
            error: {
                country: '',
                email: '',
                user_name: '',
                contact_number: '',
                country_code: '',
                password: '',
                first_name: '',
                last_name: '',
                type: '',
                is_social_user: '',
                new_user: '',
                expiry_activation: '',
                profile_photo: '',
                referral: '',
                bonus: '',
                sms_code: '',
                access_token: '',
                provider: '',
                uid: '',
                mail_id: '',
                addressFullname: '',
                addressPhoneNo: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                addressCity: '',
                bank_name: '',
                account_number: '',
                sort_code: '',
                api: ''
            },
            imgValue: '',
            signedUrl: '',

            imgUpload: false,
        }
    },
    props: {
        accUserData: Object,
        accUserButton: false
    },

    computed: {
        minDate() {
            return new Date().toISOString().split('T')[0];
        },
        isFormFilled() {
            return (
                (this.error.email === '' || this.error.email === undefined) &&
                (this.error.contact_number === '' || this.error.contact_number === undefined) &&
                (this.error.country_code === '' || this.error.country_code === undefined) &&
                (this.error.password === '' || this.error.password === undefined) &&
                (this.error.first_name === '' || this.error.first_name === undefined) &&
                (this.error.last_name === '' || this.error.last_name === undefined) &&
                (this.error.type === '' || this.error.type === undefined) &&
                (this.error.profile_photo === '' || this.error.profile_photo === undefined) &&
                (this.error.referral === '' || this.error.referral === undefined)
            )
        },
        isBankFormFilled() {
            return (
                (this.error.bank_name === '' || this.error.bank_name == undefined) &&
                (this.error.account_number === '' || this.error.account_number == undefined) &&
                (this.error.sort_code === '' || this.error.sort_code === undefined)
            )
        },
        isPaypalFormFilled() {
            return (
                this.error.mail_id === '' || this.error.mail_id === undefined
            )
        },
        isAddressFormFilled() {

            return (
                (this.error.addressFullname === '' || this.error.addressFullname === undefined) &&
                (this.error.addressPhoneNo === '' || this.error.addressPhoneNo === undefined) &&
                (this.error.address_line_1 === '' || this.error.address_line_1 === undefined) &&
                (this.error.address_line_2 === '' || this.error.address_line_2 === undefined) &&
                (this.error.post_code === '' || this.error.post_code === undefined) &&
                (this.error.addressCity === '' || this.error.addressCity === undefined)
            )
        },
    },
    created() {
        let parsedbankData;
        let parsedpaypalData;
        let parsedaddressData;
        if (this.accUserData.accounts) {
            if (this.accUserData.accounts.bank) {
                parsedbankData = this.accUserData.accounts.bank.map((item, index) => ({
                    serial_no: index + 1,
                    id: item.id,
                    bank_name: item.bank_name,
                    account_number: item.account_number,
                    sort_code: item.sort_code,
                    active: item.active
                }));
            }
        } else {
            parsedbankData = []
        }
        if (this.accUserData.accounts) {
            if (this.accUserData.accounts.paypal) {
                parsedpaypalData = this.accUserData.accounts.paypal.map((item, index) => ({
                    serial_no: index + 1,
                    id: item.id,
                    mail_id: item.mail_id,
                    active: item.active
                }));
            }
        } else {
            parsedpaypalData = []
        }
        if (this.accUserData.accounts) {
            if (this.accUserData.accounts.address) {
                parsedaddressData = this.accUserData.accounts.address.map((item, index) => ({
                    serial_no: index + 1,
                    id: item.id,
                    fullname: item.fullname,
                    phone_no: item.phone_no,
                    address_line_1: item.address_line_1,
                    address_line_2: item.address_line_2,
                    post_code: item.post_code,
                    city: item.city,
                    active: item.active
                }));
            }
        } else {
            parsedaddressData = []
        }
        sessionStorage.setItem('bankDetails', JSON.stringify(parsedbankData))
        sessionStorage.setItem('paypalDetails', JSON.stringify(parsedpaypalData))
        sessionStorage.setItem('addressDetails', JSON.stringify(parsedaddressData))
    },
    mounted() {

        if (this.accUserButton) {
            let matchingCountry = this.countryCode.find(country => country.dial_code === this.accUserData.country_code);

            if (matchingCountry) {
                this.country = `${matchingCountry.dial_code}-${matchingCountry.name}`;
            } else {
                console.log("Country code not found in the array.");
            }
        }
        if (!this.accUserButton) {
            this.imgUpload = true
        }
        const storedBankData = sessionStorage.getItem('bankDetails');
        if (storedBankData) {
            let parsedData = JSON.parse(storedBankData);
            if (Array.isArray(parsedData)) {
                this.bankData = parsedData;
            } else if (typeof parsedData === 'object') {
                this.bankData.push(parsedData);
            } else {
                this.bankData = [];
            }
        } else {
            this.bankData = []
        }
        const storedPaypalData = sessionStorage.getItem('paypalDetails');
        if (storedPaypalData) {
            const parsedData = JSON.parse(storedPaypalData);
            if (Array.isArray(parsedData)) {
                this.paypalData = parsedData;
            } else if (typeof parsedData === 'object') {
                this.paypalData.push(parsedData);
            } else {
                this.paypalData = [];
            }
        } else {
            this.paypalData = []
        }
        const storedAddressData = sessionStorage.getItem('addressDetails');
        if (storedAddressData) {
            const parsedData = JSON.parse(storedAddressData);
            if (Array.isArray(parsedData)) {
                this.addressData = parsedData;
            } else if (typeof parsedData === 'object') {
                this.addressData.push(parsedData);
            } else {
                this.addressData = [];
            }
        } else {
            this.addressData = []
        }
    },
    methods: {
        img_upload() {
            this.imgUpload = !this.imgUpload;
            this.error.profile_photo = '';
        },
        close_selected_delete() {
            this.dialogForDelete = false;
            this.deleteId = null;
            this.deleteDetails = null;
        },
        delete_batch_item() {
            switch (this.deleteDetails) {
                case 'Bank Details':
                    this.delete_bank_item(this.deleteId);
                    break;
                case 'Paypal Details':
                    this.delete_paypal_item(this.deleteId);
                    break;
                case 'Address Details':
                    this.delete_address_item(this.deleteId);
                    break;
                default:
                    this.delete_bank_item(this.deleteId);
                    break;
            }
        },
        delete_bank(id) {
            if (!this.openBankPage) {
                this.dialogForDelete = true;
                this.deleteId = id;
                this.deleteDetails = 'Bank Details'
            }
        },
        delete_paypal(id) {
            if (!this.openPaypalPage) {
                this.dialogForDelete = true;
                this.deleteId = id;
                this.deleteDetails = 'Paypal Details'
            }
        },
        delete_address(id) {
            if (!this.openAddressPage) {
                this.dialogForDelete = true;
                this.deleteId = id;
                this.deleteDetails = 'Address Details'
            }
        },
        remove_error(value) {
            switch (value) {
                case 'email':
                    let email = this.validate_email(this.accUserData.email);
                    if (!email.valid) {
                        this.error.email = email.error;
                        break
                    } else {
                        this.error.email = ''
                        break;
                    }
                    case 'user_name':
                        let username = this.validate_name(this.accUserData.user_name, "User Name")
                        if (!username.valid) {
                            this.error.user_name = username.error;
                            break;
                        } else {
                            this.error.user_name = '';
                            break;
                        }
                        case 'contact_number':
                            let phoneNo = this.validate_phone(this.accUserData.contact_number, )
                            if (!phoneNo.valid) {
                                this.error.contact_number = phoneNo.error;
                                break;
                            } else {
                                this.error.contact_number = '';
                                break;
                            }
                            case 'password':
                                let password = this.validate_pass(this.accUserData.password, 'Password');
                                if (!password.valid) {
                                    this.error.password = password.error;
                                    break;
                                } else {
                                    this.error.password = '';
                                    break;
                                }
                                case 'first_name':
                                    let firstName = this.validate_name(this.accUserData.first_name, "First Name")
                                    if (!firstName.valid) {
                                        this.error.first_name = firstName.error;
                                        break;
                                    } else {
                                        this.error.first_name = '';
                                        break;
                                    }
                                    case 'last_name':
                                        let lastname = this.validate_name(this.accUserData.last_name, "Last Name")
                                        if (!lastname.valid) {
                                            this.error.last_name = lastname.error;
                                            break;
                                        } else {
                                            this.error.last_name = '';
                                            break;
                                        }
                                        case 'type':
                                            this.error.type = '';
                                            break;
                                        case 'newUser':
                                            this.error.new_user = '';
                                            break;
                                        case 'email_opt':
                                            this.error.email_opt = '';
                                            break
                                        case 'sms_opt':
                                            this.error.sms_opt = '';
                                            break;
                                        case 'socialUser':
                                            this.error.is_social_user = '';
                                            break;
                                        case 'country':
                                            this.error.country = '';
                                            break;
                                        case 'expiry_activation':
                                            this.error.expiry_activation = '';
                                            break;
                                        case 'profile_photo':
                                            this.error.profile_photo = '';
                                            break;
                                        case 'referral':
                                            this.error.referral = '';
                                            break;
                                        case 'bonus':
                                            let bonus = this.validateNumberField(this.accUserData.bonus, 'Bonus')
                                            if (!bonus.valid) {
                                                this.error.bonus = bonus.error;
                                                break;
                                            } else {
                                                this.error.bonus = '';
                                                break;
                                            }
                                            case 'sms_code':
                                                let smsCode = this.validateNumberField(this.accUserData.sms_code, 'Sms Code')
                                                if (!smsCode.valid) {
                                                    this.error.sms_code = smsCode.error;
                                                    break;
                                                } else {
                                                    this.error.sms_code = '';
                                                    break;
                                                }
                                                case 'access_token':
                                                    this.error.access_token = '';
                                                    break;
                                                case 'provider':
                                                    this.error.provider = '';
                                                    break;
                                                case 'uid':
                                                    this.error.uid = '';
                                                    break;
                                                case 'sort_code':
                                                    let sortCode = this.validateNumberField(this.bankDetails.sort_code, 'Sort Code');
                                                    let sortCodeField = this.validate_sortField(this.bankDetails.sort_code, 'Sort Code');
                                                    if (!sortCode.valid) {
                                                        this.error.sort_code = sortCode.error;
                                                        break;
                                                    } else if (!sortCodeField.valid) {
                                                        this.error.sort_code = sortCodeField.error;
                                                        break;
                                                    } else {
                                                        this.error.sort_code = '';
                                                        break;
                                                    }
                                                    case 'account_number':
                                                        let accountNumber = this.validateNumberField(this.bankDetails.account_number, 'Account Number');
                                                        let accountNumberField = this.validate_accountNumber(this.bankDetails.account_number, 'Account Number');
                                                        if (!accountNumber.valid) {
                                                            this.error.account_number = accountNumber.error;
                                                            break;
                                                        } else if (!accountNumberField.valid) {
                                                            this.error.account_number = accountNumberField.error;
                                                            break;
                                                        } else {
                                                            this.error.account_number = '';
                                                            break;
                                                        }
                                                        case 'bank_name':
                                                            this.error.bank_name = '';
                                                            break;
                                                        case 'mail_id':
                                                            let mailId = this.validate_email(this.paypalDetails.mail_id)
                                                            if (!mailId.valid) {
                                                                this.error.mail_id = mailId.error;
                                                                break;
                                                            } else {
                                                                this.error.mail_id = '';
                                                                break;
                                                            }
                                                            case 'addressFullname':
                                                                this.error.addressFullname = '';
                                                                break;
                                                            case 'addressPhoneNo':
                                                                let PhoneNo = this.validate_phone(this.addressDetails.phone_no)
                                                                if (!PhoneNo.valid) {
                                                                    this.error.addressPhoneNo = PhoneNo.error;
                                                                    break;
                                                                } else {
                                                                    this.error.addressPhoneNo = '';
                                                                    break;
                                                                }
                                                                case 'address_line_1':
                                                                    this.error.address_line_1 = '';
                                                                    break;
                                                                case 'address_line_2':
                                                                    this.error.address_line_2 = '';
                                                                    break;
                                                                case 'post_code':
                                                                    let postCodeField = this.validate_postCode(this.addressDetails.post_code)
                                                                    if (!postCodeField.valid) {
                                                                        this.error.post_code = postCodeField.error;
                                                                        break;
                                                                    } else {
                                                                        this.error.post_code = '';
                                                                        break;
                                                                    }
                                                                    case 'addressCity':
                                                                        this.error.addressCity = '';
                                                                        break;
                                                                    default:
                                                                        this.error = {
                                                                            email: '',
                                                                            user_name: '',
                                                                            contact_number: '',
                                                                            country_code: '',
                                                                            password: '',
                                                                            first_name: '',
                                                                            last_name: '',
                                                                            type: '',
                                                                            is_social_user: '',
                                                                            new_user: '',
                                                                            expiry_activation: '',
                                                                            profile_photo: '',
                                                                            referral: '',
                                                                            bonus: '',
                                                                            sms_code: '',
                                                                            access_token: '',
                                                                            provider: '',
                                                                            uid: '',
                                                                            sort_code: '',
                                                                            account_number: '',
                                                                            bank_name: '',
                                                                            mail_id: '',
                                                                            addressFullname: '',
                                                                            addressPhoneNo: '',
                                                                            address_line_1: '',
                                                                            address_line_2: '',
                                                                            post_code: '',
                                                                            addressCity: '',
                                                                            api: ''
                                                                        }
            }
        },
        validate_address_inputs(Id) {
            let fullname = this.valid_inputs(this.addressDetails.fullname);
            let phoneNo = this.valid_inputs(this.addressDetails.phone_no);
            let addressLine1 = this.valid_inputs(this.addressDetails.address_line_1);
            let addressLine2 = this.valid_inputs(this.addressDetails.address_line_2);
            let postCode = this.valid_inputs(this.addressDetails.post_code);
            let city = this.valid_inputs(this.addressDetails.city);
            if (!fullname.valid) {
                this.error.addressFullname = fullname.error;
            } else if (!phoneNo.valid) {
                this.error.addressPhoneNo = phoneNo.error;
            } else if (!city.valid) {
                this.error.addressCity = city.error;
            } else if (!postCode.valid) {
                this.error.post_code = postCode.error;
            } else if (!addressLine1.valid) {
                this.error.address_line_1 = addressLine1.error;
            } else if (!addressLine2.valid) {
                this.error.address_line_2 = addressLine2.error;
            } else {
                if (this.isAddressFormFilled) {
                    this.error = {
                        addressFullname: '',
                        addressPhoneNo: '',
                        address_line_1: '',
                        address_line_2: '',
                        post_code: '',
                        addressCity: ''
                    }
                    if (!this.editaddressButton) {
                        this.save_addressDetails();
                    } else {
                        this.update_addressDetails(Id);
                    }
                }
            }
        },
        validate_paypal_inputs(Id) {
            let mailId = this.valid_inputs(this.paypalDetails.mail_id);
            if (!mailId.valid) {
                this.error.mail_id = mailId.error;
            } else {
                if (this.isPaypalFormFilled) {
                    this.error = {
                        mail_id: '',
                    };
                    if (!this.editPaypalButton) {
                        this.save_paypalDetails();
                    } else {
                        this.update_paypalDetails(Id);
                    }
                }
            }
        },
        validate_bank_inputs(Id) {
            let sortCode = this.valid_inputs(this.bankDetails.sort_code);
            let accountNumber = this.valid_inputs(this.bankDetails.account_number);
            let bankName = this.valid_inputs(this.bankDetails.bank_name);

            if (!bankName.valid) {
                this.error.bank_name = bankName.error;
            } else if (!accountNumber.valid) {
                this.error.account_number = accountNumber.error;
            } else if (!sortCode.valid) {
                this.error.sort_code = sortCode.error;
            } else {
                if (this.isBankFormFilled) {
                    this.error = {
                        bank_name: '',
                        account_number: '',
                        sort_code: '',
                    }
                    if (!this.editBankButton) {
                        this.save_bankDetails();
                    } else {
                        this.update_bankDetails(Id);
                    }
                }
            }
        },
        validate_inputs() {
            let email = this.valid_inputs(this.accUserData.email);
            let userName = this.valid_inputs(this.accUserData.user_name);
            let contactNumber = this.valid_inputs(this.accUserData.contact_number);
            let password = this.valid_inputs(this.accUserData.password);
            let firstName = this.valid_inputs(this.accUserData.first_name);
            let lastName = this.valid_inputs(this.accUserData.last_name);
            let type = this.valid_inputs(this.accUserData.type);
            let new_user = this.valid_inputs(this.accUserData.new_user);
            let email_opt = this.valid_inputs(this.accUserData.marketing.email_opt);
            let sms_opt = this.valid_inputs(this.accUserData.marketing.sms_opt);
            let country = this.valid_inputs(this.country);
            let profilePhoto;
            if (this.accUserButton) {
                if (this.imgUpload) {
                    profilePhoto = this.valid_inputs(this.imgValue);
                } else {
                    profilePhoto = {
                        valid: true,
                        error: ""
                    }
                }
            } else {
                profilePhoto = {
                    valid: true,
                    error: ""
                }
            }
            if (!email.valid) {
                this.error.email = email.error;
                this.$refs.emailField.$el.querySelector('input').focus()
            } else if (!userName.valid) {
                this.error.user_name = userName.error;
                this.$refs.userNameField.$el.querySelector('input').focus();
            } else if (!country.valid) {
                this.error.country = country.error;
                this.$refs.countryCodeField.$el.querySelector('input').focus();
            } else if (!contactNumber.valid) {
                this.error.contact_number = contactNumber.error;
                this.$refs.contactNumberField.$el.querySelector('input').focus();
            } else if (!password.valid) {
                this.error.password = password.error;
                this.$refs.passwordField.$el.querySelector('input').focus();
            } else if (!firstName.valid) {
                this.error.first_name = firstName.error;
                this.$refs.firstNameField.$el.querySelector('input').focus();
            } else if (!lastName.valid) {
                this.error.last_name = lastName.error;
                this.$refs.lastNameField.$el.querySelector('input').focus();
            } else if (!type.valid) {
                this.error.type = type.error;
                this.$refs.typeField.$el.querySelector('input').focus();
            } else if (!new_user.valid) {
                this.error.new_user = new_user.error;
                this.$refs.newUserField.$el.querySelector('input').focus();
            } else if (!email_opt.valid) {
                this.error.email_opt = email_opt.error;
            } else if (!sms_opt.valid) {
                this.error.sms_opt = sms_opt.error;
            } else if (!profilePhoto.valid) {
                this.error.profile_photo = profilePhoto.error;
            } else {
                if (this.isFormFilled) {
                    if (!this.accUserButton) {
                        this.accUser_add_item();
                    } else {
                        this.accUser_edit_item();
                    }
                }
            }
        },
        update_bankDetails(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('bankDetails'));
            if (existingData && Array.isArray(existingData)) {
                const indexToUpdate = existingData.findIndex(item => item.serial_no === Id);
                if (indexToUpdate !== -1) {
                    this.bankDetails.active = JSON.parse(this.bankDetails.active);
                    existingData[indexToUpdate] = this.bankDetails;
                    sessionStorage.setItem('bankDetails', JSON.stringify(existingData));
                    this.editBankButton = false;
                    this.openBankPage = false;
                    this.bankData = existingData;
                    this.bankDetails = {
                        id: '',
                        serial_no: '',
                        bank_name: '',
                        account_number: '',
                        sort_code: '',
                        active: 'false'
                    }
                }
            }
        },
        edit_bank_item(Id) {
            this.error = {
                bank_name: '',
                account_number: '',
                sort_code: '',
            }
            this.editBankButton = true;
            let editResult = this.bankData.find(item => item.account_number === Id)
            this.bankDetails = editResult;

            this.openBankPage = true;
        },
        delete_bank_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('bankDetails'));

            if (existingData && Array.isArray(existingData)) {
                let indexToRemove = existingData.find(item => item.account_number === Id)
                if (indexToRemove !== -1) {
                    existingData.splice(indexToRemove, 1);
                    sessionStorage.setItem('bankDetails', JSON.stringify(existingData));
                    this.bankData = existingData
                    this.dialogForDelete = false;
                }
            }
        },
        save_bankDetails() {
            this.bankDetails.serial_no = this.bankData.length + 1;
            this.bankDetails.active = JSON.parse(this.bankDetails.active)
            let existingData = JSON.parse(sessionStorage.getItem('bankDetails')) || [];
            let itemExists = existingData.find(item => item.account_number === this.bankDetails.account_number)
            if (!itemExists) {
                existingData.push(this.bankDetails);
                sessionStorage.setItem('bankDetails', JSON.stringify(existingData));
                this.bankData = existingData;
                this.bankDetails = {
                    id: '',
                    serial_no: '',
                    bank_name: '',
                    account_number: '',
                    sort_code: '',
                    active: 'false'
                }
                this.openBankPage = false
            } else {
                this.error.account_number = "Bank with the same Account Number already exists."
            }
        },
        open_bankInputs() {
            this.error = {
                bank_name: '',
                account_number: '',
                sort_code: '',
            }
            this.bankDetails = {
                id: '',
                serial_no: '',
                bank_name: '',
                account_number: '',
                sort_code: '',
                active: 'false'
            }
            this.openAddressPage = false;
            this.openPaypalPage = false;
            this.openBankPage = true
        },
        update_paypalDetails(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('paypalDetails'));

            if (existingData && Array.isArray(existingData)) {
                const indexToUpdate = existingData.findIndex(item => item.serial_no === Id);
                if (indexToUpdate !== -1) {
                    this.paypalDetails.active = JSON.parse(this.paypalDetails.active);
                    existingData[indexToUpdate] = this.paypalDetails;

                    sessionStorage.setItem('paypalDetails', JSON.stringify(existingData));
                    this.editPaypalButton = false;
                    this.openPaypalPage = false;
                    this.paypalData = existingData;
                    this.paypalDetails = {
                        id: '',
                        serial_no: '',
                        mail_id: '',
                        active: 'false'
                    }
                }
            }
        },
        delete_paypal_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('paypalDetails'));

            if (existingData && Array.isArray(existingData)) {
                const indexToRemove = existingData.findIndex(item => item.mail_id === Id);
                if (indexToRemove !== -1) {
                    existingData.splice(indexToRemove, 1);
                    sessionStorage.setItem('paypalDetails', JSON.stringify(existingData));
                    this.paypalData = existingData;
                    this.dialogForDelete = false;
                }
            }
        },
        edit_paypal_item(Id) {
            this.error = {
                mail_id: '',
            };
            this.editPaypalButton = true;
            let editResult = this.paypalData.find(item => item.mail_id === Id)
            this.paypalDetails = editResult;
            this.openPaypalPage = true;
        },
        save_paypalDetails() {
            this.paypalDetails.serial_no = this.paypalData.length + 1;
            this.paypalDetails.active = JSON.parse(this.paypalDetails.active);
            let existingData = JSON.parse(sessionStorage.getItem('paypalDetails')) || [];
            let itemExists = existingData.find(item => item.mail_id === this.paypalDetails.mail_id)
            if (!itemExists) {
                existingData.push(this.paypalDetails);
                sessionStorage.setItem('paypalDetails', JSON.stringify(existingData));
                this.paypalData = existingData;
                this.paypalDetails = {
                    id: '',
                    mail_id: '',
                    serial_no: '',
                    active: 'false'
                }
                this.openPaypalPage = false
            } else {
                this.error.mail_id = "Paypal with the same Mail Id already exists."
            }
        },
        open_paypalInputs() {
            this.error = {
                paypalId: '',
                mail_id: '',
            };
            this.paypalDetails = {
                id: '',
                mail_id: '',
                serial_no: '',
                active: 'false'
            }
            this.openBankPage = false;
            this.openAddressPage = false;
            this.openPaypalPage = true;
        },
        update_addressDetails(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('addressDetails'));

            if (existingData && Array.isArray(existingData)) {
                const indexToUpdate = existingData.findIndex(item => item.serial_no === Id);
                if (indexToUpdate !== -1) {
                    this.addressDetails.active = JSON.parse(this.addressDetails.active);
                    existingData[indexToUpdate] = this.addressDetails;

                    sessionStorage.setItem('addressDetails', JSON.stringify(existingData));
                    this.editaddressButton = false;
                    this.openAddressPage = false;
                    this.addressData = existingData;
                    this.addressDetails = {
                        id: '',
                        serial_no: '',
                        fullname: '',
                        phone_no: '',
                        address_line_1: '',
                        address_line_2: '',
                        post_code: '',
                        city: '',
                        active: 'false'
                    }
                }
            }
        },
        delete_address_item(Id) {
            let existingData = JSON.parse(sessionStorage.getItem('addressDetails'));

            if (existingData && Array.isArray(existingData)) {
                const indexToRemove = existingData.findIndex(item => item.serial_no === Id);
                if (indexToRemove !== -1) {
                    existingData.splice(indexToRemove, 1);
                    existingData.forEach((item, index) => {
                        item.serial_no = index + 1;
                    });
                    sessionStorage.setItem('addressDetails', JSON.stringify(existingData));
                    this.addressData = existingData;
                    this.dialogForDelete = false;
                }
            }
        },
        edit_address_item(Id) {
            this.error = {
                addressFullname: '',
                addressPhoneNo: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                addressCity: ''
            }
            this.editaddressButton = true;
            let editResult = this.addressData.find(item => item.serial_no === Id)
            this.addressDetails = editResult;
            this.openAddressPage = true;
        },
        save_addressDetails() {
            this.addressDetails.serial_no = this.addressData.length + 1;
            this.addressDetails.active = JSON.parse(this.addressDetails.active);
            let existingData = JSON.parse(sessionStorage.getItem('addressDetails')) || [];
            existingData.push(this.addressDetails);
            sessionStorage.setItem('addressDetails', JSON.stringify(existingData));
            this.addressData = existingData;
            this.addressDetails = {
                id: '',
                fullname: '',
                phone_no: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                city: '',
                active: 'false'
            }
            this.openAddressPage = false
        },
        open_addressInputs() {
            this.error = {
                addressFullname: '',
                addressPhoneNo: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                addressCity: ''
            }
            this.addressDetails = {
                id: '',
                fullname: '',
                phone_no: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                city: '',
                active: 'false'
            }
            this.openBankPage = false;
            this.openPaypalPage = false;
            this.openAddressPage = true;
        },
        async accUser_add_item() {
            try {
                this.btnDisable = true;
                this.accUserData.blackListed = JSON.parse(this.accUserData.blackListed)
                this.accUserData.verified = JSON.parse(this.accUserData.verified)
                this.accUserData.email = this.accUserData.email.toLowerCase();
                this.accUserData.country_code = this.country.split("-")[0];
                this.accUserData.new_user = JSON.parse(this.accUserData.new_user)
                this.accUserData.marketing.email_opt = JSON.parse(this.accUserData.marketing.email_opt)
                this.accUserData.marketing.sms_opt = JSON.parse(this.accUserData.marketing.sms_opt)
                if (this.imgValue.length > 0) {
                    this.accUserData.profile_photo = this.imgValue[0].name
                } else {
                    this.accUserData.profile_photo = ''
                }
                await fetch(apiUrl.addaccUserTableData, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.accUserData)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully added...";
                            if (this.imgValue.length > 0) {
                                this.signedUrl = data.uploadUrl;
                                let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                if (message === "File uploaded successfully") {
                                    this.$emit("switchaccUserTab", this.successMessage);
                                } else {
                                    this.error.api = message;
                                }
                            } else {
                                this.$emit("switchaccUserTab", this.successMessage);
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.btnDisable = false;
            }
        },
        destructuring_data(accUserData) {
            let {
                common_id,
                created,
                updated,
                creditId,
                ...accUserUpdatedData
            } = accUserData;
            accUserUpdatedData.contact_number = this.accUserData.contact_number
            accUserUpdatedData.email = this.accUserData.email.toLowerCase();

            accUserUpdatedData.country_code = this.country.split("-")[0];
            accUserUpdatedData.blackListed = JSON.parse(accUserUpdatedData.blackListed)
            accUserUpdatedData.verified = JSON.parse(accUserUpdatedData.verified)
            accUserUpdatedData.new_user = JSON.parse(accUserUpdatedData.new_user)
            accUserUpdatedData.marketing.email_opt = JSON.parse(accUserUpdatedData.marketing.email_opt)
            accUserUpdatedData.marketing.sms_opt = JSON.parse(accUserUpdatedData.marketing.sms_opt)
            return accUserUpdatedData
        },
        async accUser_edit_item() {
            try {
                this.btnDisable = true
                let accUserData = this.destructuring_data(this.accUserData)

                this.imgUpdated = this.imgUpload
                if (this.imgUpload) {
                    accUserData.profile_photo = this.imgValue.length > 0 ? this.imgValue[0].name : accUserData.profile_photo
                };
                let body = {
                    ...accUserData,
                    img_updated: this.imgUpdated,
                };
                await fetch(apiUrl.updateaccUserTableData, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(body)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully saved...";
                            if (this.imgUpdated === true) {
                                if (this.imgValue.length > 0) {
                                    this.signedUrl = data.uploadURL;
                                    let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                    if (message === "File uploaded successfully") {
                                        this.$emit("switchaccUserTab", this.successMessage);
                                    } else {
                                        this.error.api = message;
                                    }
                                } else {
                                    this.$emit("switchaccUserTab", this.successMessage);

                                }
                            } else {
                                this.successMessage = "Successfully saved...";
                                this.$emit("switchaccUserTab", this.successMessage);
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.btnDisable = false;
            }
        }
    }
})
</script>
