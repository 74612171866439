<template>
    <v-card flat title="TICKETS">
        <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search"
            v-if="tab === 'one'">
        </SearchComponent>
        <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="one" @click="fetch_raffle_table_data">View</v-tab>
        </v-tabs>
        <v-card-text>
            <v-items v-model="tab">
                <v-tab-item value="one" v-if="tab === 'one'">
                    <template v-slot:text>
                    </template>
                    <div v-if="loadingData" style="text-align: center;">
                        Loading...
                    </div>
                    <tableLayout :headers="rafflesHeader" :items="raffleItems" :slotNames="raffleSlotNames"
                        @updatedItems="updated_items" :search="search" v-else>
                        <template v-slot:Actions="{ item }">
                            <v-btn color="blue darken-1" text @click="raffle_tickets_view(item.sort_id)">view
                                tickets</v-btn>
                        </template>
                    </tableLayout>
                </v-tab-item>
            </v-items>
        </v-card-text>
    </v-card>
    <v-dialog v-model="dialogForRaffleTickets" max-width="990px" persistent>
        <v-card>
            <v-card-title class="text-size d-flex justify-evevly align-center">
                <div class="flex-grow-1 text-center">
                    {{ dialogMessage }}
                </div>
                <div class="mr-4">
                    <v-tooltip location="bottom" text="RELOAD">
                        <template v-slot:activator="{ props }">
                            <v-icon small class="" @click="this.refresh(sort_id)" v-bind="props" color="grey">mdi-refresh</v-icon>
                        </template>
                    </v-tooltip>
                </div>
                <div>
                    <v-icon small class="" @click="close_raffle_tickets" color="grey">mdi-close-circle</v-icon>
                </div>

            </v-card-title>
            <v-card-actions class="d-inline">
                <div>
                    <v-row class="align-center">
                        <v-col xs="12" sm="12" md="12" lg="10" xl="10">
                            <SearchComponent :filterSlotNames="filterTicketSlotNames"
                                @update:search="update_searchTicket" :v-if="!loadingTicket" :value="searchTicket"
                                @keydown.enter="search_tickets(sort_id)">
                                <template v-slot:actions>
                                    <v-select :items="actionsItems" @update:model-value="handle_action_change"
                                        hide-details v-model="actionValue" variant="solo-filled"
                                        label="Search By"></v-select>
                                </template>
                            </SearchComponent>
                            <div class="ml-3">

                                <div v-if="error.ticket_number" :style="{ color: 'red' }">{{ error.ticket_number }}
                                </div>
                                <div v-if="error.user_id" :style="{ color: 'red' }">{{ error.user_id }}</div>
                            </div>
                        </v-col>
                        <v-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <v-btn color="blue darken-1" text @click="search_tickets(sort_id)"
                                :disabled="searchBtn">Search</v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="loadingTicket" style="text-align: center;">Loading...</div>
                    <tableLayout :headers="raffleTicketsHeaders" :items="raffleTicketsDetails"
                        :slotNames="raffleSlotTicket" v-else>
                        <template v-slot:updated_at="{ item }">
                            <span>{{ this.formate_Date(item.updated_at) }}</span>
                        </template>
                    </tableLayout>
                    <div class="d-flex justify-content-end" v-if="moreData">
                        <v-btn color="blue darken-1" text @click="raffle_tickets_view(sort_id)"
                            :disabled="loadMore">Load
                            More</v-btn>
                    </div>
                </div>
                <v-spacer></v-spacer>

                <div class="d-flex justify-center">
                    <div v-if="error.raffleTicket" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{
                        error.raffleTicket }}</div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import tableLayout from '../ReusableComponents/tableLayout.vue'
import apiUrl from '../../api/allApis.js'
import formateDateMixin from "../../mixins/formateDate.js"
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    markRaw
} from 'vue';
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
export default markRaw({
    mixins: [jwtMixin, formateDateMixin],
    components: {
        tableLayout,
        SearchComponent,
        logoutDia
    },
    data() {
        return {
            actionsItems: ['Search By Ticket', 'Search By Email', 'Search By Cart ID'],
            actionValue: 'Search By Ticket',
            filterSlotNames: [],
            filterTicketSlotNames: ['actions'],
            dialogMessage: "",
            loadMore: false,
            searchTicket: '',
            searchTicketDup: '',
            showLogoutDialog: false,
            loadingTicket: false,
            lastEvaluatedKey: null,
            item: {},
            tab: null,
            btnBoolean: true,
            moreData: true,
            raffleSlotNames: ['Actions'],
            raffleSlotTicket: ['updated_at'],
            loadingData: false,
            rafflesHeader: [{
                align: "center",
                key: "title",
                title: 'Raffle Name',
            },
            {
                align: "center",
                key: "status",
                title: 'Status'
            },
            {
                align: "center",
                key: 'Actions',
                title: 'Actions'
            },

            ],
            raffleTicketsHeaders: [{
                align: "center",
                key: 'ticket_number',
                title: 'Ticket Number'
            },
            {
                key: 'cart_id',
                title: 'Cart Id'
            },
            {
                key: 'price',
                title: 'Price'
            },
            {
                key: 'user_email',
                title: 'User Email'
            },
            {
                key: 'user_name',
                title: 'User Name'
            },
            {
                key: 'updated_at',
                title: 'Updated At'
            },

            ],
            raffleItems: [],
            raffleTicketsDetails: [],
            dialogForRaffleTickets: false,
            search: '',
            emailAddress: "",
            userId: "",
            error: {
                raffleTicket: "",
                ticket_number: "",
                user_id: "",
            },
            sort_id: ''
        }
    },
    async mounted() {
        this.load_option_data();
    },
    created() {
        this.fetch_raffle_table_data();
    },
    computed: {
        ...mapGetters(['allOptionDatail']),
        searchBtn() {
            return !(this.searchTicket.length > 0)
        }
    },
    methods: {
        ...mapActions(['option_detail']),
        async load_option_data() {
            const apiEndpoint = `${apiUrl.fetchaccUserTableData}`;
            await this.$store.dispatch('option_detail', apiEndpoint);
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.searchTicket);
            if (selectedUser) {
                this.userId = selectedUser.userId;
            }
        },
        close_raffle_tickets() {
            this.dialogForRaffleTickets = false;
            this.raffleTicketsDetails = [];
            this.lastEvaluatedKey = null;
            this.moreData = true;
            this.error = {
                raffleTicket: "",
                ticket_number: "",
                user_id: "",
            }
            this.actionValue = 'Search By Ticket'
            this.searchTicket = '';
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        update_searchTicket(newSearch) {
            this.searchTicket = newSearch.trim();
            this.error.user_id = ""
            this.error.ticket_number = ""
            this.userId = "";
        },
        async search_tickets(sort_id) {
            try {
                let search = this.searchBtn;
                if (search) return
                this.loadingTicket = true;
                this.loadMore = true;
                let body;
                this.sort_id = sort_id;
                if (this.actionValue == "Search By Ticket") {
                    let searchTicket;
                    searchTicket = Number(this.searchTicket)
                    if (!searchTicket) {
                        this.error.ticket_number = "Invalid Ticket Number"
                    }
                    body = {
                        raffle_id: this.sort_id,
                        ticket_number: searchTicket,
                    }
                } else if (this.actionValue == "Search By Email") {
                    this.handleUserChange();
                    if (!this.userId) {
                        this.error.user_id = "Email Not Found"
                    }
                    body = {
                        raffle_id: this.sort_id,
                        user_id: this.userId,
                    }
                } else {

                    body = {
                        raffle_id: this.sort_id,
                        cart_id: this.searchTicket,
                    }
                }
                await fetch(apiUrl.filterRaffleTickets, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(body)
                }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.raffleTicketsDetails = data.Items;
                        }
                    })
            } catch (error) {
                this.error.raffleTicket = error;
                throw error
            } finally {
                this.loadingTicket = false;
                this.loadMore = false;

            }
        },
        handle_action_change() {
            this.error.user_id = ""
            this.error.ticket_number = ""
        },
        async fetch_raffle_table_data() {
            try {
                this.loadingData = true;
                this.search = '';
                await fetch(`${apiUrl.fetchRaffleTableData}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        'Authorization': JSON.parse(this.jwt_token())
                    },

                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.raffleItems = data.SortedRaffle;
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }

                    });
            } catch (error) {
                this.error = error;
            } finally {
                this.loadingData = false;
            }
        },
        refresh(sort_id) {
            this.lastEvaluatedKey = null;
            this.moreData = true;
            this.raffleTicketsDetails = [];
            this.raffle_tickets_view(sort_id);
        },
        async raffle_tickets_view(sort_id) {
            try {
                this.searchTicket = '';
                this.actionValue = 'Search By Ticket';
                this.dialogForRaffleTickets = true;
                this.loadingTicket = true;
                this.loadMore = true;
                this.dialogMessage = 'Tickets for this raffle'
                this.sort_id = sort_id;
                let body = {
                    sort_id: this.sort_id,
                    lastEvaluatedKey: this.lastEvaluatedKey
                }
                await fetch(apiUrl.fetchRaffleTickets, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(body)
                }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            if (data.Items.length > 0) {
                                this.lastEvaluatedKey = data.lastEvaluatedKey || null;
                                this.raffleTicketsDetails = [...this.raffleTicketsDetails, ...data.Items];
                                if (this.lastEvaluatedKey === null) {
                                    this.moreData = false;
                                }
                            } else {
                                this.moreData = false;
                            }
                        } else {
                            this.error.raffleTicket = data.message ? data.message : data
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.raffleTicket = error;
                throw error
            } finally {
                this.loadingTicket = false;
                this.loadMore = false;
            }
        },
    }
});
</script>
