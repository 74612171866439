<template>
<v-card flat title="CART">
    <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'one'">
        <template v-slot:actions>
            <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
        </template>
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_tab_title()">View</v-tab>
        <v-tab value="two" @click="clear_edited_data()" v-if="tabTitle === 'Edit'">{{ tabTitle }}</v-tab>
    </v-tabs>
    <v-card-text>
        <v-tab-items v-model="tab">
            <v-tab-item value="one" v-if="tab === 'one'">
                <div v-if="loader" style="text-align: center">Loading...</div>
                <tableLayout :headers="headers" :items="userCartData" :slotNames="slotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                    <template v-slot:created_at="{ item }">
                        <span>{{ this.formate_Date(item.created_at) }}</span>
                    </template>
                    <template v-slot:Actions="{ item }">
                        <v-icon small @click="deleteItem(item)" color="red darken-1">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:status="{ item }">
                        <span :class="item.status === 1 ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <addUserCart :userCartEditDtls="userCartEditDtls" :userCartSaveBtn="userCartSaveEdit" @switchPrivacyTab="switch_tab"></addUserCart>
            </v-tab-item>
        </v-tab-items>
    </v-card-text>
    <v-dialog v-model="dialogForBatch" max-width="560px" persistent>
        <v-card>
            <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
                <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            <div v-if="error.api" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{ error.api }}</div>
        </v-card>
    </v-dialog>
    <logoutDia :showLogoutDialog="showLogoutDialog" />
    <successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
</v-card>
</template>

<script>
import apiUrl from "@/api/allApis";
import addUserCart from "../userCartPages/addUserCart.vue";
import tableLayout from "../ReusableComponents/tableLayout.vue";
import formateDateMixin from "../../mixins/formateDate.js"
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import jwtMixin from '@/mixins/jwt.js';
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"


export default {
    mixins: [formateDateMixin,jwtMixin],
    components: {
        addUserCart,
        tableLayout,
        SearchComponent,
        successMessageVue,
        logoutDia
    },
    data() {
        return {
            successMessage: "",
            showSnackbar: false,
            showLogoutDialog: false,
            userCartEditDtls: {
                user_id: "",
                status: 0,
                items: [],
            },
            error: {
                api: '',
            },
            tab: null,
            tabTitle: "Add",
            userCartSaveEdit: false,
            loader: false,
            slotNames: ["Actions", 'status', 'created_at'],
            headers: [{
                    key: "user_email",
                    title: "User Email",
                },
                {
                    align: 'start',
                    key: "status",
                    title: "Status",
                },
                {
                  filterable: false,
                    key: "created_at",
                    title: "Created At",
                },
                {
                    align: 'start',
                    key: "Actions",
                    title: "Actions",
                },
            ],
            userCartData: [],
            actionsItems: ['Delete Selected Items'],
            filterSlotNames: ['actions'],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [{
                id: String,
            }],
        };
    },
    created() {
        this.fetch_UserCartData();
    },
    methods: {
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteUserCart, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_UserCartData();
                        }else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        editItem(items) {
            this.userCartEditDtls = items;
            this.tab = "two";
            this.tabTitle = "Edit";
            this.userCartSaveEdit = true;
        },
        clear_edited_data() {
            if (this.tabTitle == "Add") {
                this.userCartEditDtls = {
                    user_id: "",
                    status: 0,
                    items: [],
                }
                this.userCartSaveEdit = false;
            }
        },
        toggle_tab_title() {
            this.tabTitle = "Add";
            this.fetch_UserCartData();
        },
        deleteItem(item) {
            this.deletedUserCartItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.deletedUserCartItem.sort_id,
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        async fetch_UserCartData() {
            try {
                this.search='';
                this.loader = true;
                await fetch(apiUrl.fetchUserCart, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                        this.loader = false;
                        this.userCartData = data.SortedCart;
                        }
                        else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;                            }
                        }
                    });
            } catch (error) {
                this.loader = false;
                throw error;
            }finally{
                this.selected = [];
            }
        },
        switch_tab(message) {
            this.show_snackbar(message)
            this.tab = "one";
            this.tabTitle = "Add";
            this.userCartEditDtls = {
                user_id: "",
                status: 0,
                items: [],
            }
            this.fetch_UserCartData();

        },
        show_snackbar(message) {
            this.showSnackbar = true;
            this.successMessage = message;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }
    },
};
</script>
