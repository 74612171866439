<template>
<v-card flat>
    <v-row align="center">
        <v-col :lg="9" cols="12" class="d-flex justify-content-end pl-5" v-if="filterSlotNames.length > 0"> 
            <div v-for="(slotName, index) in filterSlotNames" :key="index" class="filterDiv">
                <slot :name="slotName"></slot>
            </div>
        </v-col>
        <v-col :lg="filterSlotNames.length > 0 ? 3 : 12" cols="12" class="d-flex justify-end">
            <v-card-item class="searchTag m-0">
                <v-text-field class="p-0 pt-1" v-model="localSearch" append-inner-icon="mdi-magnify" label="Search" variant="outlined" hide-details></v-text-field>
            </v-card-item>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
import '@/assets/css/searchComponent.css';
export default {
    data() {
        return {
            localSearch:'',
        };
    },
    props: {
        tableTitle: String,
        filterSlotNames: Array,
        value: String,
    },
    watch: {
        value(newSearch) {
            this.localSearch = newSearch;
        },
        localSearch(newSearch) {
            this.$emit('update:search', newSearch);
        },
    },
};
</script>
