<template>
<form>
    <v-text-field id="charityName" v-model="charityEditDtls.name" label="Name*" :error-messages="error.name" @input="remove_error('name')"></v-text-field>

    <v-textarea auto-grow rows="1" variant="filled" id="charityDesc" v-model="charityEditDtls.description" label="Description*" :error-messages="error.description" @input="remove_error('description')"></v-textarea>
    <v-textarea auto-grow rows="1" variant="filled" id="charityDesc" v-model="charityEditDtls.description2" label="Description 2" :error-messages="error.description2" @input="remove_error('description2')"></v-textarea>

    <v-select v-model="charityEditDtls.active" id="active" label="Active" :items="items" :error-messages="error.active"></v-select>

    <v-text-field id="charityUrl" v-model="charityEditDtls.url" label="Charity URL" :error-messages="error.url" @input="remove_error('url')"></v-text-field>
    <v-select v-model="charityEditDtls.display" label="Display" id="display" :items="items" :error-messages="error.display"></v-select>
    <v-text-field v-model="charityEditDtls.donation_amount_adjust" id='donationAmountAdjust' label="Donation Amount*" :error-messages="error.donation_amount_adjust" @input="remove_error('donation_amount_adjust')"></v-text-field>
    <v-text-field v-model="charityEditDtls.donation_amount_displayed" id='donationAmountDisplayed' label="Donation Amount Displayed*" :error-messages="error.donation_amount_displayed" @input="remove_error('donation_amount_displayed')"></v-text-field>

    <v-text-field v-model="charityEditDtls.donation_percentage" id="donationPercentage" label="Donation Percentage*" :error-messages="error.donation_percentage" @input="remove_error('donation_percentage')"></v-text-field>
    <v-text-field v-model="charityEditDtls.cost_per_kg_of_plastic" id="costPerKgOfPlastic" label="The Cost Per Kg Of Plastic*" :error-messages="error.cost_per_kg_of_plastic" @input="remove_error('cost_per_kg_of_plastic')"></v-text-field>
    <v-text-field v-model="charityEditDtls.cost_per_bottle" id="costPerBottle" label="The Cost Per Bottle*" :error-messages="error.cost_per_bottle" @input="remove_error('cost_per_bottle')"></v-text-field>

    <v-text-field v-model="charityEditDtls.video_url" label="Video Url*" id="videoUrl" :error-messages="error.video_url" @input="remove_error('video_url')"></v-text-field>
    <v-text-field v-model="charityEditDtls.video_url_mob" label="Video Url Mob*" id="videoUrlMob" :error-messages="error.video_url_mob" @input="remove_error('video_url_mob')"></v-text-field>

    <v-text-field id="charityImgAlt" v-model="charityEditDtls.img_alt" label="Img Alt" :error-messages="error.img_alt" @input="remove_error('img_alt')"></v-text-field>

    <div v-if=charityBtn>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Main Image</v-card-title>
                    <v-img :src="img_url + charityEditDtls.main_img_url" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="this.img_upload('main_img_url')">{{ mainImgUpload ?
                            'Close' : 'Edit main image' }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>

    <v-file-input accept="image/*" v-model="mainImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Main Image*" :error-messages="error.main_img_url" @change="this.handle_img_upload('main_img_url')" v-if="mainImgUpload"></v-file-input>

    <div v-if=charityBtn>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Mini Image</v-card-title>
                    <v-img :src="img_url + charityEditDtls.mini_img_url" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="this.img_upload('mini_img_url')">{{ miniImgUpload ?
                            'Close' : 'Edit mini image' }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>

    <v-file-input accept="image/*" v-model="miniImageValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Mini Image*" :error-messages="error.mini_img_url" @change="this.handle_img_upload('mini_img_url')" v-if="miniImgUpload"></v-file-input>

    <v-btn class="mr-4" @click="validate_charity()" color="blue" v-if="!charityBtn" :disabled="charityAdd">
        Add
    </v-btn>
    <v-btn class="mr-4" @click="validate_charity()" color="blue" :disabled="charityAdd" v-else>
        Save
    </v-btn>
    <logoutDia :showLogoutDialog="showLogoutDialog" />
    <div style="color: red;" v-if="error.api">{{ error.api }}</div>
</form>
</template>

<script>
import apiUrl from '@/api/allApis';
import validationMixin from "../../mixins/validation.js"
import imgUploadMixin from "../../mixins/imgUpload.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [validationMixin, imgUploadMixin, jwtMixin],
    data: () => ({
        showLogoutDialog: false,
        mainImgUpload: false,
        miniImgUpload: false,
        mainImageObj: {
            image: '',
            upload: false
        },
        miniImageObj: {
            image: '',
            upload: false
        },
        mainImageValue: '',
        miniImageValue: '',
        items: [
            'true',
            'false',

        ],
        error: {
            name: '',
            description: '',
            description2: '',
            image_url: '',
            active: '',
            url: '',
            display: '',
            img_alt: '',
            donation_amount_adjust: '',
            donation_amount_displayed: '',
            mini_img_url: '',
            main_img_url: '',
            summary: '',
            display_name: '',
            video_url_mob: '',
            video_url: '',
            cost_per_bottle: '',
            cost_per_kg_of_plastic: '',
            donation_percentage: '',
            api: ''
        },
        charityAdd: false

    }),
    computed: {
        isFormFilled() {
            return (
                (this.error.name === '' || this.error.name === undefined) &&
                (this.error.description === '' || this.error.description === undefined) &&
                (this.error.description2 === '' || this.error.description2 === undefined) &&
                (this.error.url === '' || this.error.url === undefined) &&
                (this.error.img_alt === '' || this.error.img_alt === undefined) &&
                (this.error.donation_amount_adjust === '' || this.error.donation_amount_adjust === undefined) &&
                (this.error.donation_amount_displayed === '' || this.error.donation_amount_displayed === undefined) &&
                (this.error.video_url_mob === '' || this.error.video_url_mob === undefined) &&
                (this.error.video_url === '' || this.error.video_url === undefined) &&
                (this.error.cost_per_bottle === '' || this.error.cost_per_bottle === undefined) &&
                (this.error.cost_per_kg_of_plastic === '' || this.error.cost_per_kg_of_plastic === undefined) &&
                (this.error.donation_percentage === '' || this.error.donation_percentage === undefined) &&
                (this.error.mini_img_url === '' || this.error.mini_img_url === undefined) &&
                (this.error.main_img_url === '' || this.error.main_img_url === undefined)
            );
        },
    },
    props: {
        charityEditDtls: Object,
        charityBtn: false
    },
    mounted() {
        if (!this.charityBtn) {
            this.miniImgUpload = true
            this.mainImgUpload = true
        }
        this.editedData = this.charityEditDtls
        this.charityEditDtls.active = this.charityEditDtls.active == 1 ? 'true' : 'false'
    },
    methods: {

        handle_img_upload(value) {
            switch (value) {
                case 'main_img_url':
                    this.mainImageObj = {
                        image: this.mainImageValue[0].name,
                        upload: true
                    }
                    this.error.main_img_url = '';
                    break;
                case 'mini_img_url':
                    this.miniImageObj = {
                        image: this.miniImageValue[0].name,
                        upload: true
                    }
                    this.error.mini_img_url = '';
                    break;
            }
        },
        img_upload(value) {
            switch (value) {
                case 'main_img_url':
                    this.mainImgUpload = !this.mainImgUpload;
                    this.error.main_img_url = '';
                    break;
                case 'mini_img_url':
                    this.miniImgUpload = !this.miniImgUpload;
                    this.error.mini_img_url = '';
                    break;
            }
        },
        remove_error(value) {
            switch (value) {
                case 'name':
                    let name = this.validate_name(this.charityEditDtls.name, "Name")
                    if (!name.valid) {
                        this.error.name = name.error;
                        break;
                    } else {
                        this.error.name = '';
                        break;
                    }
                case 'description':
                    this.error.description = ''
                    break;
                case 'description2':
                    this.error.description2 = ''
                    break;
                case 'image_url':
                    this.error.image_url = ''
                    break;
                case 'active':
                    this.error.active = ''
                    break;
                case 'url':
                    this.error.url = ''
                    break;
                case 'display':
                    this.error.display = ''
                    break;
                case 'img_alt':
                    this.error.img_alt = ''
                    break;
                case 'video_url_mob':
                    this.error.video_url_mob = ''
                    break;
                case 'video_url':
                    this.error.video_url = ''
                    break;
                case 'cost_per_bottle':
                    const cost_per_bottle = this.validateNumberField(this.charityEditDtls.cost_per_bottle, 'Total Plastic Bottles Removed')
                    if (!cost_per_bottle.valid) {
                        this.error.cost_per_bottle = cost_per_bottle.error
                        break;
                    } else {
                        this.error.cost_per_bottle = ''
                        break;
                    }
                case 'donation_percentage':
                    const donation_percentage = this.validateNumberField(this.charityEditDtls.donation_percentage, 'Total Plastic Removed')
                    if (!donation_percentage.valid) {
                        this.error.donation_percentage = donation_percentage.error
                        break;
                    } else {
                        this.error.donation_percentage = ''
                        break;
                    };
                case 'cost_per_kg_of_plastic':
                    const cost_per_kg_of_plastic = this.validateNumberField(this.charityEditDtls.cost_per_kg_of_plastic, 'Number Of Plastic Bottles')
                    if (!cost_per_kg_of_plastic.valid) {
                        this.error.cost_per_kg_of_plastic = cost_per_kg_of_plastic.error
                        break;
                    } else {
                        this.error.cost_per_kg_of_plastic = ''
                        break;
                    }
                case 'who_won_img_url':
                    this.error.who_won_img_url = ''
                    break;
                case 'donation_amount_adjust':
                    const donation_amount_adjust_num = this.validateNumberField(this.charityEditDtls.donation_amount_adjust, 'Donation Amount')
                    if (!donation_amount_adjust_num.valid) {
                        this.error.donation_amount_adjust = donation_amount_adjust_num.error
                        break;
                    } else {
                        this.error.donation_amount_adjust = ''
                        break;
                    }
                case 'donation_amount_displayed':
                    const donation_amount_displayed_num = this.validateNumberField(this.charityEditDtls.donation_amount_displayed, 'Donation Amount displayed')
                    if (!donation_amount_displayed_num.valid) {
                        this.error.donation_amount_displayed = donation_amount_displayed_num.error
                        break;
                    } else {
                        this.error.donation_amount_displayed = ''
                        break;
                    }
                case 'mini_img_url':
                    this.error.mini_img_url = ''
                    break;
                case '.main_img_url ':
                    this.error.main_img_url = ''
                    break;
                case 'charity_logo_img_url':
                    this.error.charity_logo_img_url = ''
                    break;
                case 'summary':
                    this.error.summary = ''
                    break;
                case 'display_name':
                    this.error.display_name = ''
                    break;
                default:
                    this.error = {
                        name: '',
                        description: '',
                        description2: '',
                        image_url: '',
                        active: '',
                        url: '',
                        display: '',
                        img_alt: '',
                        donation_amount_adjust: '',
                        donation_amount_displayed: '',
                        mini_img_url: '',
                        main_img_url: '',
                        summary: '',
                        display_name: '',
                        api: '',
                        video_url_mob: '',
                        video_url: '',
                        cost_per_bottle: '',
                        cost_per_kg_of_plastic: '',
                        donation_percentage: '',
                    }
            }
        },
        validate_charity() {
            const name = this.valid_inputs(this.charityEditDtls.name)
            const description = this.valid_inputs(this.charityEditDtls.description)
            const active = this.valid_inputs(this.charityEditDtls.active)
            const display = this.valid_inputs(this.charityEditDtls.display)
            const donation_amount_adjust = this.valid_inputs(this.charityEditDtls.donation_amount_adjust)
            const donation_amount_displayed = this.valid_inputs(this.charityEditDtls.donation_amount_displayed)

            const donation_percentage = this.valid_inputs(this.charityEditDtls.donation_percentage)
            const cost_per_kg_of_plastic = this.valid_inputs(this.charityEditDtls.cost_per_kg_of_plastic)
            const cost_per_bottle = this.valid_inputs(this.charityEditDtls.cost_per_bottle)
            const video_url = this.valid_inputs(this.charityEditDtls.video_url)
            const video_url_mob = this.valid_inputs(this.charityEditDtls.video_url_mob)

            let main_img_url;
            if (this.charityBtn) {
                if (this.mainImgUpload) {
                    main_img_url = this.valid_inputs(this.mainImageValue);
                } else {
                    main_img_url = {
                        valid: true,
                        error: ""
                    }
                }
            } else {
                main_img_url = this.valid_inputs(this.mainImageValue);
            }
            let mini_img_url;
            if (this.charityBtn) {
                if (this.miniImgUpload) {
                    mini_img_url = this.valid_inputs(this.miniImageValue);
                } else {
                    mini_img_url = {
                        valid: true,
                        error: ""
                    }
                }
            } else {
                mini_img_url = this.valid_inputs(this.miniImageValue);
            }

            if (!name.valid) {
                this.error.name = name.error
                document.getElementById('charityName').focus();
            } else if (!description.valid) {
                this.error.description = description.error
                document.getElementById('charityDesc').focus();
            } else if (!active.valid) {
                document.getElementById('active').focus();
                this.error.active = active.error
            } else if (!display.valid) {
                document.getElementById('display').focus();
                this.error.display = display.error
            } else if (!donation_amount_adjust.valid) {
                document.getElementById('donationAmountAdjust').focus();
                this.error.donation_amount_adjust = donation_amount_adjust.error
            } else if (!donation_amount_displayed.valid) {
                document.getElementById('donationAmountDisplayed').focus();
                this.error.donation_amount_displayed = donation_amount_displayed.error
            } else if (!donation_percentage.valid) {
                document.getElementById('donationPercentage').focus();
                this.error.donation_percentage = donation_percentage.error
            } else if (!cost_per_kg_of_plastic.valid) {
                document.getElementById('costPerKgOfPlastic').focus();
                this.error.cost_per_kg_of_plastic = cost_per_kg_of_plastic.error
            } else if (!cost_per_bottle.valid) {
                document.getElementById('costPerBottle').focus();
                this.error.cost_per_bottle = cost_per_bottle.error
            } else if (!video_url.valid) {
                document.getElementById('videoUrl').focus();
                this.error.video_url = video_url.error
            } else if (!video_url_mob.valid) {
                document.getElementById('videoUrlMob').focus();
                this.error.video_url_mob = video_url_mob.error
            } else if (!main_img_url.valid) {
                this.error.main_img_url = main_img_url.error
            } else if (!mini_img_url.valid) {
                this.error.mini_img_url = mini_img_url.error
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        name: '',
                        description: '',
                        description2: '',
                        image_url: '',
                        active: '',
                        url: '',
                        display: '',
                        img_alt: '',
                        donation_amount_adjust: '',
                        donation_amount_displayed: '',
                        mini_img_url: '',
                        main_img_url: '',
                        summary: '',
                        display_name: '',
                    }
                    if (!this.charityBtn) {
                        this.add_website_charity()
                    } else {
                        this.update_websiteCharity()
                    }
                }

            }
        },
        async add_website_charity() {
            try {
                this.charityAdd = true;
                const {
                    name,
                    description,
                    description2,
                    image_url,
                    active,
                    url,
                    display,
                    img_alt,
                    donation_amount_adjust,
                    donation_amount_displayed,
                    summary,
                    display_name,
                    video_url_mob,
                    video_url,
                    cost_per_bottle,
                    cost_per_kg_of_plastic,
                    donation_percentage
                } = this.charityEditDtls;

                const charityAddedData = {
                    name,
                    description,
                    description2,
                    image_url,
                    active: active === 'true' ? 1 : 0,
                    url,
                    display: JSON.parse(display),
                    img_alt,
                    donation_amount_adjust: Number(donation_amount_adjust),
                    donation_amount_displayed: Number(donation_amount_displayed),
                    mini_img_url: this.miniImageObj,
                    main_img_url: this.mainImageObj,
                    summary,
                    display_name,
                    video_url_mob,
                    video_url,
                    cost_per_bottle: Number(cost_per_bottle),
                    cost_per_kg_of_plastic: Number(cost_per_kg_of_plastic),
                    donation_percentage: Number(donation_percentage)
                };

                const response = await fetch(`${apiUrl.addWebsiteCharity}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(charityAddedData)
                });

                const data = await response.json();

                if (!data.status) {
                    await this.uploadImages(data.uploadUrl);

                    if (this.mainImageObj.upload && this.miniImageObj.upload) {
                        this.handleSuccess("Successfully added...");
                    } else {
                        this.handleUploadErrors(data.uploadUrl);
                    }
                } else {
                    this.handleError(data);
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                this.charityAdd = false;
            }
        },

        async update_websiteCharity() {
            try {
                this.charityAdd = true;

                const {
                    common_id,
                    createdAt,
                    updatedAt,
                    ...updatedCharityDtls
                } = this.charityEditDtls;

                updatedCharityDtls.active = updatedCharityDtls.active == 'true' ? 1 : 0;
                updatedCharityDtls.display = JSON.parse(updatedCharityDtls.display);
                updatedCharityDtls.donation_amount_displayed = Number(updatedCharityDtls.donation_amount_displayed);
                updatedCharityDtls.donation_amount_adjust = Number(updatedCharityDtls.donation_amount_adjust);
                updatedCharityDtls.cost_per_bottle = Number(updatedCharityDtls.cost_per_bottle),
                    updatedCharityDtls.cost_per_kg_of_plastic = Number(updatedCharityDtls.cost_per_kg_of_plastic),
                    updatedCharityDtls.donation_percentage = Number(updatedCharityDtls.donation_percentage)
                if (this.mainImageObj.upload) {
                    updatedCharityDtls.main_img_url = this.mainImageObj;
                }
                if (this.miniImageObj.upload) {
                    updatedCharityDtls.mini_img_url = this.miniImageObj;
                }

                const response = await fetch(`${apiUrl.updateWebsiteCharity}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(updatedCharityDtls)
                });

                const data = await response.json();

                if (!data.status) {
                    let mainSignedUrlObj;
                    let mainMessage;
                    let miniSignedUrlObj;
                    let miniMessage;

                    if (this.mainImageObj.upload) {
                        mainSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.mainImageValue[0].name);
                        mainMessage = await this.uploadToS3(mainSignedUrlObj.url, this.mainImageValue[0]);
                    }
                    if (this.miniImageObj.upload) {
                        miniSignedUrlObj = data.uploadUrl.find(urlObj => urlObj.file == this.miniImageValue[0].name);
                        miniMessage = await this.uploadToS3(miniSignedUrlObj.url, this.miniImageValue[0]);
                    }

                    if (this.mainImageObj.upload || this.miniImageObj.upload) {
                        if (mainMessage === "File uploaded successfully" || miniMessage === "File uploaded successfully") {
                            this.handleSuccess("Successfully saved...");
                        } else {
                            if (mainMessage !== "File uploaded successfully") {
                                this.error.main_image_url = mainMessage;
                            }
                            if (miniMessage !== "File uploaded successfully") {
                                this.error.mini_image_url = miniMessage;
                            }
                        }
                    } else {
                        this.handleSuccess("Successfully saved...");
                    }
                } else {
                    this.handleError(data);
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                this.charityAdd = false;
            }
        },

        async uploadImages(uploadUrl) {
            try {
                const mainSignedUrlObj = uploadUrl.find(urlObj => urlObj.file == this.mainImageValue[0].name);
                const miniSignedUrlObj = uploadUrl.find(urlObj => urlObj.file == this.miniImageValue[0].name);

                if (this.mainImageObj.upload) {
                    await this.uploadToS3(mainSignedUrlObj.url, this.mainImageValue[0]);
                }

                if (this.miniImageObj.upload) {
                    await this.uploadToS3(miniSignedUrlObj.url, this.miniImageValue[0]);
                }
            } catch (error) {
                throw error;
            }
        },

        handleSuccess(message) {
            this.successMessage = message;
            this.$emit("switchCharityTab", this.successMessage);
        },

        handleError(error) {
            this.error.api = error.message ? error.message : error;
            if (error.message === "Token Expired" || error.message === "Invalid Token") {
                this.showLogoutDialog = true;
            }
        },

        handleUploadErrors(uploadUrl) {
            const mainSignedUrlObj = uploadUrl.find(urlObj => urlObj.file == this.mainImageValue[0].name);
            const miniSignedUrlObj = uploadUrl.find(urlObj => urlObj.file == this.miniImageValue[0].name);

            if (mainSignedUrlObj && mainSignedUrlObj.message !== "File uploaded successfully") {
                this.error.main_image_url = mainSignedUrlObj.message;
            }

            if (miniSignedUrlObj && miniSignedUrlObj.message !== "File uploaded successfully") {
                this.error.mini_image_url = miniSignedUrlObj.message;
            }
        },

    },
})
</script>
