<template>
<div>
    <v-textarea auto-grow variant="filled" rows="1" label="Meta Title" ref="meta_title" v-model="categoryData.meta_title" @input="remove_error('meta_title')" :error-messages="error.meta_title"></v-textarea>
    <v-textarea auto-grow variant="filled" rows="1" label="Summary*" ref="summary" v-model="categoryData.summary" @input="remove_error('summary')" :error-messages="error.summary"></v-textarea>
    <v-textarea auto-grow variant="filled" rows="1" label="Meta Keywords" ref="meta_keywords" v-model="categoryData.meta_keywords" @input="remove_error('meta_keywords')" :error-messages="error.meta_keywords"></v-textarea>
    <v-text-field label="Name Plural*" ref="name_plural" v-model="categoryData.name_plural" @input="remove_error('name_plural')" :error-messages="error.name_plural"></v-text-field>
    <v-text-field label="Name*" ref="name" v-model="categoryData.name" @input="remove_error('name')" :error-messages="error.name"></v-text-field>
    <v-text-field label="Display Name*" ref="display_name" v-model="categoryData.display_name" @input="remove_error('display_name')" :error-messages="error.display_name"></v-text-field>
    <v-select label="Active*" :items="['true', 'false']" v-model="categoryData.active" @input="remove_error('active')" :error-messages="error.active"></v-select>
    <v-textarea auto-grow variant="filled" rows="1" label="Meta Description" ref="description" v-model="categoryData.description" @input="remove_error('description')" :error-messages="error.description"></v-textarea>

    <div v-if=categoryButton>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Image</v-card-title>
                    <v-img :src="img_url + categoryData.image_url" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="img_upload">{{ imgUpload ? 'Close'
                            : (categoryData.image_url ? 'Edit Image' : 'Add Image') }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>

    <v-file-input accept="image/*" v-model="imgValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Image" :error-messages="error.image_url" ref="image_url" @change="this.remove_error('image_url')" v-if="imgUpload"></v-file-input>
    <div>
        <v-btn class="me-4" color="blue" type="submit" v-if="!categoryButton" @click=validate_inputs() :disabled="btnDisable">
            Add
        </v-btn>
        <v-btn color="blue" class="me-4" type="submit" v-else @click=validate_inputs() :disabled="btnDisable">
            SAVE
        </v-btn>
    </div>
    <logoutDia :showLogoutDialog="showLogoutDialog" />
    <div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
</div>
</template>

<script>
import apiUrl from '@/api/allApis';
import vaildationMixin from "../../mixins/validation.js"
import imgUploadMixin from "../../mixins/imgUpload.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [vaildationMixin, imgUploadMixin, jwtMixin],
    data() {
        return {
            showLogoutDialog: false,
            img_updated: false,
            imgUpload: false,
            btnDisable: false,
            items: ["true", "false"],
            error: {
                meta_title: '',
                summary: '',
                meta_keywords: '',
                display: '',
                name_plural: '',
                name: '',
                display_name: '',
                active: '',
                image_url: '',
                description: '',
                api: ''
            },
            imgValue: '',
            signedUrl: '',
        }
    },
    props: {
        categoryData: Object,
        categoryButton: false
    },
    emits: ['switchcatTab'],

    updated() {
        if (this.categoryData.active == undefined || this.categoryData.active == null) {
            this.categoryData.active = "false"
        }
    },

    computed: {
        isFormFilled() {
            return (
                this.error.meta_title === '' &&
                this.error.summary === '' &&
                this.error.meta_keywords === '' &&
                this.error.name_plural === '' &&
                this.error.name === '' &&
                this.error.display_name === '' &&
                this.error.active === '' &&
                this.error.image_url === '' &&
                this.error.description === ''
            )
        }

    },
    mounted() {
        if (!this.categoryButton) {
            this.imgUpload = true
        }
    },
    methods: {
        remove_error(value) {
            switch (value) {
                case 'meta_title':
                    this.error.meta_title = ''
                    break;
                case 'summary':
                    this.error.summary = ''
                    break;
                case 'meta_keywords':
                    this.error.meta_keywords = ''
                    break;
                case 'name_plural':
                    this.error.name_plural = ''
                    break;
                case 'name':
                    let name = this.validate_name(this.categoryData.name, "Name")
                    if (!name.valid) {
                        this.error.name = name.error;
                        break;
                    } else {
                        this.error.name = '';
                        break;
                    }

                case 'display_name':
                    let display_name = this.validate_name(this.categoryData.display_name, "Display name")
                    if (!display_name.valid) {
                        this.error.display_name = display_name.error;
                        break;
                    } else {
                        this.error.display_name = '';
                        break;
                    }
                case 'active':
                    this.error.active = ''
                    break;
                case 'image_url':
                    this.error.image_url = ''
                    break;
                case 'description':
                    this.error.description = ''
                    break;
                default:
                    this.error = {
                        meta_title: '',
                        summary: '',
                        meta_keywords: '',
                        display: '',
                        name_plural: '',
                        name: '',
                        display_name: '',
                        active: '',
                        image_url: '',
                        description: '',
                        api: ''
                    }
            }
        },
        img_upload() {
            this.imgUpload = !this.imgUpload;
            this.error.image_url = '';
        },
        validate_inputs() {
            let summary = this.valid_inputs(this.categoryData.summary);
            let name_plural = this.valid_inputs(this.categoryData.name_plural);
            let name = this.valid_inputs(this.categoryData.name);
            let display_name = this.valid_inputs(this.categoryData.display_name);
            let active = this.valid_inputs(this.categoryData.active);
            let image_url;
            if (this.categoryButton) {
                if (this.imgUpload) {
                    image_url = this.valid_inputs(this.imgValue);
                } else {
                    image_url = {
                        valid: true,
                        error: ""
                    }
                }
            } else {
                image_url = {
                    valid: true,
                    error: ""
                }
            }
            if (!summary.valid) {
                this.error.summary = summary.error;
                this.$refs.summary.$el.querySelector('textarea').focus();
            }
            else if (!name_plural.valid) {
                this.error.name_plural = name_plural.error;
                this.$refs.name_plural.$el.querySelector('input').focus();
            } else if (!name.valid) {
                this.error.name = name.error;
                this.$refs.name.$el.querySelector('input').focus();
            } else if (!display_name.valid) {
                this.error.display_name = display_name.error;
                this.$refs.display_name.$el.querySelector('input').focus();
            } else if (!active.valid) {
                this.error.active = active.error;
            }
            else if (!image_url.valid) {
                this.error.image_url = image_url.error;
                this.$refs.image_url.$el.querySelector('input').focus();
            } else {

                if (this.isFormFilled) {
                    if (!this.categoryButton) {
                        this.category_add_item()
                    } else {
                        this.category_edit_item()
                    }
                }

            }
        },
        async category_add_item() {
            try {
                this.btnDisable = true;
                this.categoryData.active = JSON.parse(this.categoryData.active)
                if (this.imgValue.length > 0) {
                    this.categoryData.image_url = this.imgValue[0].name
                } else {
                    this.categoryData.image_url = ''
                }

                await fetch(apiUrl.addRaffoluxCategory, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.categoryData)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            if (this.imgValue.length > 0) {
                                this.signedUrl = data.uploadUrl;
                                let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                if (message === "File uploaded successfully") {
                                    this.successMessage = "Successfully added...";
                                    this.$emit("switchcatTab", this.successMessage);
                                } else {
                                    this.error.api = message;
                                }
                            } else {
                                this.successMessage = "Successfully added...";
                                this.$emit("switchcatTab", this.successMessage);
                            }

                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.btnDisable = false;
            }
        },
        destructuring_data(categoryData) {
            const {
                common_id,
                createdAt,
                updatedAt,
                ...categoryUpdateData
            } = categoryData;
            categoryUpdateData.active = JSON.parse(categoryUpdateData.active)

            return categoryUpdateData
        },
        async category_edit_item() {
            try {
                this.btnDisable = true
                this.img_updated = this.imgUpload ? true : false;
                if (this.imgUpload) {
                    this.categoryData.image_url = this.imgValue.length > 0 ? this.imgValue[0].name : this.categoryData.image_url
                }

                let categoryData = this.destructuring_data(this.categoryData)
                let body = {
                    img_updated: this.img_updated,
                    ...categoryData
                }
                await fetch(apiUrl.updateRaffoluxCategory, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(body)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            if (this.img_updated === true) {
                                if (this.imgValue.length > 0) {
                                    this.signedUrl = data.uploadURL;
                                    let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                    if (message === "File uploaded successfully") {
                                        this.successMessage = "Successfully saved...";
                                        this.$emit("switchcatTab", this.successMessage);
                                    } else {
                                        this.error.api = message;
                                    }
                                } else {
                                    this.successMessage = "Successfully saved...";
                                    this.$emit("switchcatTab", this.successMessage);
                                }

                            } else {
                                this.successMessage = "Successfully saved...";
                                this.$emit("switchcatTab", this.successMessage);
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
                this.error.errorMessage = "An error occurred while saving data.";
            } finally {
                this.btnDisable = false;
            }
        }
    }
})
</script>
