<template>
  <v-app>
    <v-main>
      <router-view/>
      
    </v-main>
  </v-app>
</template>

<script>
import '@/assets/css/global.css'
export default {
  name: 'App',
}
</script>
