export default {
    data(){
        return{
            jwt: '',
        }
    },
    methods: {
        jwt_token(){
            let jwt = sessionStorage.getItem('userToken')
            return jwt
        }
    }
}