import { mapGetters, mapActions } from 'vuex';
import apiUrl from '@/api/allApis.js';

export default {
    data() {
        return {
            img_url: 'https://mwc-storage-bucket.s3.ap-south-2.amazonaws.com/',
            raffleId:''
        }
    },
    computed: {
        ...mapGetters(['rafData', 'allConsolationDetail', 'rafWinData']),
        isConsolationPrize() {
            if (this.rafData && this.allConsolationDetail) {
                let consolation_prize = this.allConsolationDetail.find((item) => item.consolationID === this.rafData[0].consolation_prize_id);
                if (consolation_prize) {
                    return consolation_prize.value
                } else {
                    return 0
                }
            }
        },
        isRendering(){
            return ((this.rafData[0].no_winners - 1) != 0 && this.isConsolationPrize != 0);
          },
          sortId(){
            if (this.rafData && this.rafData.length > 0) {
                return this.rafData[0].sort_id.split('#')[1];
              }
          },
        isPostionFirst() {
            if (this.rafWinData) {
                let FirstWinner = this.rafWinData.filter((item) => item.position === 1);
                let detailOfFirstWin = {
                    UserName: FirstWinner[0].winner.user_name,
                    ticketNumber: FirstWinner[0].winner.ticket_number,
                }
                return detailOfFirstWin
            }
        },
        otherWinners() {
            if (this.rafWinData) {
                return this.rafWinData.filter((item) => item.position !== 1);
            }
        },
        isDataRenderd() {
            return (this.rafData.length > 0 && this.rafWinData.length > 0)
        }
    },
    mounted() {
        this.id = this.$route.params.id,
        this.raffleId=`Raffle#${this.id}`
        this.fetchWinData();
        this.fetchRaffelData();
    },
    methods: {
        ...mapActions(['fetch_raf_data', 'consolation_option_detail', 'fetch_rafWin_data']),
        async fetchRaffelData() {
            let apiEndpoint = `${apiUrl.fetchRaffle}?sort_id=${encodeURIComponent(this.raffleId)}`
            await this.$store.dispatch('fetch_raf_data', apiEndpoint);
            apiEndpoint = apiUrl.fetchPrize;
            await this.$store.dispatch('consolation_option_detail', apiEndpoint);
        },
        async fetchWinData(){
            const apiEndpoint = `${apiUrl.fetchRaffleWinner}?sort_id=${encodeURIComponent(this.raffleId)}`
            await this.$store.dispatch('fetch_rafWin_data', apiEndpoint);
        }
    }
}