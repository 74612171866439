
import jwtMixin from '@/mixins/jwt.js'
let state = {
  optionDetail: [
    {
      userId: "",
      emailId: "",
    },
  ],

  BlacklistReasonDetail: [
    {
      reasonID: "",
      reasonNames: "",
    },
  ],
  consolationDetail: [
    {
      prizeName:"",
      consolationID: "",
      consolationName: "",
      physicalPrize:""
    },
  ],
  websiteSellerDetail: [
    {
      sellerID: "",
      sellerNames: "",
    },
  ],
  websiteConditionDetail: [
    {
      conditionID: "",
      conditionNames: "",
    },
  ],
  websiteSponsorDetail: [
    {
      sponsorID: "",
      sponsorNames: "",
    },
  ],
  custonRaffleDetail: [
    {
      customRaffleID: "",
      custonRaffleNames: "",
    },
  ],
  deliveryDetail: [
    {
      deliveryID: "",
      deliveryOptions: "",
    },
  ],

  charityDetail: [
    {
      charityID: "",
      chrityNames: "",
    },
  ],

  raffleCodeDetail: [
    {
      raffleID: "",
      raffleName: "",
      raffleCodeID: "",
    },
  ],

  IdNameDetail: [
    {
      allId: "",
      allNames: "",
    },
  ],
  whitelabelWebsiteDetail: [
    {
      websiteId: "",
      websiteName: "",
    },
  ],
  paymentGatewayDetail: [
    {
      paymentGatewayId: "",
      paymentGatewayName: "",
    },
  ],
  paymentMethodDetail: [
    {
      paymentMethodId: "",
      paymentMethodName: "",
    },
  ],
  bankDetail: [
    {
      bankId: "",
      bankName: "",
    },
  ],
  questionDetails:[
    {
      questionId:'',
      question:'',
      answer:'',
      active:''
    }
  ]
};
let actions = {

  async consolation_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_consolation_options", data.SortedPrize);
        });
    } catch (error) {
      this.error = error;
    }
  },

  async question_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_question_detail", data.SortedQuestion);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_options", data.SortedUser);
        });
    } catch (error) {
      this.error = error;
    }
  },

  async reason_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_reason_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async seller_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_seller_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async condition_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_condition_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async sponsor_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_sponsor_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async charity_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_charity_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async delivery_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_delivery_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },

  async custom_raffle_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_custom_raffle_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async raffle_code_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_raffle_code_options", data.SortedRaffle);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async id_name_option_detail({ commit }, { currentURL, optionsKey } ) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_id_name_options", data[optionsKey]);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async whitelabel_website_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_whitelabel_website_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async payment_gateway_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_payment_gateway_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },

  async payment_method_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_payment_method_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
  async bank_option_detail({ commit }, currentURL) {
    try {
      await fetch(currentURL, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization':JSON.parse(jwtMixin.methods.jwt_token())
        },
      })
        .then((response) => response.json())
        .then((data) => {
          commit("set_dropdown_bank_options", data.status);
        });
    } catch (error) {
      this.error = error;
    }
  },
};
let mutations = {

  set_question_detail(state, result) {
    state.questionDetails = result.map((i) => ({
      questionId:i.sort_id,
      question:i.question,
      active:i.is_active,
    }));
  },
  set_dropdown_options(state, result) {
    state.optionDetail = result.map((i) => ({
      userId: i.sort_id,
      emailId: i.email,
    }));
  },
  set_dropdown_reason_options(state, result) {
    state.BlacklistReasonDetail = result.map((i) => ({
      reasonID: i.id,
      reasonNames: i.reason_name,
    }));
  },
  set_dropdown_consolation_options(state, result) {
    state.consolationDetail = result.map((i) => ({
      consolationID: i.sort_id,
      prizeName:i.name,
      consolationName: `${i.name}-${i.value}`,
      value:i.value,
      physicalPrize:i.physical_prize,
    }));
  },
  set_dropdown_seller_options(state, result) {
    state.websiteSellerDetail = result.map((i) => ({
      sellerID: i.id,
      sellerNames: i.seller_name,
    }));
  },

  set_dropdown_condition_options(state, result) {
    state.websiteConditionDetail = result.map((i) => ({
      conditionID: i.id,
      conditionNames: i.condition_name,
    }));
  },

  set_dropdown_sponsor_options(state, result) {
    state.websiteSponsorDetail = result.map((i) => ({
      sponsorID: i.id,
      sponsorNames: i.sponsor_name,
    }));
  },
  set_dropdown_delivery_options(state, result) {
    state.deliveryDetail = result.map((i) => ({
      deliveryID: i.id,
      deliveryOptions: i.delivery_option,
    }));
  },
  set_dropdown_charity_options(state, result) {
    state.charityDetail = result.map((i) => ({
      charityID: i.id,
      chrityNames: i.name,
    }));
  },
  set_dropdown_custom_raffle_options(state, result) {
    state.custonRaffleDetail = result.map((i) => ({
      customRaffleID: i.id,
      custonRaffleNames: i.title,
    }));
  },

  set_dropdown_raffle_code_options(state, result) { 
    state.raffleCodeDetail = result.map((i) => ({
      raffleID: i.sort_id,
      raffleCodeName: `RC[${i.raffle_code}]${i.title}`,
      raffleCodeID: i.raffle_code,
    }));
   
  },

  set_dropdown_id_name_options(state, result) {
    state.IdNameDetail = result.map((i) => {
      const item = {
        allId: i.sort_id,
        allNames: i.name,
      };
      if (i.hasOwnProperty('active')) {
        item.active = i.active;
      }
      return item;
    });
  },
  set_dropdown_whitelabel_website_options(state, result) {
    state.whitelabelWebsiteDetail = result.map((i) => ({
      websiteId: i.id,
      websiteName: i.name_id,
    }));
  },


  set_dropdown_payment_gateway_options(state, result) {
    state.paymentGatewayDetail = result.map((i) => ({
      paymentGatewayId: i.id,
      paymentMethodId: i.paymentmethod_id,
      paymentGatewayName: `${i.payment_method_name}-${i.environment}`,
      environment: i.environment,
    }));
  },

  set_dropdown_payment_method_options(state, result) {
    state.paymentMethodDetail = result.map((i) => ({
      paymentMethodId: i.id,
      paymentMethodName: i.payment_method_name,
    }));
  },
  set_dropdown_bank_options(state, result) {
    state.bankDetail = result.map((i) => ({
      bankId: i.id,
      bankName: i.bank_name,
    }));
  },
};
let getters = {
  allQuestionOptionDetail: (state) => state.questionDetails,
  allOptionDatail: (state) => state.optionDetail,
  allReasonOptionDatail: (state) => state.BlacklistReasonDetail,
  allConsolationDetail: (state) => state.consolationDetail,
  allIdNameDetail: (state) => state.IdNameDetail,
  allCharityDatail: (state) => state.charityDetail,
  allSellerOptionDetail: (state) => state.websiteSellerDetail,
  allConditionOptionDetail: (state) => state.websiteConditionDetail,
  allSponsorOptionDetail: (state) => state.websiteSponsorDetail,
  allCustomRaffleOptionDetail: (state) => state.custonRaffleDetail,
  allDeliveryOptionDetail: (state) => state.deliveryDetail,
  allReffleCodeOptionDetail: (state) => state.raffleCodeDetail,
  allWhitelabelWebsiteOptionDetail: (state) => state.whitelabelWebsiteDetail,
  allPaymentGatewayOptionDetail: (state) => state.paymentGatewayDetail,
  allPaymentMethodOptionDetail: (state) => state.paymentMethodDetail,
  allBankOptionDetail: (state) => state.bankDetail,
};
export default {
  state,
  actions,
  mutations,
  getters,
};
