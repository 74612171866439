import { createStore } from 'vuex'
import dropdownModule from './modules/dropdown';
import selectedOptionModule from './modules/selectedOption';
import webSiteStatus from './modules/webSiteStatus';
export default createStore({
  modules: {
    dropdownModule,
    selectedOptionModule,
    webSiteStatus
  }
})
