const apiUrl = {
  fetchGroupTableData: process.env.VUE_APP_API_URL + "/v1/fetchAccountsGroup",
  addGroupTableData: process.env.VUE_APP_API_URL + "/v1/addAccountsGroup",
  updateGroupTableData: process.env.VUE_APP_API_URL + "/v1/updateAccountsGroup",
  deleteGroupTableData: process.env.VUE_APP_API_URL + "/v1/deleteAccountsGroup",
  fetchVoucherTableData:
    process.env.VUE_APP_API_URL + "/v1/fetchVoucherUsedvouchers",
  addVoucherTableData:
    process.env.VUE_APP_API_URL + "/v1/addVoucherUsedvouchers",
  updateVoucherTableData:
    process.env.VUE_APP_API_URL + "/v1/updateVoucherUsedvouchers",
  deleteVoucherTableData:
    process.env.VUE_APP_API_URL + "/v1/deleteVoucherUsedvouchers",
  fetchWebsiteDelivery:
    process.env.VUE_APP_API_URL + "/v1/fetchWebsiteDelivery",
  addWebsiteDelivery: process.env.VUE_APP_API_URL + "/v1/addWebsiteDelivery",
  updateWebsiteDelivery:
    process.env.VUE_APP_API_URL + "/v1/updateWebsiteDelivery",
  deleteWebsiteDelivery:
    process.env.VUE_APP_API_URL + "/v1/deleteWebsiteDelivery",
  fetchCarouselTableData:
    process.env.VUE_APP_API_URL + "/v1/fetchAutomationHomePageCarousel",
  addCarouselTableData:
    process.env.VUE_APP_API_URL + "/v1/addAutomationHomePageCarousel",
  updateCarouselTableData:
    process.env.VUE_APP_API_URL + "/v1/updateAutomationHomePageCarousel",
  deleteCarouselTableData:
    process.env.VUE_APP_API_URL + "/v1/deleteAutomationHomePageCarousel",
  fetchaccUserTableData: process.env.VUE_APP_API_URL + "/admin/user/read",
  addaccUserTableData: process.env.VUE_APP_API_URL + "/admin/user/add",
  updateaccUserTableData: process.env.VUE_APP_API_URL + "/admin/user/update",
  deleteaccUserTableData: process.env.VUE_APP_API_URL + "/admin/user/delete",
  fetchFaqTableData: process.env.VUE_APP_API_URL + "/v1/fetchFaqContent",
  addFaqTableData: process.env.VUE_APP_API_URL + "/v1/addFaqContent",
  updateFaqTableData: process.env.VUE_APP_API_URL + "/v1/updateFaqContent",
  deleteFaqTableData: process.env.VUE_APP_API_URL + "/v1/deleteFaqContent",
  publishFaqBatchTableData:
    process.env.VUE_APP_API_URL + "/v1/changePublishedToActive",
  fetchPrivacyTableData: process.env.VUE_APP_API_URL + "/v1/fetchPrivacy",
  addPrivacyTableData: process.env.VUE_APP_API_URL + "/v1/addPrivacy",
  updatePrivacyTableData: process.env.VUE_APP_API_URL + "/v1/updatePrivacy",
  deletePrivacyTableData: process.env.VUE_APP_API_URL + "/v1/deletePrivacy",
  publishPrivacyBatchTableData:
    process.env.VUE_APP_API_URL + "/v1/UpdatePrivacyPublish",
  publishTermsBatchTableData:
    process.env.VUE_APP_API_URL + "/v1/UpdateTermsPublish",
  publishPlayBatchTableData:
    process.env.VUE_APP_API_URL + "/v1/updatePlayContentPublish",
  updateCartPaymentStatus:
    process.env.VUE_APP_API_URL + "/v1/updateCartPaymentStatus",
  fetchNewsTableData: process.env.VUE_APP_API_URL + "/admin/news/read",
  addNewsTableData: process.env.VUE_APP_API_URL + "/admin/news/add",
  updateNewsTableData: process.env.VUE_APP_API_URL + "/admin/news/update",
  deleteNewsTableData: process.env.VUE_APP_API_URL + "/admin/news/delete",
  fetchCountry: process.env.VUE_APP_API_URL + "/v1/fetchRestrictionsCountries",
  updateCountry:
    process.env.VUE_APP_API_URL + "/v1/updateRestrictionsCountries",
  addCountry: process.env.VUE_APP_API_URL + "/v1/addRestrictionsCountries",
  deleteCountry:
    process.env.VUE_APP_API_URL + "/v1/deleteRestrictionsCountries",
  fetchTerms: process.env.VUE_APP_API_URL + "/v1/fetchTerms",
  updateTerms: process.env.VUE_APP_API_URL + "/v1/updateTerms",
  addTerms: process.env.VUE_APP_API_URL + "/v1/addTerms",
  deleteTerms: process.env.VUE_APP_API_URL + "/v1/deleteTerms",
  fetchThumbnail: process.env.VUE_APP_API_URL + "/v1/fetchThumbnail",
  addThumbnail: process.env.VUE_APP_API_URL + "/v1/addThumbnail",
  updateThumbnail: process.env.VUE_APP_API_URL + "/v1/updateThumbnail",
  deleteThumbnail: process.env.VUE_APP_API_URL + "/v1/deleteThumbnail",
  fetchPlayContent: process.env.VUE_APP_API_URL + "/v1/fetchPlayContent",
  updatePlayContent: process.env.VUE_APP_API_URL + "/v1/updatePlayContent",
  addPlayContent: process.env.VUE_APP_API_URL + "/v1/addPlayContent",
  deletePlayContent: process.env.VUE_APP_API_URL + "/v1/deletePlayContent",
  fetchOtherSettingContent:
    process.env.VUE_APP_API_URL + "/admin/setting/read",
  updateOtherSettingContent:
    process.env.VUE_APP_API_URL + "/admin/setting/update",
  addOtherSettingContent: process.env.VUE_APP_API_URL + "/admin/setting/add",
  deleteOtherSettingContent:
    process.env.VUE_APP_API_URL + "/admin/setting/delete",

  fetchWebsiteCharity: process.env.VUE_APP_API_URL + "/admin/charity/read",
  updateWebsiteCharity: process.env.VUE_APP_API_URL + "/admin/charity/update",
  addWebsiteCharity: process.env.VUE_APP_API_URL + "/admin/charity/add",
  deleteWebsiteCharity: process.env.VUE_APP_API_URL + "/admin/charity/delete",

  fetchRaffleTableData: process.env.VUE_APP_API_URL + "/admin/raffle/read",
  fetchRaffleStatusTableData:
    process.env.VUE_APP_API_URL + "/v1/fetchRaffleStatus",
  updateRaffleStatus: process.env.VUE_APP_API_URL + "/v1/updateRaffleStatus",
  deleteRaffle: process.env.VUE_APP_API_URL + "/admin/raffle/delete",
  publishUnpublishRaffle:
    process.env.VUE_APP_API_URL + "/admin/raffle/publishUnpublish",
  updateDrawWinnerFeature:
    process.env.VUE_APP_API_URL + "/v1/updateDrawWinnerFeature",
  cloneRaffle: process.env.VUE_APP_API_URL + "/admin/raffle/clone",
  addRaffle: process.env.VUE_APP_API_URL + "/admin/raffle/add",
  fetchRaffle: process.env.VUE_APP_API_URL + "/admin/raffle/fetch",
  updateRaffle: process.env.VUE_APP_API_URL + "/admin/raffle/update",
  addWinnerDraw: process.env.VUE_APP_API_URL + "/admin/winner/select",

  fetchRestrictionReason:
    process.env.VUE_APP_API_URL + "/v1/fetchRestrictionsReason",
  updateRestrictionReason:
    process.env.VUE_APP_API_URL + "/v1/updateRestrictionsReason",
  addRestrictionReason:
    process.env.VUE_APP_API_URL + "/v1/addRestrictionsReason",
  deleteRestrictionReason:
    process.env.VUE_APP_API_URL + "/v1/deleteRestrictionsReason",
  fetchRaffoluxCategory:
    process.env.VUE_APP_API_URL + "/admin/category/read",
  updateRaffoluxCategory:
    process.env.VUE_APP_API_URL + "/admin/category/update",
  addRaffoluxCategory: process.env.VUE_APP_API_URL + "/admin/category/add",
  deleteRaffoluxCategory:
    process.env.VUE_APP_API_URL + "/admin/category/delete",
  fetchDraw: process.env.VUE_APP_API_URL + "/admin/draw/read",
  addDraw: process.env.VUE_APP_API_URL + "/admin/draw/add",
  updateDraw: process.env.VUE_APP_API_URL + "/admin/draw/update",
  deleteDraw: process.env.VUE_APP_API_URL + "/admin/draw/delete",
  fetchShopsContent: process.env.VUE_APP_API_URL + "/v1/fetchRaffoluxShop",
  updateShopsContent: process.env.VUE_APP_API_URL + "/v1/updateRaffoluxShop",
  addShopsContent: process.env.VUE_APP_API_URL + "/v1/addRaffoluxShop",
  deleteShopsContent: process.env.VUE_APP_API_URL + "/v1/deleteRaffoluxShop",
  fetchWalletPointsTransaction:
    process.env.VUE_APP_API_URL + "/admin/transaction/read",
  addWalletPointsTransaction:
    process.env.VUE_APP_API_URL + "/admin/transaction/add",
  updateWalletPointsTransaction:
    process.env.VUE_APP_API_URL + "/admin/transaction/update",
  deleteWalletPointsTransaction:
    process.env.VUE_APP_API_URL + "/admin/transaction/delete",
  fetchCreditsTransaction:
    process.env.VUE_APP_API_URL + "/v1/fetchCreditsTransaction",
  addCreditsTransaction:
    process.env.VUE_APP_API_URL + "/v1/addCreditsTransaction",
  updateCreditsTransaction:
    process.env.VUE_APP_API_URL + "/v1/updateCreditsTransaction",
  deleteCreditsTransaction:
    process.env.VUE_APP_API_URL + "/v1/deleteCreditsTransaction",
  fetchReferralPage: process.env.VUE_APP_API_URL + "/v1/fetchRaffoluxReferral",
  updateReferralPage:
    process.env.VUE_APP_API_URL + "/v1/updateRaffoluxReferral",
  addReferralPage: process.env.VUE_APP_API_URL + "/v1/addRaffoluxReferral",
  deleteReferralPage:
    process.env.VUE_APP_API_URL + "/v1/deleteRaffoluxReferral",
  fetchWhiteLabelActive:
    process.env.VUE_APP_API_URL + "/v1/fetchWhiteLabelActiveRaffle",
  deleteWhiteLabelActiveRaffle:
    process.env.VUE_APP_API_URL + "/v1/deleteWhiteLabelActiveRaffle",
  addWhiteLabelActiveRaffle:
    process.env.VUE_APP_API_URL + "/v1/addWhiteLabelActiveRaffle",
  updateWhiteLabelActiveRaffle:
    process.env.VUE_APP_API_URL + "/v1/updateWhiteLabelActiveRaffle",
  fetchVoucherContent: process.env.VUE_APP_API_URL + "/v1/fetchVoucher",
  addVoucherContent: process.env.VUE_APP_API_URL + "/v1/addVoucher",
  updateVoucherContent: process.env.VUE_APP_API_URL + "/v1/updateVoucher",
  deleteVoucherContent: process.env.VUE_APP_API_URL + "/v1/deleteVoucher",
  fetchWhitelabelCustomRaffle:
    process.env.VUE_APP_API_URL + "/v1/fetchWhitelabelCustomRaffle",
  deleteWhitelabelCustomRaffle:
    process.env.VUE_APP_API_URL + "/v1/deleteWhitelabelCustomRaffle",
  addWhitelabelCustomRaffle:
    process.env.VUE_APP_API_URL + "/v1/addWhitelabelCustomRaffle",
  updateWhitelabelCustomRaffle:
    process.env.VUE_APP_API_URL + "/v1/updateWhitelabelCustomRaffle",

  fetchRestrictionsClientBlacklist:
    process.env.VUE_APP_API_URL + "/admin/clientBlacklist/read",
  deleteRestrictionsClientBlacklist:
    process.env.VUE_APP_API_URL + "/admin/clientBlacklist/delete",
  addRestrictionsClientBlacklist:
    process.env.VUE_APP_API_URL + "/admin/clientBlacklist/add",
  updateRestrictionsClientBlacklist:
    process.env.VUE_APP_API_URL + "/admin/clientBlacklist/update",

  fetchCartPaymentMethod:
    process.env.VUE_APP_API_URL + "/admin/payment/read",
  deleteCartPaymentMethod:
    process.env.VUE_APP_API_URL + "/admin/payment/delete",
  addCartPaymentMethod:
    process.env.VUE_APP_API_URL + "/admin/payment/add",
  updateCartPaymentMethod:
    process.env.VUE_APP_API_URL + "/admin/payment/update",
  fetchInstantWinnerContent: process.env.VUE_APP_API_URL + "/admin/winner/read",
  addInstantWinnerContent: process.env.VUE_APP_API_URL + "/admin/winner/add",
  updateInstantWinnerContent:
    process.env.VUE_APP_API_URL + "/admin/winner/update",
  deleteInstantWinnerContent:
    process.env.VUE_APP_API_URL + "/admin/winner/delete",
  fetchAutomationAutoRafflePool:
    process.env.VUE_APP_API_URL + "/v1/fetchAutomationAutoRafflePool",
  deleteAutomationAutoRafflePool:
    process.env.VUE_APP_API_URL + "/v1/deleteAutomationAutoRafflePool",
  updateAutomationAutoRafflePool:
    process.env.VUE_APP_API_URL + "/v1/updateAutomationAutoRafflePool",
  addAutomationAutoRafflePool:
    process.env.VUE_APP_API_URL + "/v1/addAutomationAutoRafflePool",
  fetchdAutoRaffQueueContent:
    process.env.VUE_APP_API_URL + "/v1/fetchAutomationAutoRaffleQueue",
  addAutoRaffQueueContent:
    process.env.VUE_APP_API_URL + "/v1/addAutomationAutoRaffleQueue",
  updateAutoRaffQueueContent:
    process.env.VUE_APP_API_URL + "/v1/updateAutomationAutoRaffleQueue",
  deleteAutoRaffQueueContent:
    process.env.VUE_APP_API_URL + "/v1/deleteAutomationAutoRaffleQueue",
  fetchTicketEntryContent: process.env.VUE_APP_API_URL + "/v1/fetchTicketEntry",
  addTicketEntryContent: process.env.VUE_APP_API_URL + "/v1/addTicketEntry",
  updateTicketEntryContent:
    process.env.VUE_APP_API_URL + "/v1/updateTicketEntry",
  deleteTicketEntryContent:
    process.env.VUE_APP_API_URL + "/v1/deleteTicketEntry",
  fetchWebsiteSponsor: process.env.VUE_APP_API_URL + "/v1/fetchWebsiteSponsor",
  deleteWebsiteSponsor:
    process.env.VUE_APP_API_URL + "/v1/deleteWebsiteSponsor",
  updateWebsiteSponsor:
    process.env.VUE_APP_API_URL + "/v1/updateWebsiteSponsor",
  addWebsiteSponsor: process.env.VUE_APP_API_URL + "/v1/addWebsiteSponsor",
  fetchWebsiteCondition:
    process.env.VUE_APP_API_URL + "/v1/fetchWebsiteCondition",
  deleteWebsiteCondition:
    process.env.VUE_APP_API_URL + "/v1/deleteWebsiteCondition",
  updateWebsiteCondition:
    process.env.VUE_APP_API_URL + "/v1/updateWebsiteCondition",
  addWebsiteCondition: process.env.VUE_APP_API_URL + "/v1/addWebsiteCondition",
  fetchWebsiteSeller: process.env.VUE_APP_API_URL + "/v1/fetchWebsiteSeller",
  addWebsiteSeller: process.env.VUE_APP_API_URL + "/v1/addWebsiteSeller",
  deleteWebsiteSeller: process.env.VUE_APP_API_URL + "/v1/deleteWebsiteSeller",
  updateWebsiteSeller: process.env.VUE_APP_API_URL + "/v1/updateWebsiteSeller",

  addUserCart: process.env.VUE_APP_API_URL + "/admin/cart/add",
  fetchUserCart: process.env.VUE_APP_API_URL + "/admin/cart/read",
  updateUserCart: process.env.VUE_APP_API_URL + "/admin/cart/update",
  deleteUserCart: process.env.VUE_APP_API_URL + "/admin/cart/delete",

  fetchActiveTickets: process.env.VUE_APP_API_URL + "/v1/fetchActiveTickets",
  fetchWalletCredits: process.env.VUE_APP_API_URL + "/v1/fetchWalletCredits",
  updateWalletCredits: process.env.VUE_APP_API_URL + "/v1/updateWalletCredits",
  addWalletCredits: process.env.VUE_APP_API_URL + "/v1/addWalletCredits",
  deleteWalletCredits: process.env.VUE_APP_API_URL + "/v1/deleteWalletCredits",
  fetchWalletPoints: process.env.VUE_APP_API_URL + "/v1/fetchWalletPoints",
  addWalletPoints: process.env.VUE_APP_API_URL + "/v1/addWalletPoints",
  updateWalletPoints: process.env.VUE_APP_API_URL + "/v1/updateWalletPoints",
  deleteWalletPoints: process.env.VUE_APP_API_URL + "/v1/deleteWalletPoints",
  addTicketsToRaffle: process.env.VUE_APP_API_URL + "/v1/addTicketsToRaffle",
  closeUserAccount: process.env.VUE_APP_API_URL + "/admin/user/blacklist",
  referred: process.env.VUE_APP_API_URL + "/admin/user/fetchByReferrer",
  addCreditToWallet: process.env.VUE_APP_API_URL + "/admin/user/addCreditWallet",
  fetchWhiteLabelWebsite:
    process.env.VUE_APP_API_URL + "/v1/fetchWhiteLabelWebsite",
  deleteWhiteLabelWebsite:
    process.env.VUE_APP_API_URL + "/v1/deleteWhiteLabelWebsite",
  updateWhiteLabelWebsite:
    process.env.VUE_APP_API_URL + "/v1/updateWhiteLabelWebsite",
  addWhiteLabelWebsite:
    process.env.VUE_APP_API_URL + "/v1/addWhiteLabelWebsite",
  fetchWhitelabelOrganisation:
    process.env.VUE_APP_API_URL + "/v1/fetchWhitelabelOrganisation",
  updateWhitelabelOrganisation:
    process.env.VUE_APP_API_URL + "/v1/updateWhitelabelOrganisation",
  addWhitelabelOrganisation:
    process.env.VUE_APP_API_URL + "/v1/addWhitelabelOrganisation",
  deleteWhitelabelOrganisation:
    process.env.VUE_APP_API_URL + "/v1/deleteWhitelabelOrganisation",

  fetchWhiteLabelPaymentGateway:
    process.env.VUE_APP_API_URL + "/v1/fetchWhiteLabelPaymentGateway",
  updateWhiteLabelPaymentGateway:
    process.env.VUE_APP_API_URL + "/v1/updateWhiteLabelPaymentGateway",
  deleteWhiteLabelPaymentGateway:
    process.env.VUE_APP_API_URL + "/v1/deleteWhiteLabelPaymentGateway",
  addWhiteLabelPaymentGateway:
    process.env.VUE_APP_API_URL + "/v1/addWhiteLabelPaymentGateway",

  fetchPromotionToken: process.env.VUE_APP_API_URL + "/v1/fetchPromotionToken",
  addPromotionToken: process.env.VUE_APP_API_URL + "/v1/addPromotionToken",
  updatePromotionToken:
    process.env.VUE_APP_API_URL + "/v1/updatePromotionToken",
  deletePromotionToken:
    process.env.VUE_APP_API_URL + "/v1/deletePromotionToken",

  fetchWebsitePaymentMethod:
    process.env.VUE_APP_API_URL + "/v1/fetchWebsitePaymentMethod",
  addWebsitePaymentMethod:
    process.env.VUE_APP_API_URL + "/v1/addWebsitePaymentMethod",
  deleteWebsitePaymentMethod:
    process.env.VUE_APP_API_URL + "/v1/deleteWebsitePaymentMethod",
  updateWebsitePaymentMethod:
    process.env.VUE_APP_API_URL + "/v1/updateWebsitePaymentMethod",

  importRaffoluxUser: process.env.VUE_APP_API_URL + "/v1/importRaffoluxUser",
  importCartPayment: process.env.VUE_APP_API_URL + "/v1/importCartPayment",
  importRaffles: process.env.VUE_APP_API_URL + "/v1/importRaffles",
  importVoucher: process.env.VUE_APP_API_URL + "/v1/importVoucher",

  fetchWhitelabelBank: process.env.VUE_APP_API_URL + "/v1/fetchWhitelabelBank",
  addWhitelabelBank: process.env.VUE_APP_API_URL + "/v1/addWhitelabelBank",
  updateWhitelabelBank:
    process.env.VUE_APP_API_URL + "/v1/updateWhitelabelBank",
  deleteWhitelabelBank:
    process.env.VUE_APP_API_URL + "/v1/deleteWhitelabelBank",

  signIn: process.env.VUE_APP_API_URL + "/admin/auth/signIn",
  changePasswordWithLogin:
    process.env.VUE_APP_API_URL + "/admin/auth/changePassword",

  fetchQuestion: process.env.VUE_APP_API_URL + "/admin/question/read",
  addQuestion: process.env.VUE_APP_API_URL + "/admin/question/add",
  updateQuestion: process.env.VUE_APP_API_URL + "/admin/question/update",
  deleteQuestion: process.env.VUE_APP_API_URL + "/admin/question/delete",


  fetchRaffleWinner: process.env.VUE_APP_API_URL + "/admin/winner/fetchWithRaffleId",
  fetchPrize: process.env.VUE_APP_API_URL + "/admin/prize/read",
  addPrize: process.env.VUE_APP_API_URL + "/admin/prize/add",
  updatePrize: process.env.VUE_APP_API_URL + "/admin/prize/update",
  deletePrize: process.env.VUE_APP_API_URL + "/admin/prize/delete",
  fetchWallet: process.env.VUE_APP_API_URL + "/admin/wallet/read",
  addWallet: process.env.VUE_APP_API_URL + "/admin/wallet/add",
  updateWallet: process.env.VUE_APP_API_URL + "/admin/wallet/update",
  deleteWallet: process.env.VUE_APP_API_URL + "/admin/wallet/delete",

  fetchRaffleTickets: process.env.VUE_APP_API_URL + "/admin/raffleDetails/read",
  filterRaffleTickets: process.env.VUE_APP_API_URL + "/admin/raffleDetails/search",
  filterPayments: process.env.VUE_APP_API_URL + "/admin/payment/search",

};

export default apiUrl;
