<template>
<v-text-field label="Total Amount*" variant="filled" v-model="editItem.total_amount" :error-messages="error.total_amount" ref="total_amountField" @input="remove_error('total_amount')"></v-text-field>
<v-text-field label="Cart ID*" variant="filled" v-model="editItem.cart_id" :error-messages="error.cart_id" @input="remove_error('cart_id')" ref="cartIdField"></v-text-field>
<v-autocomplete label="User Email*" variant="filled" v-model="emailAddress" :items="allOptionDatail.map(item => item.emailId)" :menu-props="{ minWidth: '300px' }" @update:model-value="handleUserChange" :error-messages="error.user_id" ref="userIdField">
</v-autocomplete>
<v-text-field label="Notes*" variant="filled" v-model="editItem.notes" :error-messages="error.notes" @input="remove_error('notes')" ref="notesField"></v-text-field>
<v-text-field label="Credit Transaction ID" variant="filled" v-model="editItem.credit_transaction_id" :error-messages="error.credit_transaction_id" @input="remove_error('credit_transaction_id')" ref="creditTransactionField"></v-text-field>
<v-text-field label="Transaction ID" variant="filled" v-model="editItem.transaction_id" :error-messages="error.transaction_id" @input="remove_error('transaction_id')" ref="transactionIdField"></v-text-field>
<v-text-field label="Transaction Reference ID*" variant="filled" v-model="editItem.transaction_reference_id" :error-messages="error.transaction_reference_id" @input="remove_error('transaction_reference_id')" ref="transactionRefIdField"></v-text-field>
<v-text-field label="Running Balance*" variant="filled" v-model="editItem.running_balance" :error-messages="error.running_balance" @input="remove_error('running_balance')" ref="runningBalanceRefIdField"></v-text-field>
<v-text-field label="Credit Price Used*" variant="filled" v-model="editItem.credit_price_used" :error-messages="error.credit_price_used" @input="remove_error('credit_price_used')" ref="creditPriceUsedRefIdField"></v-text-field>
<v-select class="mb-4" label="Status*" :items="statusItems" v-model="editItem.status" :error-messages="error.status" hint="[(1, 'Pending'), (2, 'Confirmed'), (3, 'Cancelled'), (4, 'Declined'), (5, 'Refunded')]" persistent-hint></v-select>
<v-select class="mb-4" label="Method*" :items="paymentItems" v-model="editItem.method" :error-messages="error.method" hint="[(0, 'Free'), (1, 'Cashflows'), (2, 'Vivawallet')]" persistent-hint></v-select>
<v-select class="mb-4" label="One Time Check" :items="items" v-model="editItem.one_time_check" :error-messages="error.one_time_check" @input="remove_error('one_time_check')" ref="oneTimeCheckField"></v-select>
<v-btn class="mb-4" @click="validate_inputs()" v-if="btnNameChange" :disabled="buttonDisabled" color="blue">ADD</v-btn>
<v-btn class="mb-4" @click="validate_inputs()" :disabled="buttonDisabled" v-else color="blue">SAVE</v-btn>
<div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
</template>

<script>
import apiUrl from '../../api/allApis.js'
import vaildationMixin from "../../mixins/validation.js"
import jwtMixin from '@/mixins/jwt.js'
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    markRaw
} from 'vue';
export default markRaw({
    mixins: [vaildationMixin,jwtMixin],
    name: "Add",
    props: {
        btnNameChange: Boolean,
        editItem: Object
    },
    emits: ['data-changed'],
    data() {
        return {
            statusItems: ['1', '2', '3', '4', '5'],
            paymentItems: [0, 1, 2],
            error: {
                total_amount: '',
                order_reference: '',
                order_job_reference: '',
                status: '',
                method: '',
                cart_id: '',
                user_id: '',
                notes: '',
                credit_transaction_id: '',
                one_time_check: '',
                transaction_id: '',
                transaction_reference_id: '',
                api: '',
                running_balance: '',
                credit_price_used: '',
            },
            buttonDisabled: false,
            items: ["true", "false"],
            OptionObject: {
                userId: '',
                emailId: ''
            },
            emailAddress: null,
        }
    },
    updated() {
        if (this.editItem.one_time_check == undefined || this.editItem.one_time_check == null) {
            this.editItem.one_time_check = "false"
        }
    },
    computed: {
        ...mapGetters(['allOptionDatail']),
        isFormFilled() {
            return (
                this.error.total_amount === '' &&
                this.error.order_reference === '' &&
                this.error.order_job_reference === '' &&
                this.error.status === '' &&
                this.error.method === '' &&
                this.error.cart_id === '' &&
                this.error.user_id === '' &&
                this.error.notes === '' &&
                this.error.credit_transaction_id === '' &&
                this.error.one_time_check === '' &&
                this.error.transaction_id === '' &&
                this.error.running_balance === '' &&
                this.error.credit_price_used === '' &&
                this.error.transaction_reference_id === ''
            );
        },
    },
    methods: {
        ...mapActions(['option_detail']),
        async load_option_data() {
            const apiEndpoint = `${apiUrl.fetchaccUserTableData}?common_id=61692656`;
            await this.$store.dispatch('option_detail', apiEndpoint);
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
            if (selectedUser) {
                this.editItem.user_id = selectedUser.userId;
                this.emailAddress = selectedUser.emailId;
                this.remove_error("user_id");
            }
        },
        remove_error(value) {
            switch (value) {
                case 'total_amount':
                    let total_amount_numeric = this.validateNumberField(this.editItem.total_amount, 'Amount');
                    if (!total_amount_numeric.valid) {
                        this.error.total_amount = total_amount_numeric.error;
                        return;
                    } else {
                        this.error.total_amount = ''
                        break;
                    }
                case 'order_reference':
                    let order_reference_numeric = this.validateNumberField(this.editItem.order_reference, 'Order Refrence');
                    if (!order_reference_numeric.valid) {
                        this.error.order_reference = order_reference_numeric.error;
                        return;
                    } else {
                        this.error.order_reference = ''
                        break;
                    }
                case 'order_job_reference':
                    let order_job_reference_numeric = this.validateNumberField(this.editItem.order_job_reference, 'Order Job Refrence');
                    if (!order_job_reference_numeric.valid) {
                        this.error.order_job_reference = order_job_reference_numeric.error;
                        return;
                    } else {
                        this.error.order_job_reference = ''
                        break;
                    }
                case 'running_balance':
                    let running_balance = this.validateNumberField(this.editItem.running_balance, 'Running Balance');
                    if (!running_balance.valid) {
                        this.error.running_balance = running_balance.error;
                        return;
                    } else {
                        this.error.running_balance = ''
                        break;
                    }
                case 'credit_price_used':
                    let credit_price_used = this.validateNumberField(this.editItem.credit_price_used, 'Credit Price Used');
                    if (!credit_price_used.valid) {
                        this.error.credit_price_used = credit_price_used.error;
                        return;
                    } else {
                        this.error.credit_price_used = ''
                        break;
                    }
                case 'status':
                    let status_numeric = this.validateNumberField(this.editItem.status, 'Status');
                    if (!status_numeric.valid) {
                        this.error.status = status_numeric.error;
                        return;
                    } else {
                        this.error.status = ''
                        break;
                    }
                case 'method':
                    let method_numeric = this.validateNumberField(this.editItem.method, 'Payment Method');
                    if (!method_numeric.valid) {
                        this.error.method = method_numeric.error;
                        return;
                    } else {
                        this.error.method = ''
                        break;
                    }
                case 'cart_id':
                    this.error.cart_id = '';
                    break;
                case 'user_id':
                    this.error.user_id = '';
                    break;
                case 'notes':
                    this.error.notes = '';
                    break;
                case 'credit_transaction_id':
                    this.error.credit_transaction_id = '';
                    break;
                case 'one_time_check':
                    this.error.one_time_check = '';
                    break;
                case 'transaction_id':
                    this.error.transaction_id = '';
                    break;
                case 'transaction_reference_id':
                    this.error.transaction_reference_id = '';
                    break;
                default:
                    this.error = {
                        total_amount: '',
                        order_reference: '',
                        order_job_reference: '',
                        status: '',
                        method: '',
                        cart_id: '',
                        user_id: '',
                        notes: '',
                        credit_transaction_id: '',
                        one_time_check: '',
                        transaction_id: '',
                        transaction_reference_id: '',
                        credit_price_used: '',
                        api: '',
                    }
            }
        },
        validate_inputs() {
            let total_amount = this.valid_inputs(this.editItem.total_amount);
            let status = this.valid_inputs(this.editItem.status);
            let method = this.valid_inputs(this.editItem.method);
            let cart_id = this.valid_inputs(this.editItem.cart_id);
            const emailAddress = this.valid_inputs(this.emailAddress)
            let notes = this.valid_inputs(this.editItem.notes);
            let one_time_check = this.valid_inputs(this.editItem.one_time_check);
            let transaction_reference_id = this.valid_inputs(this.editItem.transaction_reference_id);
            let running_balance = this.valid_inputs(this.editItem.running_balance);
            let credit_price_used = this.valid_inputs(this.editItem.credit_price_used);

            if (!total_amount.valid) {
                this.error.total_amount = total_amount.error;
                this.$refs.total_amountField.$el.querySelector('input').focus();
            } else if (!status.valid) {
                this.error.status = status.error;
                this.$refs.statusField.$el.querySelector('input').focus();
            } else if (!method.valid) {
                this.error.method = method.error;
                this.$refs.paymentMethodField.$el.querySelector('input').focus();
            } else if (!cart_id.valid) {
                this.error.cart_id = cart_id.error;
                this.$refs.cartIdField.$el.querySelector('input').focus();
            } else if (!emailAddress.valid) {
                this.error.user_id = emailAddress.error;
                this.$refs.userIdField.$el.querySelector('input').focus();
            } else if (!notes.valid) {
                this.error.notes = notes.error;
                this.$refs.notesField.$el.querySelector('input').focus();
            } else if (!one_time_check.valid) {
                this.error.one_time_check = one_time_check.error;
                this.$refs.oneTimeCheckField.$el.querySelector('input').focus();
            }else if (!transaction_reference_id.valid) {
                this.error.transaction_reference_id = transaction_reference_id.error;
                this.$refs.transactionRefIdField.$el.querySelector('input').focus();
            } else if (!running_balance.valid) {
                this.error.running_balance = running_balance.error;
                this.$refs.runningBalanceRefIdField.$el.querySelector('input').focus();
            } else if (!credit_price_used.valid) {
                this.error.credit_price_used = credit_price_used.error;
                this.$refs.creditPriceUsedRefIdField.$el.querySelector('input').focus();
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        total_amount: '',
                        order_reference: '',
                        order_job_reference: '',
                        status: '',
                        method: '',
                        cart_id: '',
                        user_id: '',
                        notes: '',
                        credit_transaction_id: '',
                        one_time_check: '',
                        transaction_id: '',
                        transaction_reference_id: '',
                        credit_price_used: '',
                        api: '',
                    }
                    if (this.btnNameChange) {
                        this.add_cart_payment_data()
                    } else {
                        this.edit_cart_payment_data()
                    }
                }
            }
        },
        async edit_cart_payment_data() {
           
            try {
                this.buttonDisabled = true;
            this.editItem.one_time_check = JSON.parse(this.editItem.one_time_check);
                const body = {
                    id: this.editItem.id,
                    total_amount: Number(this.editItem.total_amount),
                    order_reference: Number(this.editItem.order_reference),
                    order_job_reference: Number(this.editItem.order_job_reference),
                    status: Number(this.editItem.status),
                    method: Number(this.editItem.method),
                    cart_id: (this.editItem.cart_id),
                    user_id: (this.editItem.user_id),
                    notes: (this.editItem.notes),
                    credit_transaction_id: (this.editItem.credit_transaction_id),
                    one_time_check: (this.editItem.one_time_check),
                    transaction_id: (this.editItem.transaction_id),
                    transaction_reference_id: (this.editItem.transaction_reference_id),
                    running_balance: (this.editItem.running_balance),
                    credit_price_used: (this.editItem.credit_price_used),
                };

                const jsonString = JSON.stringify(body);
                await fetch(apiUrl.updateCartPaymentMethod, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then((_data) => {
                        if (data.status) {
                            this.$emit('data-changed')
                        } else {
                            this.error.api = _data.message ? _data.message : _data;
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },
        async add_cart_payment_data() {
         
            try {
            this.editItem.one_time_check = JSON.parse(this.editItem.one_time_check);
            this.buttonDisabled = true;
                const body = {
                    total_amount: Number(this.editItem.total_amount),
                    order_reference: Number(this.editItem.order_reference),
                    order_job_reference: Number(this.editItem.order_job_reference),
                    status: Number(this.editItem.status),
                    method: Number(this.editItem.method),
                    cart_id: (this.editItem.cart_id),
                    user_id: (this.editItem.user_id),
                    notes: (this.editItem.notes),
                    credit_transaction_id: (this.editItem.credit_transaction_id),
                    one_time_check: (this.editItem.one_time_check),
                    transaction_id: (this.editItem.transaction_id),
                    transaction_reference_id: (this.editItem.transaction_reference_id),
                    running_balance: (this.editItem.running_balance),
                    credit_price_used: (this.editItem.credit_price_used),
                };

                const jsonString = JSON.stringify(body);
                await fetch(apiUrl.addCartPaymentMethod, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.status) {
                            this.$emit('data-changed')
                        } else {
                            this.error.api = data.message ? data.message : data;
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false;
            }
        },
    },

});
</script>
