
export default {
  methods: {
    valid_inputs(validData) {
      if (
        validData == undefined ||
        (typeof validData === "string" &&
          (validData.trim().length === 0 || validData.length === 0)) ||
        validData.length === 0
      ) {
        return { valid: false, error: "This field is Required" };
      }
      return { valid: true, error: "" };
    },
    validate_email(value) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value) {
        return { valid: false, error: "Email is required ." };
      } else if (!emailPattern.test(value)) {
        return { valid: false, error: "Please enter a valid Email address" };
      }
      return { valid: true, error: "" };
    },
    validate_name(value, fieldName) {
      const name = /^(?! )(?!.* $)(?!0$)[a-zA-Z0-9_ ]*$/;
      const allZeros = /^0+$/;
      if (!name.test(value) || allZeros.test(value)) {
        return {
          valid: false,
          error: `${fieldName} should contain only alphanumeric characters`,
        };
      }
      return { valid: true, error: "" };
    },
    validate_phone(phoneNumber) {
      if (!phoneNumber) {
        return { valid: false, error: `Contact number is required.` };
      } else {
        const phonePattern = /^(?!0)\d{9,10}$/;
        if (!phonePattern.test(phoneNumber)) {
          return { valid: false, error: "Please enter a valid phone number" };
        }
      }
      return { valid: true, error: "" };
    },
    validate_valueNum(value) {
      if (value.toString().length >= 1 && value.toString().length <= 16) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: "Value should be 1 to 16 digits" };
      }
    },
    validate_sortField(value, fieldname) {
      if (value.toString().length == 6) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: `${fieldname} should be 6 digits` };
      }
    },
    validate_accountNumber(value, fieldname) {
      if (value.toString().length == 8) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: `${fieldname} should be 8 digits` };
      }
    },
    validate_postCode(value) {
      let postPattern = /^[a-zA-Z]{2}\d{1,2}[a-zA-Z]{2}$/;
      if (postPattern.test(value)) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: "Invalid postcode" };
      }
    },
    validateNumberField(value, fieldname) {
      const numberValue = Number(value);
      if (isNaN(numberValue) ) {
        return {
          valid: false,
          error: `${fieldname} should be a valid number.`,
        };
      }
      else if (value.toString().length < 16) {
        return { valid: true, error: "" };
      } else {
        return {
          valid: false,
          error: `${fieldname} should be less than 16 digits`,
        };
      }
    },
    validate_pass(value, fieldname) {
      const numberValue = Number(value);
      if (!value) {
        return { valid: false, error: `${fieldname} is required .` };
      } else if (!isNaN(numberValue)) {
        return {
          valid: false,
          error: `${fieldname} can’t be entirely numeric.`,
        };
      } else if (value.length < 8) {
        return {
          valid: false,
          error: `${fieldname}  must contain at least 8 characters`,
        };
      } else {
        return {
          valid: true,
          error: "",
        };
      }
    },
    validate_otp(value, fieldname) {
      const numberValue = Number(value);
      if (!value) {
        return { valid: false, error: `${fieldname} is required .` };
      } else if (isNaN(numberValue)) {
        return { valid: false, error: `${fieldname} should be a Number.` };
      } else if (value.toString().length !== 6) {
        return { valid: false, error: `${fieldname} should be 6 digits` };
      } else {
        return { valid: true, error: "" };
      }
    },
    validateYear(value) {
      const numericValue = parseInt(value, 10);

      if (isNaN(numericValue)) {
        return { valid: false, error: "Year should be a Number" };
      }

      const yearPattern = /^\d{4}$/;

      if (
        !yearPattern.test(value) ||
        numericValue < 1000 ||
        numericValue > 9999
      ) {
        return { valid: false, error: "Please enter a valid year" };
      }

      return { valid: true, error: "" };
    },
    validateImage(file) {
      if (!file) return { valid: false, error: "No file selected." };

      const fileType = file.type;

      if (!fileType.startsWith("image/")) {
        return { valid: false, error: "Please select an image file." };
      }

      return { valid: true, error: "" };
    },
    validate_non_zero_num(value,fieldname) {
      const regex = /^(?!0+(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/;
      if (regex.test(value)) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: `${fieldname} should be non zero number` };
      }
    },
    generateSecureSerialNumber() {
      const min = 10000;
      const max = 100000;
      return window.crypto.getRandomValues(new Uint32Array(1))[0] % (max - min) + min;
  }
  },
};
