<template>
<div>
    <v-card flat>
        <template v-slot:title>
            <v-card-title class="d-flex justify-space-between">
                {{ title }}
            </v-card-title>
        </template>
        <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'View'">
            <template v-slot:actions>
                <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions"></v-select>
            </template>
        </SearchComponent>
        <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="View" @click="toggle_tital_view()">View</v-tab>
            <v-tab value="Add" @click="toggle_tital_add()">{{ tabTitle }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tab-items v-model="tab">
                <v-tab-item value="View" v-if="tab === 'View'">
                    <div v-if="loading" style="text-align: center;">Loading...</div>
                    <tableLayout :headers="accUserHeaders" :items="accUserDetails" loading loading-text="Loading... Please wait" :slotNames="accUserSlotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                        <template v-slot:blackListed="{ item }">
                            <span :class="item.blackListed ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                        </template>
                        <template v-slot:verified="{ item }">
                            <span :class="item.verified ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                        </template>
                        <template v-slot:referred_count="{ item }">
                            {{ item.referred_count }}
                            <span class="mdi mdi-eye viewReffer" v-if="item.referred_count > 0" @click="referred_view(item)"></span>
                        </template>
                        <template v-slot:Actions="{ item }">
                            <v-icon small class="mr-2" color="blue" @click="edit_accUser_item(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </tableLayout>
                </v-tab-item>
                <v-tab-item value="Add" v-else>
                    <accUserAddPage :accUserData="accUserData" :accUserButton="accUserOpertion" @switchaccUserTab="load_accUser_Tab" />
                </v-tab-item>
            </v-tab-items>
            <v-dialog v-model="dialogForReferred" max-width="990px" persistent>
                <v-card>
                    <v-card-title class="text-size d-flex justify-between align-center">
                        <div class="flex-grow-1 text-center">
                            {{ dialogMessage }}
                        </div>
                        <div>
                            <v-icon small class="" @click="close_referred" color="grey">mdi-close-circle</v-icon>
                        </div>
                    </v-card-title>
                    <v-card-actions class="d-inline">
                        <div>
                            <div v-if="loadingReffer" style="text-align: center;">Loading...</div>
                            <tableLayout :headers="referredHeaders" :items="referredDetails" v-else></tableLayout>
                        </div>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogForBatch" max-width="560px" persistent>
                <v-card>
                    <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogForBatchPage" :max-width="maxWidth" persistent>
                <v-card>
                    <v-card-title class="text-size  text-center" style="white-space: pre-line ;">{{
                        dialogMessageForPage
                    }}</v-card-title>
                    <v-card-text class="mt-0 pt-0">
                        <v-card-text class="p-0" v-if="divForDescription">
                            <h6>{{ dialogDescription }}</h6>
                            <p style="color:red">{{ dialogWarning }}</p>
                        </v-card-text>
                        <div class="userEmailDisplay">
                            <span v-for="(email, index) in userName" :key="index">{{ index + 1 }}. {{ email
                                    }}</span>
                        </div>
                        <div v-if="actionValue === 'Add tickets to Raffle'">
                            <v-text-field auto-grow variant="filled" label="Number of tickets to enter*" v-model="addTickets.numberOfTickets" :error-messages="error.numberOfTickets" @input="remove_error('numberOfTickets')"></v-text-field>
                            <v-select v-model="addTickets.raffle" :items="raffleItems.map(i => i.raffleCodeName)" label="Raffle"></v-select>
                        </div>
                        <div v-if="actionValue === 'Add Postal Entry'">
                            <div>
                                <div class="my-2 d-flex justify-content-end">
                                    <v-btn color="primary" class="ml-1" small @click="addNewRow">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn color="red " class="ml-1" :disabled="addPostal.length === 1" small @click="deleteRow">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="d-flex" v-for="(item, index) in addPostal" :key="index">
                                <v-text-field class="pl-2" variant="filled" label="No. Entries Rejected*" v-model="item.entriesRejected" :error-messages="error.entriesRejected" @input="remove_error('entriesRejected')"></v-text-field>
                                <v-text-field class="pl-2" variant="filled" label="No. Entries Accepted*" v-model="item.entriesAccepted" :error-messages="error.entriesAccepted" @input="remove_error('entriesAccepted')"></v-text-field>
                                <v-select class="pl-2" v-model="item.raffle" :items="raffleItems.map(i => i.raffleCodeName)" label="Raffle"></v-select>
                                <v-checkbox class="pl-2" v-model="item.notify" label="Notify the user"></v-checkbox>
                            </div>
                        </div>
                        <div v-if="actionValue === 'Add Credit to wallet'">
                            <v-text-field auto-grow variant="filled" label="Amount of credit to add*" v-model="addCreditToWallet.addAmount" :error-messages="error.addAmount" @input="remove_error('addAmount')"></v-text-field>
                        </div>
                        <div v-if="actionValue === 'Block User'">
                            <v-text-field variant="filled" label="Reason*" v-model="closeAccount.reason" :error-messages="error.reason" @input="remove_error('reason')"></v-text-field>
                        </div>
                        <div v-if="actionValue === 'Apply Voucher'">
                            <v-select v-model="applyVoucher.voucherName" :items="promotionalItems.map(i => i.voucherName)" label="Promotional Token"></v-select>
                        </div>
                        <span class="ml-5" style="color: red;" v-if="error.credit">{{ this.error.credit }}</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="function_batch_item()" :disabled="disableDialogOkBtn">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <div v-if="error.api" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{
                        error.api }}</div>
                </v-card>
            </v-dialog>
        </v-card-text>
        <logoutDia :showLogoutDialog="showLogoutDialog" />
        <successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
    </v-card>
</div>
</template>

<script>
import '@/assets/css/accountUser.css';
import apiUrl from '@/api/allApis.js'
import tableLayout from '../ReusableComponents/tableLayout.vue';
import accUserAddPage from './accountAddPage.vue';
import formateDateMixin from "../../mixins/formateDate.js"
import vaildationMixin from "../../mixins/validation.js"
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import importExportDataVue from '../ReusableComponents/importExportData.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import jwtMixin from '@/mixins/jwt.js'
import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    markRaw
} from 'vue';
import {
    jwtDecode
} from 'jwt-decode';
export default markRaw({
    mixins: [vaildationMixin, formateDateMixin, jwtMixin],
    components: {
        successMessageVue,
        accUserAddPage,
        tableLayout,
        SearchComponent,
        importExportDataVue,
        logoutDia
    },
    data() {
        return {
            dialogForReferred: false,
            loadingReffer: false,
            referredDetails: [],
            checkAdmin: false,
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            disableDialogOkBtn: false,
            tab: null,
            title: 'ACCOUNT USER',
            loading: false,
            tabTitle: "Add",
            accUserOpertion: false,
            referredHeaders: [{
                    align: 'center',
                    key: 'email',
                    title: 'Email',
                },
                {
                    align: 'start',
                    key: 'first_name',
                    title: 'First Name'
                },
                {
                    align: 'start',
                    key: 'last_name',
                    title: 'Last Name'
                },
            ],
            accUserHeaders: [{
                    align: 'start',
                    key: 'email',
                    title: 'Email',
                },
                {
                    key: 'first_name',
                    title: 'First Name'
                },
                {
                    key: 'last_name',
                    title: 'Last Name'
                },
                {
                    key: 'verified',
                    title: 'Verified'
                },
                {
                    key: 'blackListed',
                    title: 'Black Listed'
                },
                {
                    key: 'referred_count',
                    title: 'Referred count'
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                }
            ],
            accUserDetails: [],
            tableHeaders: [],
            accUserData: {
                email: '',
                user_name: '',
                password: '',
                first_name: '',
                last_name: '',
                type: [],
                blackListed: false,
                verified: false,
                new_user: false,
                marketing: {
                    email_opt: false,
                    sms_opt: false
                },
                profile_photo: '',
                referral: '',
                accounts: {
                    bank: [],
                    paypal: [],
                    address: []
                },
            },
            deleteaccUserItem: {},
            accUserSlotNames: ['blackListed', 'verified', 'referred_count', 'Actions'],
            actionsItems: ['Block User', 'Add Credit to wallet'],
            filterSlotNames: ['actions'],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [],
            userName: [],
            dialogForBatchPage: false,
            maxWidth: '560px',
            inputs: [],
            dialogDescription: '',
            dialogWarning: '',
            addTickets: {
                numberOfTickets: '',
                raffleID: '',
            },
            applyVoucher: {},
            addPostal: [{
                entriesRejected: '',
                entriesAccepted: '',
                raffle: null,
                notify: true
            }],
            closeAccount: {},
            addCreditToWallet: {},
            raffleItems: [{
                raffleID: "",
                raffleCodeName: ""
            }],
            promotionalItems: {
                voucherId: '',
                voucherName: ''
            },
            reasonItems: [{
                reasonId: '',
                reasonName: ''
            }],
            error: {
                numberOfTickets: '',
                addAmount: '',
                message: '',
                reason: '',
                img_url: '',
                credit: '',
                api: ''
            },
            modelValue: false,
            inputForImport: false,
            fieldsName: [],
            exportItems: [],
            tableName: 'AccountUser',
            admin_email: '',
            admin_id: '',
            value: ''
        }
    },
    watch: {
        actionValue(newActionValue) {
            if (newActionValue !== 'Select Action') this.handle_action_change(newActionValue);
        }
    },
    computed: {
        ...mapGetters(['UserDeatils', 'allReffleCodeOptionDetail', 'allReasonOptionDatail', 'allPromotionalName'])
    },

    created() {

        this.fetch_accUser_data();
        let Token = sessionStorage.getItem('userToken')
        if (Token) {
            this.decodeToken(Token)
        } else {
            this.$router.push({
                name: 'loginPage'
            });
        }
    },
    methods: {
        ...mapActions(['raffle_code_option_detail', 'reason_option_detail', 'promotional_token']),
        decodeToken(jwtToken) {
            const decoded = jwtDecode(jwtToken);
            this.admin_id = decoded.request.user_id
            this.admin_email = decoded.request.user_email;
        },
        close_referred() {
            this.dialogForReferred = false;
            this.error.reffered = '';
        },
        async referred_view(Item) {
            try {
                this.dialogForReferred = true;
                this.loadingReffer = true;
                this.dialogMessage = 'Referred Details'
                let referrer_id = Item.referral_id
                await fetch(`${apiUrl.referred}?referrer_id=${referrer_id}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        }
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.referredDetails = data.Items;
                        } else {
                            this.error.reffered = data.message ? data.message : data
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatchPage = false;
                this.error.reffered = error;
                this.actionValue = 'Select Action';
                throw error
            } finally {
                this.loadingReffer = false;
            }
        },
        deleteRow() {
            this.addPostal.pop();
        },
        addNewRow() {
            let row = {
                entriesRejected: '',
                entriesAccepted: '',
                raffle: null,
                notify: true
            }
            this.addPostal.push(row);
        },
        close_Model(value) {
            if (value === 'reload') {
                this.fetch_accUser_data();
                this.modelValue = false;
            }
            this.modelValue = false;
        },
        openImportDialog() {
            this.modelValue = true;
            this.inputForImport = true;
            this.fieldsName = [
                "email",
                "user_name",
                "password",
                "first_name",
                "last_name",
                "type",
                "blackListed",
                "verified",
                "new_user",
                "marketing",
                "profile_photo",
                "referral",
                "accounts",
            ]
        },
        openExportDialog() {
            this.modelValue = true;
            this.inputForImport = false;
            this.exportItems = this.accUserDetails;
        },
        function_batch_item() {
            let value = this.actionValue;
            switch (value) {
                case 'Add tickets to Raffle':
                    let validationtickets = this.validationAddInput();
                    if (validationtickets) this.addTickets_raffle();
                    break;
                case 'Add Postal Entry':
                    this.addPostal_entry();
                    break;
                case 'Add Credit to wallet':
                    let validationCredit = this.validationAddInput();
                    if (validationCredit) this.addCredit_wallet();
                    break;
                case 'Block User':
                    let validationBlock = this.validationAddInput();
                    if (validationBlock) this.close_Account();
                    break;
                case 'Apply Voucher':
                    this.apply_voucher();
                    break;
                default:
                    this.addTickets_Raffle();
                    break;
            }
        },
        validationAddInput() {
            let value = this.actionValue;
            switch (value) {
                case 'Add tickets to Raffle':
                    let numberOfTickets = this.valid_inputs(this.addTickets.numberOfTickets);
                    if (!numberOfTickets.valid) {
                        this.error.numberOfTickets = numberOfTickets.error
                        return false;
                    } else {
                        this.error.numberOfTickets = ''
                        return true;
                    }
                case 'Add Postal Entry':
                    this.addPostal_entry();
                    break;
                case 'Add Credit to wallet':
                    let addAmount = this.valid_inputs(this.addCreditToWallet.addAmount)
                    if (!addAmount.valid) {
                        this.error.addAmount = addAmount.error;
                        return false;
                    } else {
                        this.error.addAmount = '';
                        return true;
                    }
                case 'Block User':
                    let reason = this.valid_inputs(this.closeAccount.reason)
                    if (!reason.valid) {
                        this.error.reason = reason.error;
                        return false;
                    } else {
                        this.error.reason = '';
                        return true;
                    }
                case 'Apply Voucher':
                    this.apply_voucher();
                    break;
                default:
                    this.addTickets_Raffle();
                    break;
            }
        },
        async addTickets_raffle() {
            try {
                let raffle = this.raffleItems.find(i => i.raffleCodeName === this.addTickets.raffle)
                let raffleId = raffle.raffleID
                let batchData = {
                    userIds: this.selected.map(i => i.id),
                    raffleId: raffleId,
                    requiredTickets: this.addTickets.numberOfTickets
                }
                await fetch(apiUrl.addTicketsToRaffle, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchData)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.message) {
                            this.error.api = data.message;
                        } else {
                            this.dialogForBatchPage = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.addTickets = {};
                            this.fetch_accUser_data();
                        }
                    })
            } catch (error) {
                this.error.api = error;
                this.dialogForBatchPage = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        async addPostal_entry() {
            alert(this.addPostal[0].notify)
        },
        async addCredit_wallet() {
            try {
                this.disableDialogOkBtn = true;
                let credit = Number(parseFloat(this.addCreditToWallet.addAmount).toFixed(2))
                let batchData = {
                    ids: this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id,
                            user_email: i.email,
                            credit_balance: credit
                        }
                    }),
                }
                await fetch(apiUrl.addCreditToWallet, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchData)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.status) {
                            this.error.credit = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        } else {
                            this.error.credit = '';
                            this.dialogForBatchPage = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.addCreditToWallet = {};
                            this.fetch_accUser_data();
                        }
                    })
            } catch (error) {
                this.error.credit = error;
                this.dialogForBatchPage = true;
                this.actionValue = 'Select Action';
                throw error
            } finally {
                this.disableDialogOkBtn = false;
            }
        },
        async close_Account() {
            this.disableDialogOkBtn = true;
            try {
                let batchData = {
                    ids: this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id,
                            reason: this.closeAccount.reason,
                        }
                    }),
                }
                await fetch(apiUrl.closeUserAccount, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchData)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatchPage = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.closeAccount = {};
                            this.fetch_accUser_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatchPage = false;
                this.error.api = error;
                this.actionValue = 'Select Action';
                throw error
            } finally {
                this.disableDialogOkBtn = false;
            }
        },
        async apply_voucher() {
            try {
                let voucher = this.promotionalItems.find(i => i.voucherName === this.applyVoucher.voucherName)
                let voucherId = voucher.voucherId
                let batchData = {
                    ids: this.selected.map(i => i.id),
                    voucherId: voucherId,
                }
                await fetch(apiUrl.closeUserAccount, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchData)
                    }).then((response) => response.json())
                    .then((_data) => {
                        this.dialogForBatchPage = false;
                        this.actionValue = 'Select Action';
                        this.selected = [];
                        this.applyVoucher = {};
                        this.fetch_accUser_data();
                    })
            } catch (error) {
                this.dialogForBatchPage = false;
                this.error.api = error;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        close_selected_delete() {
            this.dialogForBatch = false;
            this.dialogForBatchPage = false;
            this.addTickets = {};
            this.addCreditToWallet = {};
            this.closeAccount = {};
            this.error = {
                numberOfTickets: '',
                addAmount: '',
                message: '',
                reason: '',
                img_url: '',
                api: '',
                reffered: ''
            }
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        remove_error(value) {
            switch (value) {
                case 'numberOfTickets':
                    let numberOfTickets = this.validateNumberField(this.addTickets.numberOfTickets, 'Number of tickets')
                    if (!numberOfTickets.valid) {
                        this.error.numberOfTickets = numberOfTickets.error;
                        break;
                    } else {
                        this.error.numberOfTickets = '';
                        break;
                    }
                case 'entriesAccepted':
                    let entriesAccepted = this.validateNumberField(this.addPostal[0].entriesAccepted, 'No. Entries Accepted')
                    if (!entriesAccepted.valid) {
                        this.error.entriesAccepted = entriesAccepted.error;
                        break;
                    } else {
                        this.error.entriesAccepted = '';
                        break;
                    }
                case 'entriesRejected':
                    let entriesRejected = this.validateNumberField(this.addPostal[0].entriesRejected, 'No. Entries Rejected')
                    if (!entriesRejected.valid) {
                        this.error.entriesRejected = entriesRejected.error;
                        break;
                    } else {
                        this.error.entriesRejected = '';
                        break;
                    }
                case 'addAmount':
                    let addAmount = this.validateNumberField(this.addCreditToWallet.addAmount, 'Amount of credit to add')
                    if (!addAmount.valid) {
                        this.error.addAmount = addAmount.error;
                        break;
                    } else {
                        this.error.addAmount = '';
                        break;
                    }
                case 'message':
                    this.error.message = '';
                    break;
                case 'reason':
                    this.error.reason = '';
                    break;
                case 'img_url':
                    this.error.img_url = '';
                    break;
                default:
                    this.error.numberOfTickets = '';
                    this.error.addAmount = '';
                    this.error.entriesRejected = '';
                    this.error.entriesAccepted = '';
                    this.error.message = '';
                    this.error.img_url = '';
                    break;
            }
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            this.userName = this.selected.map(i => i.email)
            if (this.selected.length) {
                switch (selectedItem) {
                    case 'Delete Selected Items':
                        this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                        this.dialogSelectBtn = false;
                        this.dialogForBatch = true;
                        break;
                    case 'Add tickets to Raffle':
                        this.dialogMessageForPage = 'Select how many tickets to add to a raffle for the following user:'
                        this.divForDescription = false;
                        this.maxWidth = '790px';
                        this.raffleItems = this.allReffleCodeOptionDetail.map((i) => ({
                            raffleID: i.raffleID,
                            raffleCodeName: i.raffleCodeName,
                        }));
                        this.addTickets.raffle = this.raffleItems[0].raffleCodeName
                        this.dialogForBatchPage = true;
                        break;
                    case 'Add Postal Entry':
                        this.dialogMessageForPage = 'Select which how many postal entries have physically been recieved and which raffle the following users have submittted a postal entry for...'
                        this.dialogForBatchPage = true;
                        this.divForDescription = false;
                        this.maxWidth = '990px'
                        this.raffleItems = this.allReffleCodeOptionDetail.map((i) => ({
                            raffleID: i.raffleID,
                            raffleCodeName: i.raffleCodeName,
                        }));
                        this.addPostal[0].raffle = this.raffleItems[0].raffleCodeName
                        this.divForAddTicket = false;
                        break;
                    case 'Add Credit to wallet':
                        this.dialogMessageForPage = this.selected.length === 1 ? 'How many credit should be allocated to this user?' : 'How many credit should be allocated to this users?'
                        this.divForDescription = false;
                        this.maxWidth = '790px'
                        this.dialogForBatchPage = true;
                        break;
                    case 'Block User':
                        this.dialogMessageForPage = 'Are you sure you want to blacklist this/these client(s)?'
                        this.dialogDescription = 'Users who get blacklisted will deactivated and blocked from signing up again!'
                        this.maxWidth = '790px';
                        this.divForDescription = true;
                        this.reasonItems = this.allReasonOptionDatail.map((i) => ({
                            reasonId: i.reasonID,
                            reasonName: i.reasonNames
                        }));
                        this.closeAccount.reason = this.reasonItems[0].reasonName
                        this.dialogForBatchPage = true;
                        break;
                    case 'Apply Voucher':
                        this.dialogMessageForPage = 'Select the Promotional voucher code...'
                        this.maxWidth = '790px';
                        this.promotionalItems = this.allPromotionalName.map((i) => ({
                            voucherId: i.voucherId,
                            voucherName: i.voucherName
                        }))
                        this.applyVoucher.voucherName = this.promotionalItems[0].voucherName
                        this.divForDescription = false;
                        this.dialogForBatchPage = true;
                        break;
                    default:
                        this.dialogSelectBtn = false;
                        this.userName = [];
                        this.dialogForBatchPage = false;
                        this.dialogForBatch = false;
                        break;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteaccUserTableData, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_accUser_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        edit_accUser_item(item) {
            this.tab = "Add";
            this.tabTitle = "Edit";
            this.accUserOpertion = true;
            this.accUserData = item;
            this.checkAdmin = this.admin_id === item.sort_id ? true : false;
        },
        toggle_tital_add() {
            if (this.tabTitle == "ADD") {
                this.accUserData = {
                    blackListed: false,
                    verified: false,
                    new_user: false,
                    marketing: {
                        email_opt: false,
                        sms_opt: false,
                    },
                    accounts: {
                        bank: [],
                        paypal: [],
                        address: []
                    },
                };
                this.accUserOpertion = false
            }
        },
        async fetch_accUser_data() {
            this.search = '';
            this.loading = true;
            try {
                await fetch(apiUrl.fetchaccUserTableData, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json;",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                    })
                    .then((response) => response.json())
                    .then((_data) => {
                        if (!_data.status) {
                            this.accUserDetails = _data.SortedUser;
                            this.selected = [];
                            if (this.checkAdmin) {
                                let user = _data.SortedUser.filter(item => item.sort_id == this.admin_id)
                                if (!user[0].type.includes('superUser')) {
                                    this.$router.push({
                                        name: 'loginPage'
                                    });
                                    sessionStorage.clear();
                                    this.checkAdmin = false;
                                }
                            }
                        } else {
                            this.error.api = _data.message ? _data.message : _data;
                            if (_data.message === "Token Expired" || _data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error.api = error;
            } finally {
                this.loading = false;
            }
        },
        delete_model(item) {
            this.deleteaccUserItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.deleteaccUserItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        toggle_tital_view() {
            this.tabTitle = "ADD"
            this.fetch_accUser_data();
        },
        load_accUser_Tab(message) {
            this.show_snackbar(message)
            this.tab = "View"
            this.fetch_accUser_data();
            this.tabTitle = "ADD"
            this.accUserData = {
                blackListed: false,
                verified: false,
                new_user: false,
                marketing: {
                    email_opt: false,
                    sms_opt: false,
                },
                accounts: {
                    bank: [{
                        id: '',
                        bank_name: '',
                        account_number: '',
                        sort_code: '',
                        created: '',
                        active: false
                    }],
                    paypal: [{
                        id: '',
                        mail_id: '',
                        created: '',
                        active: false
                    }],
                    address: [{
                        id: '',
                        fullname: '',
                        phone_no: '',
                        address_line_1: '',
                        address_line_2: '',
                        post_code: '',
                        city: '',
                        created: '',
                        active: false
                    }]
                },
            };
            this.accUserOpertion = false
        },
        show_snackbar(message) {
            this.showSnackbar = true;
            this.successMessage = message;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }
    },

})
</script>
