
export default {
    data(){
        return{
            img_url: 'https://mwc-storage-bucket.s3.ap-south-2.amazonaws.com/',
        }
    },
    methods: {
        async uploadToS3(signedUrl, file) {
            try {
                const response = await fetch(signedUrl, {
                    method: "PUT",
                    body: file
                });
                if (!response.ok) {
                    return "Failed to upload file";
                } else {
                    return "File uploaded successfully";
                }
            } catch (error) {
                console.error("Error uploading file:", error);
                throw error;
            }
        },
    },
};
