<template>
<v-card flat title="WEBSITE CATEGORY">
    <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'View'">
        <template v-slot:actions>
            <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
        </template>
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="View" @click="toggle_tital_view()">View</v-tab>
        <v-tab value="Add" @click="toggle_tital_add()">{{ tabTitle }}</v-tab>
    </v-tabs>
    <v-card-text>
        <v-tab-items v-model="tab">
            <v-tab-item value="View" v-if="tab === 'View'">
                <div v-if="loading" style="text-align: center;">Loading...</div>
                <tableLayout :headers="categoryHeaders" :items="categoryDetails" :slotNames="categorySlotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                    <template v-slot:display="{ item }">
                        <span :class="item.display ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                    <template v-slot:active="{ item }">
                        <span :class="item.active ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                    <template v-slot:Actions="{ item }">
                        <v-icon small class="mr-2" color="blue" @click="edit_category_item(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small color="red darken-1" @click="delete_model(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="Add" v-else>
                <categoryAddPage :categoryData="categoryData" :categoryButton="categoryOpertion" @switchcatTab="load_category_Tab" />
            </v-tab-item>
        </v-tab-items>
        <v-dialog v-model="dialogForBatch" max-width="560px" persistent>
            <v-card>
                <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card-text>
</v-card>
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
<logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import apiUrl from '@/api/allApis.js'
import tableLayout from '../ReusableComponents/tableLayout.vue';
import categoryAddPage from './categoryAddPage.vue';
import {
    markRaw
} from 'vue';
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

export default markRaw({
    mixins: [jwtMixin],
    components: {
        categoryAddPage,
        tableLayout,
        SearchComponent,
        successMessageVue,
        logoutDia
    },
    data() {
        return {
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            tab: null,
            loading: false,
            tabTitle: "Add",
            categoryOpertion: false,
            categoryHeaders: [{
                    key: 'name',
                    title: 'Category  Name',
                },
                {
                    key: 'display_name',
                    title: 'Category Display Name'
                },
                {
                    key: 'summary',
                    title: 'Category Summary'
                },
                {
                    key: 'active',
                    title: 'Category Active'
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                }
            ],
            error: {
                api: ''
            },
            categoryDetails: [],
            tableHeaders: [],
            categoryData: {},
            deleteCategoryItem: {},
            categorySlotNames: ['display', 'active', 'Actions'],
            actionsItems: ['Delete Selected Items'],
            filterSlotNames: ['actions'],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [],
        }
    },
    created() {
        this.fetch_category_data();
    },
    methods: {
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteRaffoluxCategory, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_category_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.actionValue = 'Select Action';
                throw error
            } finally {
                this.dialogForBatch = false;

            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        edit_category_item(item) {
            this.tab = "Add";
            this.tabTitle = "Edit";
            this.categoryOpertion = true
            this.categoryData = item
        },
        toggle_tital_add() {
            if (this.tabTitle == "ADD") {
                this.categoryData = {};
                this.categoryOpertion = false
            }
        },
        async fetch_category_data() {
            this.search = "";
            try {
                this.loading = true;
                await fetch(apiUrl.fetchRaffoluxCategory, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json;",
                            'Authorization': JSON.parse(this.jwt_token())
                        }
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.loading = false;
                            this.categoryDetails = data.SortedCategory;
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.loading = false;
                throw error
            }finally{
                this.selected = [];
            }
        },
        delete_model(item) {
            this.deleteCategoryItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.deleteCategoryItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogFunction = false;
            this.dialogForBatch = true;
        },
        toggle_tital_view() {
            this.tabTitle = "ADD"
            this.fetch_category_data();
        },
        load_category_Tab(message) {
            this.show_snackbar(message)
            this.tab = "View"
            this.fetch_category_data();
            this.tabTitle = "ADD"
            this.categoryData = {};
            this.categoryOpertion = false
        },
        show_snackbar(message) {
            this.successMessage = message;
            this.showSnackbar = true;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }

    },

})
</script>
