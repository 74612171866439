<template>
    <div>
        <v-dialog v-model="dialog" width="860px" persistent>
            <v-card>
                <v-card-title class="text-h5 text-center">{{ inputForImport ? importMessage : 'EXPORT :' }}</v-card-title>
                <v-card-text class="field-container" v-if="inputForImport">
                    <span v-for="(item, index) in fieldsName" :key="index" class="fieldName">{{ index + 1 }}. {{ item
                    }}</span>
                </v-card-text>
                <v-card-text>
                    <v-card-item>
                        <div class="d-flex justify-content-around">
                            <div style="width:35%">
                                <v-select v-if="inputForImport" v-model="selectedFormat" class="pt-1" :items="importFormats"
                                    variant="outlined" label="Select File Format"></v-select>
                                <v-select v-else v-model="selectedExportFormat" class="pt-1" :items="exportFormats"
                                    variant="outlined" label="Select File Format"></v-select>
                            </div>
                            <div style="width:65%" v-if="inputForImport">
                                <v-file-input :accept="selectedFormat" class="pl-4 pt-1" label="File input"
                                    variant="outlined" show-size :error-messages="fileInput"
                                    @change="remove_error('fileInput')"></v-file-input>
                            </div>
                        </div>
                    </v-card-item>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" @click="inputForImport ? import_item() : exportData()"
                        :disabled="btnDisable">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import '@/assets/css/importExport.css'

import * as XLSX from 'xlsx/xlsx.mjs';
import exportData from '../../mixins/exportData '
import apiUrl from '@/api/allApis';
import yaml from 'js-yaml';
import Papa from 'papaparse';
export default {
    mixins: [exportData],
    data() {
        return {
            importMessage: 'This importer will import the following fields:',
            btnDisable: false,
            fileInput: '',
            selectedExportFormat: 'csv',
            selectedFormat: '.csv',
            importFormats: ['.csv', '.xls', '.xlsx', '.tsv', '.json', '.yaml'],
            exportFormats: ['csv', 'xls', 'xlsx', 'tsv', 'ods', 'json', 'yaml', 'html']
        }
    },
    props: {
        modelValue: Boolean,
        inputForImport: Boolean,
        fieldsName: Array,
        exportItems: Array,
        tableName: String
    },
    computed: {
        dialog: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        exportData() {
            this.exportToFile(this.exportItems, this.selectedExportFormat, this.tableName);
        },
        import_item() {
            this.handleFileUpload();
        },
        parseCSV(data) {
            Papa.parse(data, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    this.importData(results.data)
                }
            });
        },
        close(value) {
            this.$emit('closeModel', value);
        },
        remove_error(value) {
            if (value === 'fileInput') {
                this.fileInput = ''
            }
        },
        handleFileUpload() {
            const fileInput = document.querySelector('input[type=file]');
            const file = fileInput.files[0];
            if (!file) {
                this.fileInput = 'Select File'
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                switch (this.selectedFormat) {
                    case '.csv':
                    case '.tsv':
                        this.parseCSV(data);
                        break;
                    case '.xlsx':
                        this.parseXLSX(data);
                        break;
                    case '.xls':
                        this.parseXLS(data);
                        break;
                    case '.json':
                        this.parseJSON(data);
                        break;
                    case '.yaml':
                        this.parseYAML(data);
                        break;
                    default:
                        this.fileInput = "Unsupported file format"
                        break;
                }
            };
            reader.readAsBinaryString(file);
        },
        importData(data) {
            switch (this.tableName) {
                case 'AccountUser':
                    this.addImportUserData(data);
                    break;
                case 'WebsiteRaffle':
                    this.addImportRaffleData(data);
                    break;
                case 'CartPayment':
                    this.addImportPaymentData(data);
                    break;
                case 'Voucher':
                    this.addImportVoucherData(data);
                    break;
                default:
                    this.addImportUserData(data);
                    break;
            }
        },
        async addImportUserData(data) {
            let parsedData = {}
            if (this.selectedFormat === '.json' || this.selectedFormat === '.yaml') {
                parsedData = data;
            } else {
                parsedData = this.parseAccountUserData(data);
            }
            parsedData = parsedData.map(item => ({
                contact_number: String(item.contact_number),
                id: item.id,
                email: item.email,
                user_name: item.user_name,
                password: item.password,
                first_name: item.first_name,
                last_name: item.last_name,
                type: Array.isArray(item.type) ? item.type.map(type => type) : [],
                expiry_activation: item.expiry_activation,
                active: Boolean(item.active),
                verified: Boolean(item.verified),
                is_newuser: Boolean(item.is_newuser),
                is_social_user: Boolean(item.is_social_user),
                marketing: {
                    email_opt: Boolean(item.marketing.email_opt),
                    sms_opt: Boolean(item.marketing.sms_opt)
                },
                profile_photo: item.profile_photo,
                referral: Number(item.referral),
                bonus: Number(item.bonus),
                sms_code: Number(item.sms_code),
                accounts: {
                    bank: item.accounts.bank.map(bank => bank),
                    paypal: item.accounts.paypal.map(paypal => paypal),
                    address: item.accounts.address.map(address => address)
                }
            }));
            try {
                await fetch(apiUrl.importRaffoluxUser, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(parsedData)
                }).then((response) => response.json())
                    .then((_data) => {
                        this.btnDisable = false
                        this.close('reload');
                    })
            } catch (error) {
                this.btnDisable = false
                throw error
            }
        },
        async addImportRaffleData(data) {
            this.btnDisable = true
            try {
                await fetch(apiUrl.importRaffles, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then((response) => response.json())
                    .then((_data) => {
                        this.btnDisable = false;
                        this.close('reload');
                    })
            } catch (error) {
                this.btnDisable = false;
                throw error
            }
        },
        async addImportPaymentData(data) {
            this.btnDisable = true;
            data = data.map(item => ({
                id: typeof item.id !== 'string' ? item.id.toString() : item.id,
                amount: Number(item.amount),
                order_reference: Number(item.order_reference),
                order_job_reference: Number(item.order_job_reference),
                status: Number(item.status),
                payment_method: Number(item.payment_method),
                cart_id: typeof item.cart_id !== 'string' ? item.cart_id.toString() : item.cart_id,
                user_id: item.user_id,
                notes: typeof item.notes === 'string' ? item.notes : item.notes.toString(),
                credit_transaction_id: typeof item.credit_transaction_id === 'string' ? item.credit_transaction_id : String(item.credit_transaction_id),
                one_time_check: typeof item.one_time_check === 'string' ? JSON.parse(item.one_time_check.toLowerCase()) : item.one_time_check,
                transaction_id: typeof item.transaction_id === 'string' ? item.transaction_id : item.transaction_id.toString(),
                transaction_reference_id: typeof item.transaction_reference_id === 'string' ? item.transaction_reference_id : item.transaction_reference_id.toString()
            }));
            try {
                await fetch(apiUrl.importCartPayment, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then((response) => response.json())
                    .then((_data) => {
                        this.btnDisable = false;
                        this.close('reload');
                    })
            } catch (error) {
                this.btnDisable = false;
                throw error
            }
        },
        async addImportVoucherData(data) {
            this.btnDisable = true;
            data = data.map(item => ({
                active: typeof item.active === 'string' ? JSON.parse(item.active.toLowerCase()) : item.active,
                sharable: typeof item.sharable === 'string' ? JSON.parse(item.sharable.toLowerCase()) : item.sharable,
                purchase_required: typeof item.purchase_required === 'string' ? JSON.parse(item.purchase_required.toLowerCase()) : item.purchase_required,
                is_discount: typeof item.is_discount === 'string' ? JSON.parse(item.is_discount.toLowerCase()) : item.is_discount,
                max_users: Number(item.max_users),
                purchase_amt: Number(item.purchase_amt),
                type: Number(item.type),
                credit_amt: Number(item.credit_amt),
                discount_amt: Number(item.discount_amt),
                id: item.id,
                token: item.token.toString(),
                name: item.name.toString(),
                description: item.description.toString(),
                instruction: item.instruction.toString(),
                free_credit_expiry: item.free_credit_expiry.toString(),
                credit_expiry_duration: item.credit_expiry_duration.toString()
            }))
            try {
                await fetch(apiUrl.importVoucher, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then((response) => response.json())
                    .then((_data) => {
                        this.btnDisable = false;
                        this.close('reload');
                    })
            } catch (error) {
                this.btnDisable = false;
                throw error
            }
        },
        parseAccountUserData(jsonData) {
            try {
                return jsonData.map(item => {
                    let marketing = item.marketing ? JSON.parse(item.marketing) : {};
                    let utm = item.utm ? JSON.parse(item.utm) : {};
                    let social_auth = item.social_auth ? JSON.parse(item.social_auth) : {};
                    let type = item.type ? JSON.parse(item.type) : [];
                    let accounts = item.accounts ? JSON.parse(item.accounts) : {};
                    return {
                        ...item,
                        marketing: marketing,
                        accounts: accounts,
                        utm: utm,
                        social_auth: social_auth,
                        ['type']: type
                    };
                });
            } catch (error) {
                console.error('Error parsing JSON data:', error);
                return []; 
            }
        },
        parseXLS(data) {
            const workbook = XLSX.read(data, {
                type: 'binary'
            });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_row_object_array(sheet);
            this.importData(jsonData);
        },
        parseXLSX(data) {
            const workbook = XLSX.read(data, {
                type: 'binary'
            });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            this.importData(jsonData);
        },
        parseJSON(data) {
            const dataArray = JSON.parse(data);
            this.importData(dataArray);
        },
        parseYAML(data) {
            const dataArray = yaml.load(data);
            this.importData(dataArray)
        },
    },
};
</script>
