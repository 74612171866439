const moment = require("moment");
import timerMixin from "./timer";
export default {
    mixins: [timerMixin],
    methods: {
        formate_Date(value) {
            return moment(value).format("MMM DD, YYYY, h:mm a");
        },
        formate_input_Date(value) {
            return moment.utc(value).format("MMM DD, YYYY, h:mm a");
        },
        filter_Date(value, condition) {
            const today = new Date();
            let itemDate = value.split("T")[0];
            switch (condition) {
                case "Today":
                    let todayDate = today.toISOString().split("T")[0];
                    return todayDate === itemDate;
                case "Past 7 days":
                    const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                    let sevenDaysAgoDate = sevenDaysAgo.toISOString().split("T")[0];
                    return itemDate >= sevenDaysAgoDate;
                case "This month":
                    const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                    const itemValue = new Date(value)
                    const itemMonth = new Date(itemValue.getFullYear(), itemValue.getMonth(), 1);
                    return thisMonth.getTime() === itemMonth.getTime();
                case "This year":
                    const itemYearValue = new Date(value)
                    return today.getFullYear() === itemYearValue.getFullYear();
                default:
                    return false;
            }
        },
    },
};
