<template>
<v-text-field v-model="userCartEditDtls.id" label="ID" readOnly v-if="userCartSaveBtn"></v-text-field>

<v-autocomplete label="User Email*" variant="filled" v-model="emailAddress" :items="allOptionDatail.map(item => item.emailId)" :menu-props="{ minWidth: '300px' }" @update:model-value="handleUserChange" :error-messages="error.user_id">
</v-autocomplete>
<v-select v-model="userCartEditDtls.status" label="Status" :items="items" :error-messages="error.status" v-if="userCartSaveBtn"></v-select>

<div>
    <h6>
        Cart Items:
        <v-btn density="default" color="primary" icon="mdi-plus" @click="open_userCart_items()" v-if="!openUserCart"></v-btn>
    </h6>

    <v-row dense>
        <v-col :cols="12" :lg="6">
            <template v-if="openUserCart">
                <v-autocomplete label="Raffle Name*" id="item_id" rows="1" variant="filled" v-model="ItemName" :items="allReffleCodeOptionDetail.map(item => item.raffleCodeName)" :menu-props="{ minWidth: '300px' }" :error-messages="error.item_id" @update:model-value="remove_error('item_id')" v-if="!editCartButton">
                </v-autocomplete>
                <v-text-field label="Raffle Name*" id="item_id" rows="1" v-model="ItemName" :error-messages="error.item_id" readonly v-else></v-text-field>
                <v-text-field label="Raffle Id*" id="item_id" rows="1" v-model="userCartDtls.raffle_id" readOnly></v-text-field>
                <v-select v-model="userCartDtls.is_draw" label="Is Draw" :items="drawItems" :error-messages="error.is_draw"></v-select>
                <v-autocomplete label="Question*" id="question" rows="1" variant="filled" v-model="questionItem" :items="allQuestionOptionDetail.map(item => item.question)" :menu-props="{ minWidth: '300px' }" :error-messages="error.question" @update:model-value="remove_error('question')">
                </v-autocomplete>
                <v-text-field label="User Answer*" id="userAnswer" rows="1" v-model="userCartDtls.question.userAnswer" readOnly></v-text-field>
                <v-text-field label="Ticket Count*" id="ticketCount" rows="1" v-model="userCartDtls.ticket_count" :error-messages="error.ticketCount" @input="remove_error('ticketCount')"></v-text-field>
                <v-btn class="me-4 mb-4" color="blue" type="submit" @click="validate_userCart_inputs(userCartDtls.serial_no)" v-if="!editCartButton">
                    save Cart items
                </v-btn>
                <v-btn class="me-4 mb-4" color="blue" type="submit" @click="validate_userCart_inputs(userCartDtls.serial_no)" v-else>
                    Update Cart items
                </v-btn>

                <div style="color: red" v-if="error.activeTickets">{{ error.activeTickets }}</div>

            </template>
        </v-col>
    </v-row>
    <h6 v-if="finalCartData.length">
        Cart:
    </h6>
    <v-container fluid v-if="finalCartData.length">
        <v-row dense>
            <v-col v-for="(user, index) in finalCartData" :key="index" :cols="12" :lg="6">
                <v-card>
                    <v-card-text line-height=".75rem"><p>Raffle Id : {{ user.raffle_id }} </p>
                        <p>Is Draw: {{ user.is_draw }} </p>
                        <p>Question Id: {{ user.question.id }} </p>
                        <p>User Answer: {{ user.question.userAnswer }} </p>
                        <p>Ticket Count: {{ user.ticket_count }} </p>
                    </v-card-text>
                    <v-card-actions padding="0">
                        <v-spacer></v-spacer>
                        <v-btn icon @click="edit_userCart_item(user.serial_no)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="delete_userCart_opendialog(user.serial_no)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-dialog v-model="dialogForItem" max-width="560px" persistent>
        <v-card>
            <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closed_delete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="delete_userCart_item(this.deleteId)">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            <div v-if="error.api" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{ error.api }}</div>
        </v-card>
    </v-dialog>
</div>
<v-btn class="mr-4" @click="validate_userCart()" color="blue" v-if="!userCartSaveBtn" :disabled="userCartAdd">
    Add
</v-btn>
<v-btn class="mr-4" @click="validate_userCart()" color="blue" :disabled="userCartAdd" v-else>
    Save
</v-btn>
<div style="color: red" v-if="error.api">{{ error.api }}</div>
</template>

<script>
import apiUrl from "@/api/allApis";
import vaildationMixin from "../../mixins/validation.js";
import jwtMixin from '@/mixins/jwt.js'
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    markRaw
} from 'vue';
export default markRaw({
    mixins: [vaildationMixin,jwtMixin],
    data: () => ({
        dialogMessage: 'Are you sure you want to delete selected item?',
        userCartAdd: false,
        edituserCartButton: false,
        openUserCart: false,
        items: ["active", "inactive"],
        drawItems: ["true", "false"],
        error: {
            user_id: "",
            voucher_id: "",
            item_id: "",
            status: '',
            api: "",
            activeTickets: ""
        },
        successmessage: '',
        userCartDtls: {
            raffle_id: "",
            ticket_count: '',
            question: {
                id: '',
                userAnswer: ''
            },
            is_draw: 'false'
        },
        userCartData: [],
        finalCartData: [],
        optionObject: {
            userId: '',
            emailId: ''
        },
        emailAddress: null,
        editCartButton: false,
        voucherObject: {
            allId: "",
            allNames: "",
        },
        voucherName: null,
        raffleObject: {
            raffleID: "",
            raffleCodeName: "",
        },
        ItemName: null,
        questionItem: null,
        dialogForItem: false,

    }),
    emits: ['switchPrivacyTab'],
    computed: {
        ...mapGetters(['allOptionDatail', 'allIdNameDetail', 'allReffleCodeOptionDetail', 'allQuestionOptionDetail']),
        isFormFilled() {
            return(this.error.user_id === "" || this.error.user_id === undefined);
        },
        isUserCartItemFilled() {
            return (
                (this.error.item_id === "" || this.error.item_id === undefined) &&
                (this.error.ticketCount === "" || this.error.ticketCount === undefined)
            );
        },
    },
    props: {
        userCartEditDtls: {},
        userCartSaveBtn: false,
    },
    watch: {
        'userCartDtls.raffle_id': function () {
            this.handle_item_name()
        },
        'userCartDtls.question.id': function () {
            this.handle_questionItem_name()
        },
        ItemName() {
            this.handleRaffleChange();
        },
        questionItem() {
            this.handleQuestionChange();
        },

    },
    methods: {
        ...mapActions(['option_detail', 'id_name_option_detail', 'raffle_code_option_detail', 'question_detail']),
        async load_option_data() {
            let apiEndpoint = apiUrl.fetchaccUserTableData;
            await this.$store.dispatch('option_detail', apiEndpoint);
            apiEndpoint = apiUrl.fetchQuestion;
            await this.$store.dispatch('question_detail', apiEndpoint);
        },
        closed_delete() {
            this.dialogForItem = false;
        },
        handle_item_name() {
            if (this.editCartButton) {
                this.raffleObject = this.allReffleCodeOptionDetail.find(item => item.raffleID === this.userCartDtls.raffle_id)
                if (this.raffleObject) {
                    this.ItemName = this.raffleObject.raffleCodeName
                } else {
                    this.ItemName = "Raffle Name Not Available"
                }
            }
        },
        handle_questionItem_name() {
            if (this.editCartButton) {
                this.questionObject = this.allQuestionOptionDetail.find(item => item.questionId === this.userCartDtls.question.id)
                if (this.questionObject) {
                    this.questionItem = this.questionObject.question
                } else {
                    this.questionItem = "Question Name Not Available"
                }
            }
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
            if (selectedUser) {
                this.userCartEditDtls.user_id = selectedUser.userId;
                this.emailAddress = selectedUser.emailId;
                this.remove_error('user_id');
            }
        },
        async load_raffle_data() {
            const apiEndpoint = apiUrl.fetchRaffleTableData;
            await this.$store.dispatch('raffle_code_option_detail', apiEndpoint);
        },
        handleRaffleChange() {
            let selectedUser = this.allReffleCodeOptionDetail.find(item => item.raffleCodeName === this.ItemName);
            if (selectedUser) {
                this.userCartDtls.raffle_id = selectedUser.raffleID;
                this.ItemName = selectedUser.raffleCodeName;
                this.remove_error('item_id');
            }
        },
        handleQuestionChange() {
            let selectedUser = this.allQuestionOptionDetail.find(item => item.question === this.questionItem);
            if (selectedUser) {
                this.userCartDtls.question.id = selectedUser.questionId;
                this.userCartDtls.question.userAnswer = selectedUser.answer[0].answer;
                this.remove_error('question');
            }
        },

        remove_error(value) {
            switch (value) {
                case "user_id":
                    this.error.user_id = "";
                    break;
                case "voucher_id":
                    this.error.voucher_id = "";
                    break;

                case "item_id":
                    this.error.item_id = "";
                    break;
                case "question":
                    this.error.question = "";
                    break;
                case "ticketCount":
                    let ticket = this.validateNumberField(this.userCartDtls.ticket_count, 'Ticket Count')
                    if (!ticket.valid) {
                        this.error.ticketCount = ticket.error;
                        break;
                    } else {
                        this.error.ticketCount = "";
                        break;
                    }
                default:
                    this.error = {
                        user_id: "",
                        voucher_id: "",
                        ticketCount: '',
                        item_id: "",
                        question: '',
                        api: "",
                        activeTickets: ""
                    };
            }
        },
        validate_userCart() {
            const emailAddress = this.valid_inputs(this.emailAddress)

            if (!emailAddress.valid) {
                this.error.user_id = emailAddress.error;
            }
            else {
                if (this.isFormFilled) {
                    this.error = {
                        user_id: "",
                    };
                    if (!this.userCartSaveBtn) {
                        this.add_userCart();
                    } else {
                        this.update_userCart_Content();
                    }
                }
            }
        },
        async add_userCart() {
            this.userCartAdd = true;
            try {
                this.userCartEditDtls.status = this.userCartEditDtls.status === "active" ? 1 : 0
                this.userCartEditDtls.items = this.userCartData.map(i => ({
                    raffle_id: i.raffle_id,
                    ticket_count: i.ticket_count,
                    question: {
                        id: i.question.id,
                        userAnswer: i.question.userAnswer
                    },
                    is_draw: i.is_draw === false ? 0 : 1,
                }));
                let {
                    status,
                    ...userCartEditDtls
                } = this.userCartEditDtls
                await fetch(`${apiUrl.addUserCart}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(userCartEditDtls),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.userCartAdd = false;
                            this.successMessage = "Successfully added...";
                            this.$emit("switchPrivacyTab", this.successMessage);
                        } else {
                            this.userCartAdd = false;
                            this.error.api = data.message ? data.message : data;
                        }
                    });
            } catch (error) {
                this.userCartAdd = false;
                console.error("Error in add_userCart:", error);
                throw error;
            }
        },

        async update_userCart_Content() {
            this.userCartAdd = true;
            try {
                this.userCartEditDtls.items = this.userCartData.map(i => ({
                    raffle_id: i.raffle_id,
                    ticket_count: i.ticket_count,
                    question: {
                        id: i.question.id,
                        userAnswer: i.question.userAnswer
                    },
                    is_draw: i.is_draw === false ? 0 : 1,
                }));
                this.userCartEditDtls.status = this.userCartEditDtls.status === "active" ? 1 : 0
                let {
                    user_email,
                    ...userCartEditDtls
                } = this.userCartEditDtls
                await fetch(apiUrl.updateUserCart, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(userCartEditDtls),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.userCartAdd = false;
                            this.successMessage = "Successfully saved...";
                            this.$emit("switchPrivacyTab", this.successMessage);
                        } else {
                            this.userCartAdd = false;
                            this.error.api = data.message ? data.message : data;
                        }
                    });
            } catch (error) {
                this.userCartAdd = false;
                throw error;
            }
        },
        open_userCart_items() {
            this.openUserCart = true;
        },
        validate_userCart_inputs(Id) {
            const ItemName = this.valid_inputs(this.ItemName);
            const ticketCount = this.valid_inputs(this.userCartDtls.ticket_count);
            const question = this.valid_inputs(this.questionItem);
            if (!ItemName.valid) {
                this.error.item_id = ItemName.error;
            } else if (!question.valid) {
                this.error.question = question.error;
            } else if (!ticketCount.valid) {
                this.error.ticketCount = ticketCount.error;
            }
            else {
                if (this.isUserCartItemFilled) {
                    this.error = {
                        item_id: '',
                        ticketCount: ''
                    };
                    if (!this.editCartButton) {
                        this.add_userCart_items()
                    } else {
                        this.update_userCartDetails(Id);
                    }
                }
            }
        },

        add_userCart_items() {
            let userCartItemData = JSON.parse(sessionStorage.getItem("userCartDtls")) || [];
            let newItem = {
                serial_no: this.generateSecureSerialNumber(),
                raffle_id: this.userCartDtls.raffle_id,
                question: this.userCartDtls.question,
                is_draw: JSON.parse(this.userCartDtls.is_draw),
                ticket_count: Number(this.userCartDtls.ticket_count)
            };
            let itemExists = userCartItemData.find(item => item.raffle_id === newItem.raffle_id)
            if (!itemExists) {
                userCartItemData.push(newItem);
                sessionStorage.setItem("userCartDtls", JSON.stringify(userCartItemData));
                this.userCartData = userCartItemData;
                this.finalCartData = JSON.parse(JSON.stringify(userCartItemData));
                this.openUserCart = false
                this.userCartDtls = {
                    raffle_id: "",
                    ticket_count: '',
                    question: {
                        id: '',
                        userAnswer: ''
                    },
                    is_draw: 'false',
                    serial_no: '',
                };
                this.ItemName = null;
                this.questionItem = null;
            } else {
                this.error.item_id = "Cart Items with the same ID already exists."
            }
        },
        update_userCartDetails(id) {
            let userCartItemData = JSON.parse(sessionStorage.getItem("userCartDtls"));
            if (userCartItemData && Array.isArray(userCartItemData)) {
                const updatedIndex = userCartItemData.findIndex((item) => item.serial_no === id);
                if (updatedIndex !== -1) {
                    this.userCartDtls.is_draw = JSON.parse(this.userCartDtls.is_draw)
                    this.userCartDtls.ticket_count = Number(this.userCartDtls.ticket_count)
                    userCartItemData[updatedIndex] = this.userCartDtls;
                    sessionStorage.setItem("userCartDtls", JSON.stringify(userCartItemData));
                    this.userCartData = userCartItemData;
                    this.finalCartData = JSON.parse(JSON.stringify(userCartItemData));
                    this.editCartButton = false;
                    this.userCartDtls = {
                        raffle_id: "",
                        ticket_count: '',
                        question: {
                            id: '',
                            userAnswer: ''
                        },
                        is_draw: 'false',
                        serial_no: ''
                    };
                    this.ItemName = null;
                    this.questionItem = null;
                }
                this.openUserCart = false;
            }
        },
        edit_userCart_item(id) {
            this.editCartButton = true;
            const editedItem = this.userCartData.find(item => item.serial_no === id);
            this.userCartDtls = editedItem;
            this.openUserCart = true;
        },
        delete_userCart_opendialog(Id) {
            if (this.userCartDtls.serial_no != Id) {
                this.dialogForItem = true;
                this.deleteId = Id;
            }
        },
        delete_userCart_item(id) {
            if (!this.openUserCart) {
                let userCartItemData = JSON.parse(sessionStorage.getItem("userCartDtls"));
                if (userCartItemData && Array.isArray(userCartItemData)) {
                    const deleteUserCartItem = userCartItemData.findIndex((item) => item.serial_no === id);
                    if (deleteUserCartItem !== -1) {
                        userCartItemData.splice(deleteUserCartItem, 1);
                        sessionStorage.setItem("userCartDtls", JSON.stringify(userCartItemData));
                        this.userCartData = userCartItemData;
                        this.finalCartData = JSON.parse(JSON.stringify(userCartItemData));
                        this.dialogForItem = false;
                    }
                }
            }
        },

    },
});
</script>
