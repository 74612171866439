<template>
    <div>
        <v-text-field label="Id" v-if="newsButton" v-model="newsData.id" readOnly></v-text-field>
        <v-text-field label="Title*" ref="titleField" v-model="newsData.title" @input="remove_error('title')"
            :error-messages="error.title"></v-text-field>
        <v-text-field label="Slug*" ref="slugField" v-model="newsData.sort_id" @input="remove_error('sort_id')"
            :error-messages="error.sort_id" :readonly="newsButton"></v-text-field>
        <v-textarea auto-grow variant="filled" label="Content*" ref="contentField" rows="1" v-model="newsData.content"
            @input="remove_error('content')" :error-messages="error.content"></v-textarea>
        <v-select :items="statusItems" label="Status*" v-model="newsData.status"></v-select>
        <v-text-field label="Author*" ref="authorField" v-model="newsData.author" @input="remove_error('author')"
            :error-messages="error.author"></v-text-field>
        <v-text-field label="Img Alt" ref="imgAltField" v-model="newsData.img_alt" @input="remove_error('img_alt')"
            :error-messages="error.img_alt"></v-text-field>
        <v-textarea auto-grow variant="filled" rows="1" label="Keywords" v-model="newsData.keywords"
            @input="remove_error('keywords')" :error-messages="error.keywords"></v-textarea>
       <v-text-field label="Card Description*" v-model="newsData.card_description"
            @input="remove_error('card_description')" :error-messages="error.card_description"></v-text-field>
        <v-text-field label="Meta Description" v-model="newsData.meta_description"
            @input="remove_error('meta_description')" :error-messages="error.meta_description"></v-text-field>
        <v-text-field label="Meta Title" v-model="newsData.meta_title" @input="remove_error('meta_title')"
            :error-messages="error.meta_title"></v-text-field>

        <div v-if=newsButton>
            <v-row class="mb-3">
                <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                    <v-card>
                        <v-card-title class="caption">Image</v-card-title>
                        <v-img :src="img_url + newsData.img_url" height="150"></v-img>
                    </v-card>
                    <div>
                        <v-btn class="mt-3" color="blue" @click="img_upload">{{ imgUpload ? 'Close' : (newsData.img_url ?
                            'Edit Image' : 'Add Image') }}</v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-file-input accept="image/*" v-model="imgValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Image*"
            :error-messages="error.img_url" ref="img_url" @change="this.remove_error('img_url')"
            v-if="imgUpload"></v-file-input>
            <div>
            <v-btn class="me-4" color="blue" type="submit" v-if="!newsButton" @click=validate_inputs()
                :disabled="btnDisable">
                Add
            </v-btn>
            <v-btn color="blue" class="me-4" type="submit" v-else @click=validate_inputs() :disabled="btnDisable">
                SAVE
            </v-btn>
        </div>
        <logoutDia :showLogoutDialog="showLogoutDialog" />
        <div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
    </div>
</template>

<script>
import apiUrl from '@/api/allApis';
import vaildationMixin from "../../mixins/validation.js";
import imgUploadMixin from "../../mixins/imgUpload.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [vaildationMixin, imgUploadMixin, jwtMixin],
    emits: ['switchnewsTab'],
    data() {
        return {
            showLogoutDialog: false,
            imgValue: '',
            signedUrl: '',
            img_updated: false,
            imgUpload: false,
            btnDisable: false,
            statusItems: ['Draft', 'Publish'],
            error: {
                title: '',
                sort_id: '',
                content: '',
                author: '',
                img_alt: '',
                img_url: '',
                keywords: '',
                card_description: '',
                meta_description: '',
                meta_title: '',
                api: ''
            }
        }
    },
    props: {
        newsData: {},
        newsButton: false,
    },
    mounted() {
        if (!this.newsButton) {
            this.imgUpload = true
        }
    },
    computed: {
        isFormFilled() {
            return (
                (this.error.title === '' || this.error.title === undefined) &&
                (this.error.sort_id === '' || this.error.sort_id === undefined) &&
                (this.error.content === '' || this.error.content === undefined) &&
                (this.error.author === '' || this.error.author === undefined) &&
                (this.error.img_alt === '' || this.error.img_alt === undefined) &&
                (this.error.img_url === '' || this.error.img_url === undefined) &&
                (this.error.keywords === '' || this.error.keywords === undefined) &&
                (this.error.card_description === '' || this.error.card_description === undefined) &&
                (this.error.meta_description === '' || this.error.meta_description === undefined) &&
                (this.error.meta_title === '' || this.error.meta_title === undefined)
            );
        },
    },
    methods: {
        remove_error(value) {
            switch (value) {
                case 'title':
                    this.error.title = ''
                    break;
                case 'sort_id':
                    this.error.sort_id = ''
                    break;
                case 'content':
                    this.error.content = ''
                    break;
                case 'author':
                    this.error.author = ''
                    break;
                case 'img_alt':
                    this.error.img_alt = ''
                    break;
                case 'keywords':
                    this.error.keywords = ''
                    break;
                case 'img_url':
                    this.error.img_url = ''
                    break;
                case 'category':
                    this.error.category = ''
                    break;
                case 'card_description':
                    this.error.card_description = ''
                    break;
                case 'meta_description':
                    this.error.meta_description = ''
                    break;
                case 'meta_title':
                    this.error.meta_title = ''
                    break;
                default:
                    this.error = {
                        title: '',
                        sort_id: '',
                        content: '',
                        author: '',
                        img_alt: '',
                        img_url: '',
                        keywords: '',
                        card_description: '',
                        meta_description: '',
                        meta_title: '',
                        api: ''
                    }
            }
        },
        validate_inputs() {
            let title = this.valid_inputs(this.newsData.title);
            let sort_id = this.valid_inputs(this.newsData.sort_id);
            let content = this.valid_inputs(this.newsData.content);
            let author = this.valid_inputs(this.newsData.author);
            let card_description = this.valid_inputs(this.newsData.card_description);
            let img_url;
            if (this.newsButton) {
                if (this.imgUpload) {
                    img_url = this.valid_inputs(this.imgValue);
                } else {
                    img_url = {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                img_url = this.valid_inputs(this.imgValue);
            }

            if (!title.valid) {
                this.error.title = title.error;
                this.$refs.titleField.$el.querySelector('input').focus();
            } else if (!sort_id.valid) {
                this.error.sort_id = sort_id.error;
                this.$refs.slugField.$el.querySelector('input').focus();
            } else if (!content.valid) {
                this.error.content = content.error;
                this.$refs.contentField.$el.querySelector('textarea').focus();
            } else if (!author.valid) {
                this.error.author = author.error;
                this.$refs.authorField.$el.querySelector('input').focus();
            } else if (!card_description.valid) {
                this.error.card_description = card_description.error;
            } else if (!img_url.valid) {
                this.error.img_url = img_url.error;
                this.$refs.img_url.$el.querySelector('input').focus();
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        title: '',
                        sort_id: '',
                        content: '',
                        author: '',
                        card_description: '',
                        api: ''
                    }
                    if (!this.newsButton) {
                        this.news_add_item()
                    } else {
                        this.news_edit_item()
                    }
                }
            }
        },
        img_upload() {
            this.imgUpload = !this.imgUpload;
            this.error.img_url = '';
        },
        async news_add_item() {
            try {
                this.btnDisable = true
                this.newsData.status = this.newsData.status === 'Draft' ? 0 : 1;
                this.newsData.img_url = this.imgValue[0].name
                await fetch(apiUrl.addNewsTableData, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(this.newsData)
                }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            this.signedUrl = data.uploadURL;
                            let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                            if (message === "File uploaded successfully") {
                                this.successMessage = "Successfully added...";
                                this.$emit("switchnewsTab", this.successMessage);
                            } else {
                                this.error.api = message;
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.btnDisable = false;
            }
        },
        destructuring_data(newsData) {
            let {
                common_id,
                createdAt,
                updatedAt,
                ...newsUpdateData
            } = newsData;
            return newsUpdateData
        },
        async news_edit_item() {
            try {
                this.btnDisable = true
                this.img_updated = this.imgUpload ? true : false;
                if (this.imgUpload) {
                    this.newsData.img_url = this.imgValue[0].name
                }
                let editNewsData = this.destructuring_data(this.newsData)
                editNewsData.status = editNewsData.status === 'Draft' ? 0 : 1;
                let body = {
                    img_updated: this.img_updated,
                    ...editNewsData
                }
                await fetch(apiUrl.updateNewsTableData, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': JSON.parse(this.jwt_token())
                    },
                    body: JSON.stringify(body)
                }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            if (this.img_updated === true) {
                                this.signedUrl = data.uploadURL;
                                let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                if (message === "File uploaded successfully") {
                                    this.successMessage = "Successfully saved...";
                                    this.$emit("switchnewsTab", this.successMessage);
                                } else {
                                    this.error.api = message;
                                }
                            } else {
                                this.successMessage = "Successfully saved...";
                                this.$emit("switchnewsTab", this.successMessage);
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.btnDisable = false;
            }
        }
    }
})
</script>
