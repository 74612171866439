import * as XLSX from "xlsx/xlsx.mjs";
import json2yaml from "json2yaml";
import exportFromJSON from "export-from-json";
export default {
  methods: {
    flattenData(data) {
      const result = {};
      function flatten(obj, prefix = "") {
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            result[`${prefix}${key}`] = JSON.stringify(obj[key]);
          } else {
            result[`${prefix}${key}`] = obj[key];
          }
        }
      }

      flatten(data);
      return result;
    },

    exportToFile(data, fileType, tableName) {
      if (fileType === "json" || fileType === "yaml") {
        this.downloadJsonOrYaml(data, fileType, tableName);
      }else if(fileType==="xls"){
        try {
              const fileName = tableName || "exported-data";
              const exportType = exportFromJSON.types["xls"];
              exportFromJSON({ data, fileName, exportType });
            } catch (e) {
              throw new Error("Parsing failed!");
            }
      } else{
      let flattenedData;
      if (tableName === "AccountUser") {
        if (Array.isArray(data)) {
          flattenedData = data.map(this.flattenData); 
        } else {
          flattenedData = this.flattenData(data); 
        }
      } else {
        flattenedData = data;
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(flattenedData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      let wbout;

      if (fileType === "csv") {
        wbout = XLSX.utils.sheet_to_csv(ws);
      } else if (fileType === "tsv") {
        wbout = XLSX.utils.sheet_to_csv(ws, {
          FS: "\t",
        });
      } else {
        wbout = XLSX.write(wb, {
          bookType: fileType,
          type: "binary",
        });
      }
      const blob = new Blob([this.s2ab(wbout)], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${tableName}.${fileType}`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    downloadJsonOrYaml(data, fileType, tableName) {
      const content =
        fileType === "json" ? JSON.stringify(data) : json2yaml.stringify(data);
      const blob = new Blob([content], {
        type: `text/${fileType}`,
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${tableName}.${fileType}`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
};

