<template>
<v-card flat title="WALLET">
    <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'one'">
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_btn">View</v-tab>
        <v-tab value="two" @click="addBtn()" v-if="!this.btnBoolean">{{ getBtnName() }}</v-tab>
    </v-tabs>

    <v-card-text>
        <v-tab-items v-model="tab">
            <v-tab-item value="one" v-if="tab === 'one'">
                <div v-if="loadingData" style="text-align: center;">
                    Loading...
                </div>
                <tableLayout :headers="walletHeader" :items="walletItems" :slotNames="walletSlotNames" @updatedItems="updated_items" :showSelect="false" :search="search" v-else>
                    <template v-slot:Actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="blue">mdi-pencil</v-icon>
                     </template>
                    <template v-slot:credit_balance="{ item }">
                        <span>{{ formatCreditBalance(item.credit_balance) }}</span>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <walletAdd :editItem="item" :btnNameChange=btnBoolean @data-changed="show_changed_data"></walletAdd>
            </v-tab-item>
        </v-tab-items>
    </v-card-text>
</v-card>
<v-dialog v-model="dialogForBatch" max-width="560px" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="delete_batch_item()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        <div v-if="error.api" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{ error.api }}</div>
    </v-card>
</v-dialog>
<logoutDia :showLogoutDialog="showLogoutDialog" />
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
</template>

<script>
import walletAdd from './addWallet.vue'
import tableLayout from '../ReusableComponents/tableLayout.vue'
import apiUrl from '@/api/allApis.js'
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    markRaw
} from 'vue';
export default markRaw({
    mixins: [jwtMixin],
    components: {
        walletAdd,
        tableLayout,
        SearchComponent,
        successMessageVue,
        logoutDia
    },

    data() {
        return {
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            tab: null,
            btnBoolean: true,
            loadingData: false,
            item: {

            },
            walletSlotNames: [ 'Actions','credit_balance'],
            walletHeader: [{
                    key: 'user_email',
                    title: 'User Email'
                },
                {
                    key: 'credit_balance',
                    title: 'Credit Balance'
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                },
            ],
            walletItems: [],
            actionsItems: ['Delete Selected Items'],
            filterSlotNames: [  ],
            actionValue: 'Select Action',
            dialogForBatch: false,
            search: '',
            selected: [],
            indvidualItem: '',
            singleDelete: false,
            singleItem: [],
            error: {
                api: ''
            }
        }
    },
    created() {
        this.fetch_wallet_table_data();
    },
    methods: {
        formatCreditBalance(number) {
      return number.toFixed(2);
    },
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteWallet, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_wallet_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        editItem(item) {
            this.tab = 'two'
            this.item = item
            this.btnBoolean = false;
        },
        toggle_btn() {
            this.btnBoolean = true;
            this.item = {};
            this.fetch_wallet_table_data();
        },
        addBtn() {
            if (this.tabTitle == "ADD") {
                this.item = {}
            }
        },
        deleteItem(item) {
            this.indvidualItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.indvidualItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        show_changed_data(message) {
            this.show_snackbar(message)
            this.tab = "one";
            this.fetch_wallet_table_data();
            this.btnBoolean = true;
            this.item = {};
        },
        getBtnName() {
            return this.btnBoolean ? 'Add' : 'Edit';
        },
        changed_data() {
            this.fetch_wallet_table_data();
            this.tab = "one"
            this.tabTitle = "Add"
        },

        async fetch_wallet_table_data() {
            try {
                this.loadingData = true;
                this.search = '';
                await fetch(apiUrl.fetchWallet, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization':JSON.parse(this.jwt_token())
                        },

                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                        this.walletItems = data.SortedWallet;
                        }
                        else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error = error;
            } finally {
                this.selected = [];
                this.loadingData = false;
            }
        },
        show_snackbar(message) {
            this.successMessage = message;
            this.showSnackbar = true;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }
    }
});
</script>
