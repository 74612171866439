<template>
<v-card flat>
    <template v-slot:title>
        <v-card-title class="d-flex justify-space-between">
            {{ title }}
        </v-card-title>
    </template>
    <importExportDataVue :modelValue="modelValue" :inputForImport="inputForImport" :fieldsName="fieldsName" :exportItems="exportItems" :tableName="tableName" @closeModel="close_Model" />
    <v-row class="align-center">
        <v-col xs="12" sm="12" md="12" lg="10" xl="10">
            <SearchComponent @update:search="update_search" @keydown.enter="search_payments()" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'one'">
                <template v-slot:actions>
                    <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
                </template>
            </SearchComponent>
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="2" xl="2">
            <v-btn color="blue darken-1" text @click="search_payments()" :disabled="searchBtn">Search</v-btn>
        </v-col>
    </v-row>
    <div class="ml-3" v-if="error.user_id" :style="{ color: 'red' }">{{ error.user_id }}</div>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_btn">View</v-tab>
        <v-tab value="two" @click="addBtn()" v-if="tab === 'two'">{{ getBtnName() }}</v-tab>
    </v-tabs>
    <v-card-text>
        <v-items v-model="tab">
            <v-tab-item value="one" v-if="tab === 'one'">
                <template v-slot:text>
                </template>
                <div v-if="loadingData" style="text-align: center;">
                    Loading...
                </div>
                <tableLayout :headers="cartPaymentHeader" :items="cartPaymentItems" :slotNames="cartPaymentSlotNames" @updatedItems="updated_items" :showSelect="true" v-else>
                    <template v-slot:status="{ item }">
                        <span>{{ this.formate_status(item.status) }}</span>
                    </template>
                    <template v-slot:payment_type="{ item }">
                        <span>{{ 'payment_type' in item ? item.payment_type : 'Raffolux Payment' }}</span>
                    </template>
                    <template v-slot:credit_price_used="{ item }">
                        <span>{{ 'credit_price_used' in item ? item.credit_price_used : 'Raffolux Payment' }}</span>
                    </template>

                    <template v-slot:cash_prize="{ item }">
                        <span>{{ this.cash_prize(item.total_amount,item.credit_price_used) }}</span>
                    </template>
                    <template v-slot:Actions="{ item }">
                        <v-icon small @click="deleteItem(item)" color="red">mdi-delete</v-icon>
                    </template>
                </tableLayout>
                <div class="d-flex justify-content-end">
                    <v-btn color="blue darken-1" v-if="moreData" text @click="fetch_cart_payment_data()" :disabled="loadMore">Load
                        More</v-btn>
                </div>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <cartPaymentAdd :editItem="item" :btnNameChange=btnBoolean @data-changed="show_changed_data"></cartPaymentAdd>
            </v-tab-item>
        </v-items>
    </v-card-text>
</v-card>

<v-dialog v-model="dialogForBatch" :max-width="maxWidth" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="dialogFunction ? transaction_batch_item() : delete_batch_item()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
<div v-if="error.cartPaymentFetch" class="mt-0 mb-2" :style="{ color: 'red', textAlign: 'center' }">{{
                error.cartPaymentFetch }}</div>
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
<logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import cartPaymentAdd from './cartPaymentAdd.vue'
import tableLayout from '../ReusableComponents/tableLayout.vue'
import apiUrl from '../../api/allApis.js'
import formateDateMixin from "../../mixins/formateDate.js"
import userNameDisplay from "../../mixins/userNameDisplay.js"
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import importExportDataVue from '../ReusableComponents/importExportData.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import logoutDia from "../ReusableComponents/tokenExpireDia.vue";
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    markRaw
} from 'vue';
import jwtMixin from '@/mixins/jwt.js'
export default markRaw({
    mixins: [formateDateMixin, userNameDisplay, jwtMixin],
    components: {
        cartPaymentAdd,
        tableLayout,
        SearchComponent,
        importExportDataVue,
        successMessageVue,
        logoutDia
    },

    data() {
        return {
            userId: "",
            successMessage: "",
            loadMore: false,
            lastEvaluatedKey: null,
            moreData: true,
            showLogoutDialog: false,
            showSnackbar: false,
            maxWidth: '500px',
            item: {
                status: '1',
                method: 1
            },
            tab: null,
            btnBoolean: true,
            cartPaymentSlotNames: ['Actions', 'status', 'cash_prize', 'payment_type', 'credit_price_used'],
            filterItems: ['Search By Email', ],
            filterValue: 'Search By Email',
            loadingData: false,
            cartPaymentHeader: [{
                    key: 'user_email',
                    title: 'User Email',
                },
                {
                    key: 'status',
                    title: 'Status',
                },
                {
                    key: 'payment_type',
                    title: 'Payment Type',
                },

                {
                    key: 'credit_price_used',
                    title: 'Credit Price',
                },
                {
                    key: 'cash_prize',
                    title: 'Cash Price',
                },
                {
                    key: 'total_amount',
                    title: 'Total Amount',
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                },

            ],
            cartPaymentItems: [],
            indvidualItem: '',
            actionsItems: ['Delete Selected Items' ],
            filterSlotNames: ['actions', ],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [],
            confirmTransaction: 2,
            modelValue: false,
            inputForImport: false,
            fieldsName: [],
            exportItems: [],
            tableName: 'CartPayment',
            title: 'PAYMENT',
            prizeMessage:'Raffolux Payment',
            error: {
                cartPaymentFetch: "",
                user_id: '',
            }
        }
    },
    created() {
        this.fetch_cart_payment_data();
    },
    async mounted() {
        this.load_option_data();
    },
    computed: {
        ...mapGetters(['allOptionDatail']),
        searchBtn() {
            return !(this.search.length > 0)
        }
    },
    methods: {
        ...mapActions(['option_detail']),
        async load_option_data() {
            const apiEndpoint = `${apiUrl.fetchaccUserTableData}`;
            await this.$store.dispatch('option_detail', apiEndpoint);
        },
        close_Model(value) {
            if (value === 'reload') {
                this.fetch_cart_payment_data();
                this.modelValue = false;
            }
            this.modelValue = false;
        },
        cash_prize(totalAmount, creditPriceUsed) {
            if (creditPriceUsed !== undefined) {
                return totalAmount - creditPriceUsed;
            } else {
                return this.prizeMessage;
            }
        },
        openImportDialog() {
            this.modelValue = true;
            this.inputForImport = true;
            this.fieldsName = [
                "id",
                "amount",
                "order_reference",
                "order_job_reference",
                "status",
                "method",
                "cart_id",
                "user_id",
                "notes",
                "credit_transaction_id",
                "one_time_check",
                "transaction_id",
                "transaction_reference_id"
            ]
        },
        openExportDialog() {
            this.modelValue = true;
            this.inputForImport = false;
            this.exportItems = this.cartPaymentItems;
        },
        formate_status(value) {
            switch (value) {
                case 1:
                    return 'Pending';
                case 2:
                    return 'Confirmed';
                case 3:
                    return 'Cancelled';
                case 4:
                    return 'Declined';
                case 5:
                    return 'Refunded';
                default:
                    return 'Pending';
            }
        },
        async transaction_batch_item() {
            try {
                let batchIds = {
                    ids: this.selected,
                    status: this.confirmTransaction
                }
                await fetch(apiUrl.updateCartPaymentStatus, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.fetch_cart_payment_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length == 1 ? 'Are you sure you want to delete this item?' : 'Are you sure you want to delete selected items?'
                    this.maxWidth = '560px';
                    this.dialogFunction = false;
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Confirm transaction') {
                    this.dialogMessage = 'Are you sure you want to Confirm transaction selected items?'
                    this.dialogSelectBtn = false;
                    this.confirmTransaction = 2;
                    this.dialogFunction = true;
                    this.maxWidth = '700px';
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Cancel transaction') {
                    this.dialogMessage = 'Are you sure you want to Cancel transaction selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogFunction = true;
                    this.maxWidth = '700px';
                    this.confirmTransaction = 3;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.maxWidth = '460px';
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteCartPaymentMethod, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.singleItem = [];
                            this.refresh();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch.trim();
            this.error.user_id = ""
            this.userId = "";
        },

        editItem(item) {
            this.tab = 'two'
            this.item = item
            this.btnBoolean = false;
        },
        toggle_btn() {
            this.btnBoolean = true;
            this.item = {
                status: '1',
                method: 1
            };
            this.refresh();
        },
        addBtn() {
            if (this.tabTitle == "ADD") {
                this.item = {
                    status: '1',
                    method: 1
                };
            }
        },
        deleteItem(item) {
            this.indvidualItem = item;
            this.singleDelete = false;
            this.dialogFunction = false;
            this.singleItem = [{
                sort_id: this.indvidualItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        getBtnName() {
            return this.btnBoolean ? 'Add' : 'Edit';
        },
        show_changed_data() {
            this.refresh();
            this.tab = "one";
            this.btnBoolean = true;
            this.item = {};
        },
        refresh() {
            this.lastEvaluatedKey = null;
            this.moreData = true;
            this.cartPaymentItems = [];
            this.fetch_cart_payment_data();
        },
        async fetch_cart_payment_data() {
            this.search = '';
            try {
                this.loadingData = true;
                this.loadMore = true;
                let body = {
                    lastEvaluatedKey: this.lastEvaluatedKey
                }
                await fetch(apiUrl.fetchCartPaymentMethod, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(body)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            if (data.SortedPayment.length > 0) {
                                this.lastEvaluatedKey = data.lastEvaluatedKey || null;
                                this.cartPaymentItems = [...this.cartPaymentItems, ...data.SortedPayment];
                                if (this.lastEvaluatedKey === null) {
                                    this.moreData = false;
                                }
                            } else {
                                this.moreData = false;

                            }
                        } else {
                            this.error.cartPaymentFetch = data.message ? data.message : data
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.cartPaymentFetch = error;
                throw error
            } finally {
                this.loadingData = false;
                this.loadMore = false;
                this.error.user_id = ""
            }
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.search);
            if (selectedUser) {
                this.userId = selectedUser.userId;
            }
        },
        async search_payments() {
            let search = this.searchBtn;
            if (search) return
            this.error.user_id = ""
            try {
                this.loadingData = true;
                this.loadMore = true;
                let body;
                this.handleUserChange();
                if (!this.userId) {
                    this.error.user_id = "Email Not Found"
                }
                body = {
                    user_id: this.userId,
                }

                await fetch(apiUrl.filterPayments, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },

                        body: JSON.stringify(body)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.cartPaymentItems = data.Items;
                        }
                    })
            } catch (error) {
                this.error.cartPaymentFetch = error;
                throw error
            } finally {
                this.loadingData = false;
                this.loadMore = false;
            }
        },
        show_snackbar(message) {
            this.successMessage = message;
            this.showSnackbar = true;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }

    }
});
</script>
