<template>
    <v-select v-model="editItem.is_active" label="Active" :items="item"></v-select>
    <v-textarea auto-grow label="Question*" rows="1" v-model="editItem.question" @input="remove_error('question')" :error-messages="error.question"></v-textarea>
    
    <div class="textarea-container" v-for="(item, index) in answers" :key="index">
        <v-textarea v-model="item.answer" rows="1" :label="'Answer ' + (index + 1) + '*'" outlined dense @input="remove_error('Answer' + (index + 1))" :error-messages="error['Answer' + (index + 1)]"></v-textarea>
        <v-checkbox v-model="item.isAnswer" color="primary" :label="`${item.isAnswer}`" dense @click="update_is_answer(index)"></v-checkbox>
    </div>
    
    <div v-if=!btnNameChange>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Question Img</v-card-title>
                    <v-img :src="img_url + editItem.question_img" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="img_upload">{{ imgUpload ? 'Close' : 'Edit question image'
                            }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
    <v-file-input accept="image/*" v-model="imgValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Question Image*" :error-messages="error.question_img" @change="this.remove_error('question_img')" v-if="imgUpload"></v-file-input>
    
    <div style="text-align: center;">
        <v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-if=btnNameChange color="blue">ADD</v-btn>
        <v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-else color="blue">SAVE</v-btn>
        <div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
    </div>
    <logoutDia :showLogoutDialog="showLogoutDialog" />
    </template>
    
    <script>
    import apiurl from '@/api/allApis.js'
    import vaildationMixin from "../../mixins/validation.js"
    import imgUploadMixin from "../../mixins/imgUpload.js"
    import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
    import {
        markRaw
    } from 'vue';
    import jwtMixin from '@/mixins/jwt.js'
    export default markRaw({
        components: {
    logoutDia,
  },
        mixins: [vaildationMixin, imgUploadMixin, jwtMixin],
        name: "Add",
        props: {
            btnNameChange: Boolean,
            editItem: Object,
        },
        emits: ['data-changed'],
        data() {
            return {
                showLogoutDialog: false,
                img_updated: false,
                imgUpload: false,
                item: ['true', 'false'],
                successmessage: '',
                error: {
                    Answer1: '',
                    Answer2: '',
                    Answer3: '',
                    Answer4: '',
                    question: '',
                    question_img: '',
                    api: '',
                },
                buttonDisabled: false,
                answers: [{
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    },
                    {
                        answer: "",
                        isAnswer: false
                    }
                ],
                imgValue: '',
                signedUrl: '',
            }
        },
        mounted() {
            if (this.btnNameChange) {
                this.imgUpload = true
            }
            this.editItem.is_active = this.editItem.is_active === 'active' ? 'true' : 'false';
            if (Array.isArray(this.editItem.answers) && this.editItem.answers.length > 0) {
                this.answers = this.editItem.answers.map((i) => {
                    return {
                        answer: i.answer,
                        isAnswer: i.isAnswer
                    }
                })
            }
        },
        methods: {
            update_is_answer(newVal) {
                this.answers.forEach((answer, index) => {
                    if (index === newVal) {
                        answer.isAnswer = true;
                    } else {
                        answer.isAnswer = false;
                    }
                })
            },
            remove_error(value) {
                switch (value) {
                    case 'Answer1':
                        this.error.Answer1 = ''
                        break;
                    case 'Answer2':
                        this.error.Answer2 = ''
                        break;
                    case 'Answer3':
                        this.error.Answer3 = ''
                        break;
                    case 'Answer4':
                        this.error.Answer4 = ''
                        break;
                    case 'is_active':
                        this.error.is_active = ''
                        break;
                    case 'question':
                        this.error.question = ''
                        break;
                    case 'question_img':
                        this.error.question_img = ''
                        break;
                    default:
                        this.error = {
                            Answer1: '',
                            Answer2: '',
                            Answer3: '',
                            Answer4: '',
                            question: '',
                            question_img: '',
                            api: '',
                        }
                }
            },
            validate_inputs() {
                let answers1 = this.valid_inputs(this.answers[0].answer);
                let answers2 = this.valid_inputs(this.answers[1].answer);
                let answers3 = this.valid_inputs(this.answers[2].answer);
                let answers4 = this.valid_inputs(this.answers[3].answer);
                let question = this.valid_inputs(this.editItem.question);
                let question_img;
                if (!this.btnNameChange) {
                    if (this.imgUpload) {
                        question_img = this.valid_inputs(this.imgValue);
                    } else {
                        question_img = {
                            valid: true,
                            error: ""
                        };
                    }
                } else {
                    question_img = this.valid_inputs(this.imgValue);
                }
                if (!question.valid) {
                    this.error.question = question.error;
                } else if (!answers1.valid) {
                    this.error.Answer1 = answers1.error;
                } else if (!answers2.valid) {
                    this.error.Answer2 = answers2.error;
                } else if (!answers3.valid) {
                    this.error.Answer3 = answers3.error;
                } else if (!answers4.valid) {
                    this.error.Answer4 = answers4.error;
                } else if (!question_img.valid) {
                    this.error.question_img = question_img.error;
                } else {
                    this.error = {
                        answers: '',
                        is_active: '',
                        question: '',
                        question_img: '',
                        api: '',
                    }
    
                    for (let i = 0; i < this.answers.length; i++) {
                        let answer = this.answers[i].answer;
                        for (let j = i + 1; j < this.answers.length; j++) {
                            if (answer === this.answers[j].answer) {
                                this.error[`Answer${j}`] = 'Duplicate answer. This answer already exists.';
                                return;
                            }
                        }
                    }
                    if (this.btnNameChange) {
                        this.add_question_data()
                    } else {
                        this.edit_question_data()
                    }
                }
            },
            img_upload() {
                this.imgUpload = !this.imgUpload;
                this.error.question_img = '';
            },
            async edit_question_data() {
                try {
                    this.buttonDisabled = true;
                    this.editItem.answers = this.answers.map((i) => {
                        return {
                            answer: i.answer,
                            isAnswer: i.isAnswer
                        }
                    });
                    this.editItem.is_active = JSON.parse(this.editItem.is_active) ? 'active' : 'inactive'
                    this.img_updated = this.imgUpload ? true : false;
                    if (this.imgUpload) {
                        this.editItem.question_img = this.imgValue[0].name
                    }
                    let body = {
                        img_updated: this.img_updated,
                        ...this.editItem
                    }
                    const jsonString = JSON.stringify(body);
                    await fetch(apiurl.updateQuestion, {
                            method: "PUT",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                'Authorization': JSON.parse(this.jwt_token())
                            },
                            body: jsonString
                        })
                        .then((response) => response.json())
                        .then(async (data) => {
                            if (!data.status) {
                                if (this.img_updated === true) {
                                    this.signedUrl = data.uploadURL;
                                    let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                    if (message === "File uploaded successfully") {
                                        this.successMessage = "Successfully saved...";
                                        this.$emit("data-changed", this.successMessage);
                                    } else {
                                        this.error.api = message;
                                    }
                                } else {
                                    this.successMessage = "Successfully saved...";
                                    this.$emit("data-changed", this.successMessage);
                                }
                            } 
                            else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                        });
                } catch (error) {
                    this.error.api = error;
                    this.editItem.is_active = this.editItem.is_active === 'active' ? 'true' : 'false';
                    this.error.errorMessage = "An error occurred while saving data.";
                } finally {
                    this.buttonDisabled = false;
                }
            },
            async add_question_data() {
               
                try {
                    this.buttonDisabled = true;
                    this.editItem.answers = this.answers.map((i) => {
                        return {
                            answer: i.answer,
                            isAnswer: i.isAnswer
                        }
                    });
                    this.editItem.is_active = JSON.parse(this.editItem.is_active) ? 'active' : 'inactive'
                    this.editItem.question_img = this.imgValue[0].name
                    let body = this.editItem
                    const jsonString = JSON.stringify(body);
                    await fetch(apiurl.addQuestion, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                'Authorization': JSON.parse(this.jwt_token())
                            },
                            body: jsonString
                        })
                        .then((response) => response.json())
                        .then(async (data) => {
                            if (!data.status) {
                                this.signedUrl = data.uploadURL;
                                let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                if (message === "File uploaded successfully") {
                                    this.successMessage = "Successfully added...";
                                    this.$emit("data-changed", this.successMessage);
                                } else {
                                    this.error.api = message;
                                }
                            } else {
                                this.error.api = data.message ? data.message : data;
                                this.editItem.is_active = this.editItem.is_active === 'active' ? 'true' : 'false';
                                if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                            }
                        });
                } catch (error) {
                    this.error.api = error;
                } finally {
                    this.buttonDisabled = false;
                }
            },
        },
    
    });
    </script>
    
    <style scoped>
    .textarea-container {
        position: relative;
    }
    
    .v-input__slot::before {
        content: none !important;
    }
    
    .v-textarea--outlined .v-input__control::after {
        display: none !important;
    }
    
    .v-checkbox {
        position: absolute;
        right: 10px;
        top: 9px;
    }
    </style>
    