import moment from "moment-timezone";
export default {
    data() {
        return {
            countdownTimers: {},
            userTimeZone: ''
        }
    },
    beforeDestroy() {
        for (const timerId in this.countdownTimers) {
            clearInterval(this.countdownTimers[timerId]);
        }
    },
    mounted() {
        this.isUserTimezone();
    },
    methods: {
        startCountdownSingleUk(timerData) {
            let dateTime = timerData.raffle_expire.split('.000Z')[0]
            let targetDate = moment.tz(dateTime, this.userTimeZone);
            const timerId = timerData.raffle_id;
            this.countdownTimers[timerId] = '';
            const updateCountdown = () => {
                let now = moment.tz(this.userTimeZone);
                let duration = moment.duration(targetDate.diff(now));

                if (duration.asSeconds() <= 0) {
                    clearInterval(intervalId);
                    this.countdownTimers[timerId] = '00 : 00 : 00 : 00';
                    this.checkRefExpire();
                    return;
                } else {
                    let days = Math.floor(duration.asDays());
                    let hours = duration.hours();
                    let minutes = duration.minutes();
                    let seconds = duration.seconds();

                    const updatedDays = String(days).padStart(2, '0');
                    const updatedHours = String(hours).padStart(2, '0');
                    const updatedMinutes = String(minutes).padStart(2, '0');
                    const updatedSeconds = String(seconds).padStart(2, '0');
                    this.countdownTimers[timerId] = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
                }
            }
            const intervalId=setInterval(updateCountdown, 1000);
        },

        isUserTimezone() {
            const Env = process.env.VUE_APP_ENV
            if (Env === 'dev' || Env === 'testing') {
                this.userTimeZone = 'Asia/Kolkata'
            } else {
                this.userTimeZone = 'Europe/London'
            }
        }
    }
}