export default {
  data() {
    return {
      userEmail: "",
      userLastName: "",
      userFirstrName: "",
      id: "",
    };
  },
  methods: {
    logout() {
      sessionStorage.clear();
      this.userEmail = "";
      this.userLastName = "";
      this.userFirstrName = "";
      this.id = "";
      this.$router.push({
        name: "loginPage",
      });
    },
  },
};
