<template>
<v-card>
  <v-app-bar color="primary" prominent>
    <v-toolbar-title></v-toolbar-title>
    <profile></profile>
  </v-app-bar>
  <navigationDrawer></navigationDrawer>
</v-card>
</template>

<script>
import navigationDrawer from "@/views/navigationDrawer.vue"
import profile from "./profile.vue";
export default {
  components: {
    navigationDrawer,
    profile
  },
  beforeCreate(){
    let Token=sessionStorage.getItem('userToken')
    if (!Token) {
      this.$router.push({
          name: 'loginPage'
        });
      }
  }
}
</script>
