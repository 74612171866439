export default {
  state: {
    dropdownOptions: []
  },
  mutations: {
    setDropdownOptions(state, options) {
      state.dropdownOptions = options;
    }
  },
  actions: {
    async fetchDropdownOptions({ commit }) {
      try {
        const response = await fetch(`${apiUrl.fetchRestrictionsClientBlacklist}?common_id=${common_id}`);
        const data = await response.json();
        commit('setDropdownOptions', data);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    }
  }
};
