<template>
<div>
    <div class="row liveDrawPageNavBarBg m-0 align-items-center">
        <div class="col-12 text-center p-0">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/MWCLogo.svg" alt="" class="liveDrawMwcLogo">
        </div>
    </div>
    <div class="row m-0 postLiveDrawBg" v-if="isDataRenderd">
        <div class="col-6 p-0">
            <div class="postLiveDrawLeftSecWidth">
                <h2 class="postLiveDrawCongratsTxt mb-0">CONGRATULATIONS!</h2>
                <p class="postLiveDrawDescTxt">To the winner of the “{{ rafData[0]?.title }}” Competition</p>
                <div class="d-flex justify-content-center">
                    <div class="position-relative d-flex postLiveNameWidth">
                        <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/postLiveDrawWinnerImg.svg" class="postLiveDrawWinnerLogo" alt="">
                        <p class="postLiveDrawWinnerName">{{ isPostionFirst.UserName.toUpperCase() }}</p>
                    </div>
                </div>

                <p class="postDrawTktNumTxt">with lucky ticket number</p>
                <div class="d-flex justify-content-center">
                    <p class="postDrawTkt mb-0">{{ isPostionFirst.ticketNumber }}</p>
                </div>

            </div>
        </div>
        <div class="col-6 p-0">
            <div class="d-flex justify-content-center">
                <img :src="img_url + rafData[0].main_image_url" alt="" class="liveDrawRightSecImg">
            </div>
        </div>
    </div>
    <hr class="postLiveDrawHrLine">
    <div class="postLiveRunnerPrizesSec" v-if="isDataRenderd">
        <p class="postLiveRunnersDesc" v-if="isConsolationPrize != 0">And congratulations to our runner up prize
            winners who will each receive £{{ isConsolationPrize }} MWC Credit!</p>
        <div class="d-flex justify-content-between mb-4" v-for="i of otherWinners">
            <p class="postLiveDrawRunnerName mb-0">{{ formateName(i.winner.user_name) }}</p>
            <p class="postLiveDrawRunnerName mb-0">#{{ i.winner.ticket_number }}</p>
        </div>
    </div>
</div>
</template>

<script>
import '@/assets/css/preLiveDraw.css';
import '@/assets/css/postLiveDraw.css';
import raffleMixin from "../../mixins/raffleMixin.js"
export default {
    mixins: [raffleMixin],
    components: {},
    data: () => ({}),
    created() {
        this.triggerConfetti()
    },
    methods: {
        triggerConfetti() {
             const defaults = {
                spread: 500,
                ticks: 100,
                gravity: 1,
                decay: 0.94,
                startVelocity: 50,
                shapes: ["square", "circle", "triangle"],
                colors: ["AFE1AF", "ffffff", "50C878", "228B22", "2E8B57"],
            };
            confetti({
                ...defaults,
                particleCount: 400,
                scalar: 1.5,
            });
            confetti({
                ...defaults,
                particleCount: 400,
                scalar: 1.5,
            });
            confetti({
                ...defaults,
                particleCount: 400,
                scalar: 1.5,
            });
        },
        formateName(name) {
            if (name) {
                let firstName = name.split(' ')[0];
                firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
                let lastName = name.split(' ')[1].charAt(0).toUpperCase();
                return `${firstName} ${lastName}`
            }
        }
    },

}
</script>
