<template>
<v-text-field label="Email*" variant="filled" v-model="editItem.email" :error-messages="error.email" ref="emailField" @input="remove_error('email')"></v-text-field>

<v-text-field label="Reason*" variant="filled" v-model="editItem.reason" :error-messages="error.reason" @input="remove_error('reason')" ref="reasonField"></v-text-field>
<v-btn @click="validate_inputs()" v-if="btnNameChange" :disabled="buttonDisabled" color="blue">ADD</v-btn>
<v-btn @click="validate_inputs()" :disabled="buttonDisabled" v-else color="blue">SAVE</v-btn>
<div v-if="error.api" :style="{ color: 'red' }">{{ error.api }}</div>
</template>

<script>
import apiUrl from '../../api/allApis.js'
import vaildationMixin from "../../mixins/validation.js"
import {
  mapGetters,
  mapActions
} from "vuex";

import {
  markRaw
} from 'vue';
import jwtMixin from '@/mixins/jwt.js'
export default markRaw({
  mixins: [vaildationMixin,jwtMixin],
  name: "Add",
  props: {
    btnNameChange: Boolean,
    editItem: Object
  },
  emits: ['data-changed'],
  data() {
    return {
      successmessage: '',
      error: {
        email: '',
        first_name: '',
        last_name: '',
        origin: '',
        reason: '',
        user_id: '',
        unique_id: '',
        api: '',
      },
      buttonDisabled: false,
      userId: '',
      selectedUser: null,
      emailAddress: null,
      OptionObject: {
        userId: '',
        emailId: ''
      },
      items: ["admin", "user"],
      reasonName: null,
      reasonObject: {
        reasonID: '',
        reasonName: ''
      },
    }
  },
  computed: {
    ...mapGetters(['allOptionDatail', 'allReasonOptionDatail']),
    isFormFilled() {
      return (
        this.error.email === '' &&
        this.error.reason === '' 
      );
    },

  },
  async mounted() {

    if (!this.btnNameChange) {
      this.OptionObject = this.allOptionDatail.find(item => item.userId === this.editItem.user_id)
      if (this.OptionObject) {
        this.emailAddress = this.OptionObject.emailId
      } else {
        this.emailAddress = "User Email Not Available"
      }
    }

  },
  methods: {
    ...mapActions(['option_detail', 'reason_option_detail']),
    async load_option_data() {
      const apiEndpoint = apiUrl.fetchaccUserTableData;
      await this.$store.dispatch('option_detail', apiEndpoint);
    },
    handleUserChange() {
      let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
      if (selectedUser) {
        this.editItem.user_id = selectedUser.userId;
        this.emailAddress = selectedUser.emailId;
        this.remove_error('user_id');
      }
    },
    remove_error(value) {
      switch (value) {
        case 'email':
          let email = this.validate_email(this.editItem.email);
          if (!email.valid) {
            this.error.email = email.error;
            break
          } else {
            this.error.email = ''
            break;
          }
          case 'first_name':
            let first_name = this.validate_name(this.editItem.first_name, "First name")
          if (!first_name.valid) {
            this.error.first_name = first_name.error;
            break;
          } else {
            this.error.first_name = '';
            break;
          }
          case 'last_name':
            let last_name = this.validate_name(this.editItem.last_name, "Last name")
          if (!last_name.valid) {
            this.error.last_name = last_name.error;
            break;
          }
          else {
            this.error.last_name = '';
            break;
          }
        case 'origin':
          this.error.origin = ''
          break;
        case 'reason':
          this.error.reason = ''
          break;
        case 'user_id':
          this.error.user_id = ''
          break;
        case 'unique_id':
          this.error.unique_id = ''
          break;
        default:
          this.error = {
            email: '',
            first_name: '',
            last_name: '',
            origin: '',
            reason: '',
            user_id: '',
            unique_id: '',
            api: '',
          }
      }
    },
    validate_inputs() {
      let email = this.valid_inputs(this.editItem.email);
      let reason = this.valid_inputs(this.editItem.reason)

      if (!email.valid) {
        this.error.email = email.error;
        this.$refs.emailField.$el.querySelector('input').focus();
      }
       else if (!reason.valid) {
        this.error.reason = reason.error;
        this.$refs.reasonField.$el.querySelector('input').focus();
      } else {
        if (this.isFormFilled) {
          this.error = {
            email: '',
            first_name: '',
            last_name: '',
            origin: '',
            reason: '',
            user_id: '',
            unique_id: '',
            api: '',
          }
          if (this.btnNameChange) {
            this.add_client_blacklist_table_data()
          } else {
            this.edit_client_blacklist_table_data()
          }
        }
      }
    },
    async edit_client_blacklist_table_data() {
      try {
        this.buttonDisabled = true;
        await fetch(apiUrl.updateRestrictionsClientBlacklist, {
            method: "PUT",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'Authorization':JSON.parse(this.jwt_token())
            },
            body:JSON.stringify(this.editItem)
          })
          .then((response) => response.json())
          .then((data) => {
            if (!data.status) {
              this.successMessage = "Successfully saved...";
              this.$emit("data-changed", this.successMessage);
            } else {
              this.error.api = data.message ? data.message : data;
            }
          });
      } catch (error) {
        this.error.api = error;

      } finally {
        this.buttonDisabled = false;
      }
    },
    async add_client_blacklist_table_data() {
    
      try {
        this.buttonDisabled = true;

        await fetch(apiUrl.addRestrictionsClientBlacklist, {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'Authorization':JSON.parse(this.jwt_token())
            },
            body: JSON.stringify(this.editItem)
          })
          .then((response) => response.json())
          .then((data) => {
            if (!data.status) {
              this.successMessage = "Successfully added...";
              this.$emit("data-changed", this.successMessage);
            } else {
              this.error.api = data.message ? data.message : data;
            }
          });
      } catch (error) {
        this.error.api = error;
      } finally {
        this.buttonDisabled = false;
      }
    },
  },

});
</script>
