<template>
    <div>
        <v-data-table v-model="selectedSortId" :headers="headers"  :items="items" :search="search" return-object :show-select="showSelect">
            <template v-for="(slotName, index) in slotNames" :key="index" v-slot:[`item.${slotName}`]="{ item }">
                <slot :name="slotName" :item="item">
                </slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedSortId: [],
        }
    },
    props: {
        headers: Array,
        items: Array,
        slotNames: Array,
        search: String,
        showSelect: Boolean
    },
    computed: {
        selected() {
            return this.selectedSortId;
        },
    },
    watch: {
        selected(newSelected) {
            this.$emit('updatedItems', newSelected);
        }
    }
}
</script>
