export const  countryCodeArray=[
  {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
  },
  {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
  },
  {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
  },
  {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
  },
  {
      "name": "AmericanSamoa",
      "dial_code": "+1684",
      "code": "AS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
  },
  {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
  },
  {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
  },
  {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
  },
  {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg"
  },
  {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
  },
  {
      "name": "Armenia",
      "dial_code": "+374",
      "code": "AM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
  },
  {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
  },
  {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
  },
  {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
  },
  {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
  },
  {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
  },
  {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
  },
  {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
  },
  {
      "name": "Barbados",
      "dial_code": "+1246",
      "code": "BB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
  },
  {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
  },
  {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
  },
  {
      "name": "Belize",
      "dial_code": "+501",
      "code": "BZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
  },
  {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
  },
  {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
  },
  {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
  },
  {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
  },
  {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
  },
  {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
  },
  {
      "name": "British Indian Ocean Territory",
      "dial_code": "+246",
      "code": "IO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg"
  },
  {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
  },
  {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
  },
  {
      "name": "Burundi",
      "dial_code": "+257",
      "code": "BI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
  },
  {
      "name": "Cambodia",
      "dial_code": "+855",
      "code": "KH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
  },
  {
      "name": "Cameroon",
      "dial_code": "+237",
      "code": "CM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
  },
  {
      "name": "Cape Verde",
      "dial_code": "+238",
      "code": "CV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
  },
  {
      "name": "Cayman Islands",
      "dial_code": "+345",
      "code": "KY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
  },
  {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
  },
  {
      "name": "Chad",
      "dial_code": "+235",
      "code": "TD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
  },
  {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
  },
  {
      "name": "China",
      "dial_code": "+86",
      "code": "CN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
  },
  {
      "name": "Christmas Island",
      "dial_code": "+61",
      "code": "CX",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg"
  },
  {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
  },
  {
      "name": "Comoros",
      "dial_code": "+269",
      "code": "KM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
  },
  {
      "name": "Congo",
      "dial_code": "+242",
      "code": "CG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
  },
  {
      "name": "Cook Islands",
      "dial_code": "+682",
      "code": "CK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
  },
  {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
  },
  {
      "name": "Croatia",
      "dial_code": "+385",
      "code": "HR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
  },
  {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
  },
  {
      "name": "Cyprus",
      "dial_code": "+537",
      "code": "CY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
  },
  {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
  },
  {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
  },
  {
      "name": "Djibouti",
      "dial_code": "+253",
      "code": "DJ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
  },
  {
      "name": "Dominica",
      "dial_code": "+1767",
      "code": "DM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
  },
  {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
  },
  {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
  },
  {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
  },
  {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
  },
  {
      "name": "Equatorial Guinea",
      "dial_code": "+240",
      "code": "GQ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
  },
  {
      "name": "Eritrea",
      "dial_code": "+291",
      "code": "ER",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
  },
  {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
  },
  {
      "name": "Ethiopia",
      "dial_code": "+251",
      "code": "ET",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
  },
  {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
  },
  {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
  },
  {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
  },
  {
      "name": "France",
      "dial_code": "+33",
      "code": "FR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
  },
  {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
  },
  {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
  },
  {
      "name": "Gabon",
      "dial_code": "+241",
      "code": "GA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
  },
  {
      "name": "Gambia",
      "dial_code": "+220",
      "code": "GM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
  },
  {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
  },
  {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
  },
  {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
  },
  {
      "name": "Gibraltar",
      "dial_code": "+350",
      "code": "GI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
  },
  {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
  },
  {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
  },
  {
      "name": "Grenada",
      "dial_code": "+1473",
      "code": "GD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
  },
  {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
  },
  {
      "name": "Guam",
      "dial_code": "+1671",
      "code": "GU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
  },
  {
      "name": "Guatemala",
      "dial_code": "+502",
      "code": "GT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
  },
  {
      "name": "Guinea",
      "dial_code": "+224",
      "code": "GN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
  },
  {
      "name": "Guinea-Bissau",
      "dial_code": "+245",
      "code": "GW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
  },
  {
      "name": "Guyana",
      "dial_code": "+595",
      "code": "GY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
  },
  {
      "name": "Haiti",
      "dial_code": "+509",
      "code": "HT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
  },
  {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
  },
  {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
  },
  {
      "name": "Iceland",
      "dial_code": "+354",
      "code": "IS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
  },
  {
      "name": "India",
      "dial_code": "+91",
      "code": "IN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
  },
  {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
  },
  {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
  },
  {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
  },
  {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
  },
  {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
  },
  {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
  },
  {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
  },
  {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
  },
  {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
  },
  {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
  },
  {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
  },
  {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
  },
  {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
  },
  {
      "name": "Latvia",
      "dial_code": "+371",
      "code": "LV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
  },
  {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
  },
  {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
  },
  {
      "name": "Liberia",
      "dial_code": "+231",
      "code": "LR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
  },
  {
      "name": "Liechtenstein",
      "dial_code": "+423",
      "code": "LI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
  },
  {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
  },
  {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
  },
  {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
  },
  {
      "name": "Malawi",
      "dial_code": "+265",
      "code": "MW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
  },
  {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
  },
  {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
  },
  {
      "name": "Mali",
      "dial_code": "+223",
      "code": "ML",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
  },
  {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
  },
  {
      "name": "Marshall Islands",
      "dial_code": "+692",
      "code": "MH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
  },
  {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
  },
  {
      "name": "Mauritania",
      "dial_code": "+222",
      "code": "MR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
  },
  {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "MU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
  },
  {
      "name": "Mayotte",
      "dial_code": "+262",
      "code": "YT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
  },
  {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
  },
  {
      "name": "Monaco",
      "dial_code": "+377",
      "code": "MC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
  },
  {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
  },
  {
      "name": "Montenegro",
      "dial_code": "+382",
      "code": "ME",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
  },
  {
      "name": "Montserrat",
      "dial_code": "+1664",
      "code": "MS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
  },
  {
      "name": "Morocco",
      "dial_code": "+212",
      "code": "MA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
  },
  {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
  },
  {
      "name": "Namibia",
      "dial_code": "+264",
      "code": "NA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
  },
  {
      "name": "Nauru",
      "dial_code": "+674",
      "code": "NR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
  },
  {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
  },
  {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
  },
  {
      "name": "Netherlands Antilles",
      "dial_code": "+599",
      "code": "AN",
      "image": "https://static.raffolux.com/static/website/redev_images/netherlands-antilles.svg"
  },
  {
      "name": "New Caledonia",
      "dial_code": "+687",
      "code": "NC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
  },
  {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
  },
  {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
  },
  {
      "name": "Niger",
      "dial_code": "+227",
      "code": "NE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
  },
  {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
  },
  {
      "name": "Niue",
      "dial_code": "+683",
      "code": "NU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
  },
  {
      "name": "Norfolk Island",
      "dial_code": "+672",
      "code": "NF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
  },
  {
      "name": "Northern Mariana Islands",
      "dial_code": "+1 670",
      "code": "MP",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
  },
  {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
  },
  {
      "name": "Oman",
      "dial_code": "+968",
      "code": "OM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
  },
  {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
  },
  {
      "name": "Palau",
      "dial_code": "+680",
      "code": "PW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
  },
  {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
  },
  {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
  },
  {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
  },
  {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
  },
  {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
  },
  {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
  },
  {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
  },
  {
      "name": "Puerto Rico",
      "dial_code": "+1939",
      "code": "PR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
  },
  {
      "name": "Qatar",
      "dial_code": "+974",
      "code": "QA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
  },
  {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
  },
  {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
  },
  {
      "name": "Samoa",
      "dial_code": "+685",
      "code": "WS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
  },
  {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
  },
  {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
  },
  {
      "name": "Senegal",
      "dial_code": "+221",
      "code": "SN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
  },
  {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
  },
  {
      "name": "Seychelles",
      "dial_code": "+248",
      "code": "SC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
  },
  {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
  },
  {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
  },
  {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
  },
  {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
  },
  {
      "name": "Solomon Islands",
      "dial_code": "+677",
      "code": "SB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
  },
  {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "dial_code": "+500",
      "code": "GS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg"
  },
  {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
  },
  {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
  },
  {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
  },
  {
      "name": "Suriname",
      "dial_code": "+597",
      "code": "SR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
  },
  {
      "name": "Swaziland",
      "dial_code": "+268",
      "code": "SZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
  },
  {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
  },
  {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
  },
  {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
  },
  {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
  },
  {
      "name": "Togo",
      "dial_code": "+228",
      "code": "TG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
  },
  {
      "name": "Tokelau",
      "dial_code": "+690",
      "code": "TK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
  },
  {
      "name": "Tonga",
      "dial_code": "+676",
      "code": "TO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
  },
  {
      "name": "Trinidad and Tobago",
      "dial_code": "+1868",
      "code": "TT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
  },
  {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
  },
  {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
  },
  {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
  },
  {
      "name": "Turks and Caicos Islands",
      "dial_code": "+1649",
      "code": "TC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
  },
  {
      "name": "Tuvalu",
      "dial_code": "+688",
      "code": "TV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
  },
  {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
  },
  {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
  },
  {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
  },
  {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
  },
  {
      "name": "United States",
      "dial_code": "+1",
      "code": "US",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
  },
  {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
  },
  {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
  },
  {
      "name": "Vanuatu",
      "dial_code": "+678",
      "code": "VU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
  },
  {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
  },
  {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
  },
  {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
  },
  {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
  },
  {
      "name": "land Islands",
      "dial_code": "",
      "code": "AX",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
  },
  {
      "name": "Antarctica",
      "dial_code": null,
      "code": "AQ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
  },
  {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
  },
  {
      "name": "Brunei Darussalam",
      "dial_code": "+673",
      "code": "BN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
  },
  {
      "name": "Cocos (Keeling) Islands",
      "dial_code": "+61",
      "code": "CC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg"
  },
  {
      "name": "Congo, The Democratic Republic of the",
      "dial_code": "+243",
      "code": "CD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
  },
  {
      "name": "Cote d'Ivoire",
      "dial_code": "+225",
      "code": "CI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
  },
  {
      "name": "Falkland Islands (Malvinas)",
      "dial_code": "+500",
      "code": "FK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
  },
 
  {
      "name": "Holy See (Vatican City State)",
      "dial_code": "+379",
      "code": "VA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
  },
  {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
  },
  {
      "name": "Iran, Islamic Republic of",
      "dial_code": "+98",
      "code": "IR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
  },


  {
      "name": "Korea, Democratic People's Republic of",
      "dial_code": "+850",
      "code": "KP",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg"
  },
  {
      "name": "Korea, Republic of",
      "dial_code": "+82",
      "code": "KR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
  },
  {
      "name": "Lao People's Democratic Republic",
      "dial_code": "+856",
      "code": "LA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
  },
  {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
  },
  {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
  },
  {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "dial_code": "+389",
      "code": "MK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
  },
  {
      "name": "Micronesia, Federated States of",
      "dial_code": "+691",
      "code": "FM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
  },
  {
      "name": "Moldova, Republic of",
      "dial_code": "+373",
      "code": "MD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
  },
  {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
  },
  {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
  },
  {
      "name": "Pitcairn",
      "dial_code": "+872",
      "code": "PN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg"
  },
  {
      "name": "Réunion",
      "dial_code": "+262",
      "code": "RE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
  },
  {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
  },
  {
      "name": "Saint Barthélemy",
      "dial_code": "+590",
      "code": "BL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg"
  },
  {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "dial_code": "+290",
      "code": "SH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
  },
  {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
  },
  {
      "name": "Saint Lucia",
      "dial_code": "+1758",
      "code": "LC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
  },
  {
      "name": "Saint Martin",
      "dial_code": "+590",
      "code": "MF",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg"
  },
  {
      "name": "Saint Pierre and Miquelon",
      "dial_code": "+508",
      "code": "PM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "dial_code": "+1784",
      "code": "VC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
  },
  {
      "name": "Sao Tome and Principe",
      "dial_code": "+239",
      "code": "ST",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
  },
  {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "dial_code": "+47",
      "code": "SJ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg"
  },
  {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg"
  },
  {
      "name": "Taiwan, Province of China",
      "dial_code": "+886",
      "code": "TW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
  },
  {
      "name": "Tanzania, United Republic of",
      "dial_code": "+255",
      "code": "TZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
  },
  {
      "name": "Timor-Leste",
      "dial_code": "+670",
      "code": "TL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
  },
  {
      "name": "Venezuela, Boliletian Republic of",
      "dial_code": "+58",
      "code": "VE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
  },
  {
      "name": "Viet Nam",
      "dial_code": "+84",
      "code": "VN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
  },
  {
      "name": "Virgin Islands, British",
      "dial_code": "+1284",
      "code": "VG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
  },
  {
      "name": "Virgin Islands, U.S.",
      "dial_code": "+1340",
      "code": "VI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
  }
]