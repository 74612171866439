<template>
<form>
    <v-text-field v-model="settingEditDtls.value" label="Value*" :error-messages="error.value" @input="remove_error('value')"></v-text-field>
    <v-text-field v-model="settingEditDtls.name" label="Name*" :error-messages="error.name" @input="remove_error('name')"></v-text-field>

    <v-btn class="mr-4" @click="validate_addSetting()" color="blue" v-if="!SettingSaveEdit" :disabled="settingsAdd">
        Add
    </v-btn>
    <v-btn class="mr-4" @click="validate_addSetting()" color="blue" v-else :disabled="settingsAdd">
        Save
    </v-btn>
    <logoutDia :showLogoutDialog="showLogoutDialog" />

    <div style="color: red;" v-if="error.api">{{ error.api }}</div>

</form>
</template>

<script>
import apiUrl from '@/api/allApis';
import vaildationMixin from "../../mixins/validation.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
        logoutDia,
    },
    mixins: [vaildationMixin, jwtMixin],
    data: () => ({
        showLogoutDialog: false,
        successMessage: '',
        items: [
            'Item 1',
            'Item 2',
            'Item 3',
            'Item 4',
        ],
        settingEditData: [],
        error: {
            value: '',
            name: '',
            api: ''
        },
        settingsAdd: false
    }),
    computed: {
        isFormFilled() {
            return (
                this.error.value === '' &&
                this.error.name === ''
            );
        },
    },
    props: {
        settingEditDtls: Object,
        SettingSaveEdit: false
    },
    mounted() {
        this.settingEditData = this.settingEditDtls
    },
    methods: {
        remove_error(value) {
            switch (value) {
                case 'value':
                    this.error.value = ''
                    break;
                case 'name':
                    let name = this.validate_name(this.settingEditDtls.name, "Name")
                    if (!name.valid) {
                        this.error.name = name.error;
                        break;
                    } else {
                        this.error.name = '';
                        break;
                    }
                default:
                    this.error = {
                        value: '',
                        name: '',
                        api: ''
                    }
            }
        },

        validate_addSetting() {
            const value = this.valid_inputs(this.settingEditDtls.value)
            const name = this.valid_inputs(this.settingEditDtls.name)

            if (!value.valid) {
                this.error.value = value.error
            }
            else if (!name.valid) {
                this.error.name = name.error
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        value: '',
                        name: ''
                    }
                    if (!this.SettingSaveEdit) {
                        this.add_otherSetting()
                    } else {
                        this.update_otherSetting()
                    }
                }
            }
        },
        async add_otherSetting() { 
            try {
                this.settingsAdd = true
                await fetch(apiUrl.addOtherSettingContent, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.settingEditDtls)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully added...";
                            this.$emit("switchSettingTab", this.successMessage);
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })

            } catch (error) {
                this.error.api = error;
            } finally {
                this.settingsAdd = false
            }
        },
        async update_otherSetting() {
            try {
                this.settingsAdd = true
                await fetch(apiUrl.updateOtherSettingContent, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.settingEditDtls)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully saved...";
                            this.$emit("switchSettingTab", this.successMessage);
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.settingsAdd = false
            }
        },
    },
})
</script>
