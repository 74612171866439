<template>
<form>
    <v-text-field id="instantWinnerName" v-model="instantWinnerDetails.name" label="Name*" :error-messages="error.name" @input="remove_error('name')" :readonly="saveBtn"></v-text-field>
    <v-text-field v-model="instantWinnerDetails.prize_name" id="prizeName" label="Prize Name*" :error-messages="error.prizeName" @input="remove_error('prizeName')" :readonly="saveBtn"></v-text-field>
    <v-autocomplete label="Raffle Name*" rows="1" id="raffle_id" variant="filled" v-model="raffleCodeName" :items="allReffleCodeOptionDetail.map(item => item.raffleCodeName)" :menu-props="{ minWidth: '300px' }" :error-messages="error.raffle_id" @update:model-value="handleRaffleCodeChange" :readonly="saveBtn"></v-autocomplete>
    <v-text-field v-model="instantWinnerDetails.location" label="Location" :error-messages="error.location" @input="remove_error('location')" ></v-text-field>
    <v-text-field v-model="instantWinnerDetails.position" label="Position*" :error-messages="error.position" @input="remove_error('position')" :readonly="saveBtn"></v-text-field>
    <v-autocomplete label="User Email*" variant="filled" v-model="emailAddress" :items="allOptionDatail.map(item => item.emailId)" :menu-props="{ minWidth: '300px' }" @update:model-value="handleUserChange" :error-messages="error.user_id" :readonly="saveBtn">
    </v-autocomplete>
    <div v-if=saveBtn>
        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="4" lg="3" class="align-content-center">
                <v-card>
                    <v-card-title class="caption">Img Url</v-card-title>
                    <v-img :src="img_url+instantWinnerDetails.img_url" height="150"></v-img>
                </v-card>
                <div>
                    <v-btn class="mt-3" color="blue" @click="img_upload">{{ imgUpload ? 'Close' :(instantWinnerDetails.img_url ? 'Edit Image' : 'Add Image') }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
    <v-file-input accept="image/*" v-model="imgValue" prepend-icon="" prepend-inner-icon="mdi-camera" label="Image" :error-messages="error.img_url" @change="this.remove_error('img_url')" v-if="imgUpload"></v-file-input>
    <v-btn class="mr-4" @click="validate_instantWinner()" color="blue" v-if="!saveBtn" :disabled="buttonDisabled">
        Add
    </v-btn>
    <v-btn class="mr-4" @click="validate_instantWinner()" color="blue" :disabled="buttonDisabled" v-else>
        Save
    </v-btn>
    <div style="color: red;" v-if="error.api">{{ error.api }}</div>
    <logoutDia :showLogoutDialog="showLogoutDialog" />

</form>
</template>

<script>
import apiUrl from '@/api/allApis';
import vaildationMixin from "../../mixins/validation.js";
import imgUploadMixin from "../../mixins/imgUpload.js"
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"
import {
    mapGetters,
    mapActions
} from "vuex"
import {
    markRaw
} from 'vue';
export default markRaw({
    components: {
    logoutDia,
  },
    mixins: [vaildationMixin, imgUploadMixin,jwtMixin],
    data: () => ({
        showLogoutDialog: false,
        buttonDisabled: false,
        items: [
            'true',
            'false',
        ],
        instantWinEditedData: [],
        error: {
            name: '',
            location: '',
            img_url: '',
            position: '',
            draw_id: '',
            user_id: '',
            raffle_id: '',
            prizeName: '',
            api: ''
        },
        OptionObject: {
            userId: '',
            emailId: ''
        },
        emailAddress: null,
        raffleCodeName: null,
        imgValue: '',
        signedUrl: '',
        img_updated: false,
        imgUpload: false,
        successMessage: ''
    }),
    computed: {
        ...mapGetters(['allOptionDatail', 'allReffleCodeOptionDetail']),
        isFormFilled() {
            return (
                (this.error.name === '' || this.error.name === undefined) &&
                (this.error.location === '' || this.error.location === undefined) &&
                (this.error.img_url === '' || this.error.img_url === undefined) &&
                (this.error.position === '' || this.error.position === undefined) &&
                (this.error.user_id === '' || this.error.user_id === undefined)
            );
        }
    },
    props: {
        instantWinnerDetails: Object,
        saveBtn: Boolean
    },
    async mounted() {
        if (!this.saveBtn) {
            this.imgUpload = true
        }
        this.instantWinEditedData = this.instantWinnerDetails
        this.load_option_data();
        if (this.saveBtn) {
            this.OptionObject = this.allOptionDatail.find(item => item.userId === this.instantWinnerDetails.user_id)
            if (this.OptionObject) {
                this.emailAddress = this.OptionObject.emailId
            } else {
                this.emailAddress = "User Email Not Available"
            }
        }
        if (this.saveBtn) {
            this.raffleCodeObject = this.allReffleCodeOptionDetail.find(item => item.raffleID === this.instantWinnerDetails.raffle_id)
            if (this.raffleCodeObject) {
                this.raffleCodeName = this.raffleCodeObject.raffleCodeName
            } else {
                this.raffleCodeName = "Raffle Code  Name Not Available"
            }
        }
    },
    methods: {
        ...mapActions(['option_detail', 'raffle_code_option_detail']),
        img_upload() {
            this.imgUpload = !this.imgUpload;
            this.error.img_url = '';
        },
        async load_option_data() {
            let apiEndpoint = apiUrl.fetchaccUserTableData;
            await this.$store.dispatch('option_detail', apiEndpoint);
            apiEndpoint = apiUrl.fetchRaffleTableData;
            await this.$store.dispatch('raffle_code_option_detail', apiEndpoint);
        },
        handleRaffleCodeChange() {
            let selectedUser = this.allReffleCodeOptionDetail.find(item => item.raffleCodeName === this.raffleCodeName);
            if (selectedUser) {
                this.instantWinnerDetails.raffle_id = selectedUser.raffleID;
                this.raffleCodeName = selectedUser.raffleCodeName;
                this.remove_error('raffle_id');
            }
        },
        handleUserChange() {
            let selectedUser = this.allOptionDatail.find(item => item.emailId === this.emailAddress);
            if (selectedUser) {
                this.instantWinnerDetails.user_id = selectedUser.userId;
                this.emailAddress = selectedUser.emailId;
                this.remove_error('user_id');
            }
        },
        remove_error(value) {
            switch (value) {
                case 'name':
                    let name = this.validate_name(this.instantWinnerDetails.name, "Name")
                    if (!name.valid) {
                        this.error.name = name.error;
                        break;
                    } else {
                        this.error.name = ''
                        break;
                    }
                case 'raffle_id':
                    this.error.raffle_id = ''
                    break;
                case 'type':
                    this.error.type = ''
                    break;
                case 'prizeName':
                    this.error.prizeName = ''
                    break;
                case 'location':
                    this.error.location = ''
                    break;
                case 'img_url':
                    this.error.img_url = ''
                    break;
                case 'position':
                    const position_num = this.validateNumberField(this.instantWinnerDetails.position,
                        'position')
                    if (!position_num.valid) {
                        this.error.position = position_num.error
                        break;
                    } else {
                        this.error.position = ''
                        break;
                    }
                case 'draw_id':
                    this.error.draw_id = ''
                    break;
                case 'user_id':
                    this.error.user_id = ''
                    break;
                default:
                    this.error = {
                        name: '',
                        location: '',
                        raffle_id: '',
                        img_url: '',
                        position: '',
                        draw_id: '',
                        user_id: '',
                        prizeName: '',
                        api: ''
                    }
            }

        },
        validate_instantWinner() {
            const name = this.valid_inputs(this.instantWinnerDetails.name)
            const position = this.valid_inputs(this.instantWinnerDetails.position)
            const emailAddress = this.valid_inputs(this.emailAddress)
            const raffleCodeName = this.valid_inputs(this.raffleCodeName)
            let img_url;
            if (this.saveBtn) {
                if (this.imgUpload) {
                    img_url = this.valid_inputs(this.imgValue)
                } else {
                    img_url = {
                        valid: true,
                        error: ""
                    };
                }
            } else {
                img_url = {
                    valid: true,
                    error: ""
                };
            }

            if (!name.valid) {
                this.error.name = name.error
                document.getElementById('instantWinnerName').focus();
            }else if (!raffleCodeName.valid) {
                this.error.raffle_id = raffleCodeName.error;
                document.getElementById('raffle_id').focus();
                return;
            }else if (!position.valid) {
                this.error.position = position.error
            }else if (!emailAddress.valid) {
                this.error.user_id = emailAddress.error
            } else if (!img_url.valid) {
                this.error.img_url = img_url.error
            } else {
                if (this.isFormFilled) {
                    this.error = {
                        name: '',
                        location: '',
                        img_url: '',
                        position: '',
                        draw_id: '',
                        user_id: '',
                        raffle_id: '',
                        prizeName: '',
                    }
                    if (!this.saveBtn) {
                        this.add_instantWinner()
                    } else {
                        this.update_instantWinner()
                    }
                }
            }
        },
        async add_instantWinner() {
            try {
                this.buttonDisabled = true
               this.instantWinnerDetails.position = Number(this.instantWinnerDetails.position)
                if (this.imgValue.length > 0) {
                    this.instantWinnerDetails.img_url = this.imgValue[0].name
                } else {
                    this.instantWinnerDetails.img_url = ''
                }
                await fetch(apiUrl.addInstantWinnerContent, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(this.instantWinnerDetails)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully added...";
                            if (this.imgValue.length > 0) {
                                this.signedUrl = data.uploadUrl;
                                let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                if (message === "File uploaded successfully") {
                                    this.success_emit();
                                } else {
                                    this.error.api = message;
                                }
                            } else {
                                this.success_emit();
                            }
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false
            }
        },
        success_emit() {
            this.$emit("switchInstantTab", this.successMessage);
        },
        async update_instantWinner() {
            try {
                this.buttonDisabled = true
                const {
                    user_email,
                    prize_name,
                    ...instantWinnerData
                } = this.instantWinnerDetails
               instantWinnerData.position = Number(instantWinnerData.position)
                this.img_updated = this.imgUpload ? true : false;
                if (this.imgUpload) {
                        instantWinnerData.img_url = this.imgValue.length > 0 ? this.imgValue[0].name :instantWinnerData.img_url
                }
                let body = {
                    img_updated: this.img_updated,
                    ...instantWinnerData
                }
                await fetch(apiUrl.updateInstantWinnerContent, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(body)
                    }).then((response) => response.json())
                    .then(async (data) => {
                        if (!data.status) {
                            this.successMessage = "Successfully saved...";
                            if (this.img_updated === true) {
                                if (this.imgValue.length > 0) {
                                    this.signedUrl = data.uploadURL;
                                    let message = await this.uploadToS3(this.signedUrl, this.imgValue[0]);
                                    if (message === "File uploaded successfully") {
                                        this.success_emit();
                                    } else {
                                        this.error.api = message;
                                    }
                                } else {
                                    this.success_emit();
                                }
                            } else {
                                this.success_emit();
                            }
                        }  else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.error.api = error;
            } finally {
                this.buttonDisabled = false
            }

        },

    },
})
</script>
