<template>
<v-card flat>
    <template v-slot:title>
        <v-card-title class="d-flex justify-space-between">
            {{ title }}
        </v-card-title>
    </template>
<SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab==='one'">
        <template v-slot:actions>
            <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
        </template>
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_btn">View</v-tab>
        <v-tab value="two" @click="addBtn()">{{ getBtnName() }}</v-tab>
    </v-tabs>
    <v-card-text>
        <v-items v-model="tab">
            <v-tab-item value="one" v-if="tab === 'one'">
                <template v-slot:text>
                </template>
                <div v-if="loadingData" style="text-align: center;">
                    Loading...
                </div>
                <tableLayout :headers="raffleHeaders" :items="filtreDetails" :slotNames="raffleSlotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                    <template v-slot:raffle_expire="{ item }">
                        <span>{{ this.formate_input_Date(item.raffle_expire) }}</span>
                    </template>
                    <template v-slot:custom_raffle_id="{ item }">
                        <span>{{ this.custom_raffle_display(item.custom_raffle_id) }}</span>
                    </template>
                    <template v-slot:drawing_in="{ item }">
                        <span>{{ this.formate_input_Date(item.drawing_in) }}</span>
                    </template>
                    <template v-slot:category_id="{ item }">
                        <span>{{ this.id_name_display(item.category_id) }}</span>
                    </template>
                    <template v-slot:is_live="{ item }">
                        <span :class="item.is_live ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                    <template v-slot:is_cross_selling="{ item }">
                        <span :class="item.is_cross_selling ? 'mdi mdi-check-circle-outline' : 'mdi mdi-close-circle-outline'"></span>
                    </template>
                    <template v-slot:Actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="blue">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item)" color="red">mdi-delete</v-icon>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <raffleAddPage :editItem="item" :btnNameChange=btnBoolean @data-changed="show_changed_data">
                </raffleAddPage>
            </v-tab-item>
        </v-items>
    </v-card-text>
</v-card>
<v-dialog v-model="dialogForBatch" :max-width="maxWidth" persistent>
    <v-card>
        <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" :disabled="disableDialogOkBtn" @click="batch_item_function()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
<logoutDia :showLogoutDialog="showLogoutDialog" />
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
</template>

<script>
import raffleAddPage from './raffleAddPage.vue'
import tableLayout from '../ReusableComponents/tableLayout.vue'
import apiUrl from '../../api/allApis.js'
import formateDateMixin from "../../mixins/formateDate.js"
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import userNameDisplay from "../../mixins/userNameDisplay.js"
import importExportDataVue from '../ReusableComponents/importExportData.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import jwtMixin from '@/mixins/jwt.js'
import logoutMixin from '@/mixins/logout'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"

import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    markRaw
} from 'vue';

export default markRaw({
    components: {
        successMessageVue,
        raffleAddPage,
        tableLayout,
        SearchComponent,
        importExportDataVue,
        logoutDia
    },
    mixins: [formateDateMixin, userNameDisplay, jwtMixin, logoutMixin],
    data() {
        return {
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            disableDialogOkBtn: false,
            maxWidth: '560px',
            filterItems: ['Seletc Filter', 'Status'],
            filterValue: 'Seletc Filter',
            filterStatus: [],
            statusValue: 'All',
            filterBtn: false,
            item: {
                is_credit_accepted:'false',
                is_discount_accepted:'false',
                featured:'false',
                is_live:'false',
                is_cross_selling:'false',
                is_charity:'false',
                is_live_draw:'false',
                is_free_raffle:'false',
                is_unlimited_entries:'false',
                publish:'false',
                box_papers:'false',
                status:'draft',
                discounts: [],
                thumbnails: [],
                tickets: [],
            },
            tab: null,
            btnBoolean: true,
            raffleSlotNames: ['Actions', 'is_live', 'raffle_expire', 'drawing_in','is_cross_selling' /* 'category_id', 'custom_raffle_id' */ ],
            loadingData: false,
            publishName: '',
            raffleHeaders: [
                {
                    key: 'title',
                    title: 'Title'
                },
                {
                    key: 'is_live',
                    title: 'Live'
                },
                
                {
                 align:'center',  
                   key: 'is_cross_selling',
                   title: 'Cross Selling',
               },
                {
                    key: 'status',
                    title: 'Status'
                },
                {
                    key: 'raffle_expire',
                    title: 'Raffle Expire'
                },
                {
                    key: 'drawing_in',
                    title: 'Drawing In'
                },
                {
                    align: 'center',
                    key: 'Actions',
                    title: 'Actions'

                }
            ],
            error: {
                api: ''
            },
            raffleItems: [],
            indvidualItem: '',
            actionsItems: ['Delete Selected Items', 'Raffle Clone' /*  'Set Raffle-Active', 'Set Raffle-Pending', 'Set Raffle-Ended', 'Set Raffle-SoldOut', 'Raffle Publish', 'Raffle Unpublish' */ ],
            filterSlotNames: ['actions' /* 'filter' */ ],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [],
            publish: true,
            filtreDetails: [],
            statusNameValue: 'Active',
            modelValue: false,
            inputForImport: false,
            fieldsName: [],
            exportItems: [],
            tableName: 'WebsiteRaffle',
            title: 'WEBSITE RAFFLE',

        }
    },
    computed: {
        ...mapGetters(['allStatus'])
    },
    watch: {
        statusValue(newStatusValue) {
            this.handle_Status_change(newStatusValue);
        },
        filterValue(newFilterValue) {
            this.handle_filter_change(newFilterValue)
        },
        loadingData(newLoadingData) {
            if (newLoadingData === true) {
                this.filterValue = 'Select Filter'
            }
        }
    },
    created() {
        this.fetch_website_raffle_table_data();
    },
    methods: {
        ...mapActions(['fetch_website_status']),
        close_Model(value) {
            if (value == 'reload') {
                this.fetch_website_raffle_table_data();
                this.modelValue = false;
            }
            this.modelValue = false;
        },
        openImportDialog() {
            this.modelValue = true;
            this.inputForImport = true;
            this.fieldsName = [
                "category_id",
                "is_free_raffle",
                "credits_perTicket",
                "displayed_winner_img_url",
                "is_unlimited_tickets",
                "product_source",
                "mini_image_alt",
                "charity_target_raised_amount",
                "video_length_mp4",
                "tickets_sold",
                "is_super_raffle",
                "state",
                "target_price",
                "total_entries_per_player",
                "jira_key_id",
                "is_live_draw",
                "is_charity",
                "discounted_entry_cost_gbp",
                "youtube_link",
                "hide_tickets_checkout",
                "raffle_added",
                "video_url_webm",
                "publish",
                "main_image_url",
                "is_guaranteed_draw",
                "is_live",
                "charity_color_override",
                "custom_raffle_id",
                "reserve_price",
                "postal_tickets",
                "entry_cost_gbp",
                "meta_title",
                "status_id",
                "displayed_winner_description",
                "tickets_left",
                "video_live_drawurl",
                "disable_ticket_selector",
                "claim_category",
                "is_unlimited_raffle",
                "charity_id",
                "is_auto_clone",
                "meta_description",
                "is_flash",
                "slug",
                "specification",
                "cloned_from_id",
                "no_winners",
                "drawing_in",
                "updatedAt",
                "video_url_mp4",
                "video_no_sound",
                "ticket_start_int",
                "total_entries",
                "raffle_code",
                "instant_win_image",
                "extended",
                "auto_cloned",
                "retail_price",
                "xl_image_alt",
                "extended_days",
                "no_of_top_prizes",
                "ticket_entries",
                "is_sequential_tickets",
                "condition_id",
                "facebook_description",
                "charity_amount",
                "raffle_length",
                "summary",
                "winners",
                "createdAt",
                "charity_approximate_donation",
                "promo_image_alt",
                "xl_image_url",
                "common_id",
                "quantity_of_items",
                "no_of_tickets",
                "description",
                "max_entries",
                "title",
                "raffle_expire",
                "facebook_title",
                "discounted_entry_percentage",
                "delivery_information_id",
                "sponsor_id",
                "tickets_per_entry",
                "raffle_type",
                "is_new",
                "all_raffle_sort",
                "id",
                "featured",
                "cash_alternative",
                "promo_image_url",
                "super_raffle_img_url",
                "feature_raffle_sort",
                "autoraffle",
                "is_unlimited_entries",
                "seller_id",
                "mini_image_url",
                "main_image_alt"
            ]
        },
        openExportDialog() {
            this.modelValue = true;
            this.inputForImport = false;
            this.exportItems = this.raffleItems;
        },
        handle_Status_change(selectedItem) {
            if (selectedItem === 'All') {
                this.filtreDetails = this.raffleItems
            } else {
                let id = this.allStatus.find(i => i.statusName == selectedItem)
                this.filtreDetails = this.raffleItems.filter(i => i.status_id == id.statusId)
            }
        },
        handle_filter_change(selectedItem) {
            if (selectedItem === 'Status') {
                this.filterBtn = true;
                let Status = this.allStatus.map(i => i.statusName)
                this.filterStatus = ['All', ...Status]
                this.filterSlotNames = ['actions' /* , 'filter', 'filteringItems' */ ]
            } else {
                this.filterBtn = false;
                this.statusValue = 'All';
                this.filterSlotNames = ['actions' /* , 'filter' */ ]
            }
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '560px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Set Raffle-Active') {
                    this.dialogMessage = 'Are you sure you want to Set the selected Raffles Active?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '650px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Set Raffle-Pending') {
                    this.dialogMessage = 'Are you sure you want to Set the selected Raffles Pending?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '690px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Set Raffle-Ended') {
                    this.dialogMessage = 'Are you sure you want to Set the selected Raffles Ended?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '650px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Set Raffle-SoldOut') {
                    this.dialogMessage = 'Are you sure you want to Set the selected Raffles SoldOut?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '690px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Raffle Clone') {
                    this.dialogMessage = 'Are you sure you want to clone selected items?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '650px'
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Raffle Publish') {
                    this.dialogMessage = 'Are you sure you want to Raffle Publish selected items?'
                    this.dialogSelectBtn = false;
                    this.maxWidth = '650px'
                    this.dialogForBatch = true;
                    this.publish = true;
                } else if (selectedItem === 'Raffle Unpublish') {
                    this.dialogMessage = 'Are you sure you want to Raffle Unpublish selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = true;
                    this.maxWidth = '650px'
                    this.publish = false;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
                this.maxWidth = '520px'
            }
        },
        async fetch_status() {
            const apiEndpoint = `${apiUrl.fetchRaffleStatusTableData}?common_id=61692656`;
            await this.$store.dispatch('fetch_website_status', apiEndpoint);
        },
        batch_item_function() {
            let value = this.actionValue
            switch (value) {
                case 'Delete Selected Items':
                    this.delete_batch_item();
                    break;
                case 'Set Raffle-Active':
                    this.statusNameValue = 'Active';
                    this.raffle_batch_item();
                    break;
                case 'Set Raffle-Pending':
                    this.statusNameValue = 'Pending';
                    this.raffle_batch_item();
                    break;
                case 'Set Raffle-Ended':
                    this.statusNameValue = 'Ended';
                    this.raffle_batch_item();
                    break;
                case 'Set Raffle-SoldOut':
                    this.statusNameValue = 'SoldOut';
                    this.raffle_batch_item();
                    break;
                case 'Raffle Clone':
                    this.clone_batch_item();
                    break;
                case 'Raffle Publish':
                    this.publish_batch_item();
                    break;
                case 'Raffle Unpublish':
                    this.publish_batch_item();
                    break;
                default:
                    this.delete_batch_item();
                    break;
            }
        },
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected];
        },
        async raffle_batch_item() {
            try {
                let statusValueId = this.allStatus.find(i => i.statusName == this.statusNameValue)
                let batchIds = {
                    ids: this.selected,
                    status_id: statusValueId.statusId
                }
                await fetch(apiUrl.updateRaffleStatus, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.fetch_website_raffle_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteRaffle, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.singleItem = [];
                            this.successMessage = "Successfully deleted...";
                            this.show_snackbar(this.successMessage);
                            this.fetch_website_raffle_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.actionValue = 'Select Action';
                throw error
            } finally {
                this.dialogForBatch = false;
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        editItem(item) {
            this.tab = 'two'
            this.item = item;
            this.btnBoolean = false;
        },
        toggle_btn() {
            this.btnBoolean = true;
            this.item = {
                is_credit_accepted:'false',
                is_discount_accepted:'false',
                featured:'false',
                is_live:'false',
                is_cross_selling:'false',
                is_charity:'false',
                is_live_draw:'false',
                is_free_raffle:'false',
                is_unlimited_entries:'false',
                publish:'false',
                box_papers:'false',
                status:'draft',
                discounts: [],
                thumbnails: [],
                tickets: [],
            };
            this.fetch_website_raffle_table_data();
        },
        addBtn() {
            if (this.tabTitle == "ADD") {
                this.item = {
                    is_credit_accepted:'false',
                    is_discount_accepted:'false',
                    featured:'false',
                    is_live:'false',
                    is_cross_selling:'false',
                    is_charity:'false',
                    is_live_draw:'false',
                    is_free_raffle:'false',
                    is_unlimited_entries:'false',
                    publish:'false',
                    box_papers:'false',
                    status:'draft',
                    discounts: [],
                    thumbnails: [],
                    tickets: [],
                };
            }
        },
        deleteItem(item) {
            this.indvidualItem = item;
            this.singleDelete = false;
            this.singleItem = [{
                sort_id: this.indvidualItem.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        getBtnName() {
            return this.btnBoolean ? 'Add' : 'Edit';
        },
        show_changed_data(message) {
            this.show_snackbar(message)
            this.fetch_website_raffle_table_data();
            this.tab = "one";
            this.btnBoolean = true;
            this.item = {
                is_credit_accepted:'false',
                is_discount_accepted:'false',
                featured:'false',
                is_live:'false',
                is_cross_selling:'false',
                is_charity:'false',
                is_live_draw:'false',
                is_free_raffle:'false',
                is_unlimited_entries:'false',
                publish:'false',
                box_papers:'false',
                status:'draft',
                discounts: [],
                thumbnails: [],
                tickets: [],
            };
        },
        async publish_batch_item() {
            try {
                let publishId = {
                    ids: this.selected,
                    publish: this.publish,
                }
                const jsonString = JSON.stringify(publishId);
                await fetch(apiUrl.publishUnpublishRaffle, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.fetch_website_raffle_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.actionValue = 'Select Action';
                this.selected = [];
                this.error = error;
            }
        },
        async clone_batch_item() {
            this.disableDialogOkBtn = true
            try {
                let cloneId = {
                    ids: this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    })
                }

                const jsonString = JSON.stringify(cloneId);
                await fetch(apiUrl.cloneRaffle, {
                        method: "PUT",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                        body: jsonString
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.dialogForBatch = false;
                            this.actionValue = 'Select Action';
                            this.selected = [];
                            this.fetch_website_raffle_table_data();
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                this.error = error;
            } finally {
                this.disableDialogOkBtn = false;
            }
        },
        async fetch_website_raffle_table_data() {
            this.search = '';
            try {
                this.loadingData = true;
                await fetch(`${apiUrl.fetchRaffleTableData}`, {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            'Authorization': JSON.parse(this.jwt_token())
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                            this.raffleItems = data.SortedRaffle;
                            this.filtreDetails = this.raffleItems
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    });
            } catch (error) {
                this.error = error;
            } finally {
                this.selected = [];
                this.loadingData = false;
            }
        },
        show_snackbar(message) {
            this.showSnackbar = true;
            this.successMessage = message;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        }
    }
});
</script>
